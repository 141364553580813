import { callApi } from '../utils/apiUtils';

export const TENANT_NOTES_LOAD_REQUEST = 'TENANT_NOTES_LOAD_REQUEST';
export const TENANT_NOTES_LOAD_SUCCESS = 'TENANT_NOTES_LOAD_SUCCESS';
export const TENANT_NOTES_LOAD_FAILURE  = 'TENANT_NOTES_LOAD_FAILURE';

export const TENANT_NOTE_EDIT_START = 'TENANT_NOTE_EDIT_START';
export const TENANT_NOTE_EDIT_CANCEL = 'TENANT_NOTE_EDIT_CANCEL';
export const TENANT_NOTE_SAVE_REQUEST = 'TENANT_NOTE_SAVE_REQUEST';
export const TENANT_NOTE_SAVE_FAILURE = 'TENANT_NOTE_SAVE_FAILURE';
export const TENANT_NOTE_CREATE_SUCCESS = 'TENANT_NOTE_CREATE_SUCCESS';


function tenantNotesLoadRequest() {
  return {
    type: TENANT_NOTES_LOAD_REQUEST,
  };
}

function tenantNotesLoadSuccess(response) {
  const tenantNotes = response.data.notes;
  return {
    type: TENANT_NOTES_LOAD_SUCCESS,
    notes: tenantNotes,
  };
}

function tenantNotesLoadFailure(error) {
  return {
    type: TENANT_NOTES_LOAD_FAILURE,
    error: error,
  };
}

function tenantNoteSaveRequest() {
  return {
    type: TENANT_NOTE_SAVE_REQUEST,
  };
}

function tenantNoteCreateSuccess(response) {
  const tenantNote = response.data.note;
  return {
    type: TENANT_NOTE_CREATE_SUCCESS,
    note: tenantNote,
    id: 'New'
  };
} 

function tenantNoteSaveFailure(error) {
  return {
    type: TENANT_NOTE_SAVE_FAILURE,
    error: error,
  };
}

export function tenantNoteEdit(values) {
  return {
    type: TENANT_NOTE_EDIT_START,
  };
}

export function tenantNoteEditCancel() {
  return {
    type: TENANT_NOTE_EDIT_CANCEL,
  };
}

export function tenantNoteSave(tenantId, data) {
  const config = {
    method: 'post', 
    body: JSON.stringify({note: data}),
  };
  return callApi('/tenants/'+tenantId+'/notes/', {}, config, tenantNoteSaveRequest, tenantNoteCreateSuccess, tenantNoteSaveFailure);
}

export function tenantNotesLoad(tenantId) {
  const config = {
    method: 'get',    
  };
  return callApi('/tenants/'+tenantId+'/notes/', {}, config, tenantNotesLoadRequest, tenantNotesLoadSuccess, tenantNotesLoadFailure);
}