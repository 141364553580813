import { callApi } from '../utils/apiUtils'

export const LEASE_OCCUPANTS_LOAD_REQUEST = 'LEASE_OCCUPANTS_LOAD_REQUEST'
export const LEASE_OCCUPANTS_LOAD_SUCCESS = 'LEASE_OCCUPANTS_LOAD_SUCCESS'
export const LEASE_OCCUPANTS_LOAD_FAILURE = 'LEASE_OCCUPANTS_LOAD_FAILURE'
export const LEASE_OCCUPANT_EDIT_START = 'LEASE_OCCUPANT_EDIT_START'
export const LEASE_OCCUPANT_EDIT_CANCEL = 'LEASE_OCCUPANT_EDIT_CANCEL'
export const LEASE_OCCUPANT_SAVE_REQUEST = 'LEASE_OCCUPANT_SAVE_REQUEST'
export const LEASE_OCCUPANT_SAVE_SUCCESS = 'LEASE_OCCUPANT_SAVE_SUCCESS'
export const LEASE_OCCUPANT_SAVE_FAILURE = 'LEASE_OCCUPANT_SAVE_FAILURE'
export const LEASE_OCCUPANT_CREATE_SUCCESS = 'LEASE_OCCUPANT_CREATE_SUCCESS'
export const LEASE_OCCUPANT_DEL_REQUEST = 'LEASE_OCCUPANT_DEL_REQUEST'
export const LEASE_OCCUPANT_DEL_SUCCESS = 'LEASE_OCCUPANT_DEL_SUCCESS'
export const LEASE_OCCUPANT_DEL_FAILURE = 'LEASE_OCCUPANT_DEL_FAILURE'

function leaseOccupantsLoadRequest() {
  return {
    type: LEASE_OCCUPANTS_LOAD_REQUEST,
  }
}

function leaseOccupantsLoadSuccess(response) {
  const leaseOccupants = response.data.occupants
  return {
    type: LEASE_OCCUPANTS_LOAD_SUCCESS,
    leaseOccupants: leaseOccupants,
  }
}

function leaseOccupantsLoadFailure(error) {
  return {
    type: LEASE_OCCUPANTS_LOAD_FAILURE,
    error: error,
  }
}

function leaseOccupantSaveRequest() {
  return {
    type: LEASE_OCCUPANT_SAVE_REQUEST,
  }
}

function leaseOccupantSaveSuccess(response) {
  const leaseOccupant = response.data.occupant
  return {
    type: LEASE_OCCUPANT_SAVE_SUCCESS,
    leaseOccupant: leaseOccupant,
    id: leaseOccupant.id,
  }
}

function leaseOccupantCreateSuccess(response) {
  const leaseOccupant = response.data.occupant
  return {
    type: LEASE_OCCUPANT_CREATE_SUCCESS,
    leaseOccupant: leaseOccupant,
    id: 'New',
  }
}

function leaseOccupantSaveFailure(error) {
  return {
    type: LEASE_OCCUPANT_SAVE_FAILURE,
    error: error,
  }
}

function leaseOccupantDelRequest() {
  return {
    type: LEASE_OCCUPANT_DEL_REQUEST,
  }
}

function leaseOccupantDelSuccess(response) {
  const leaseOccupant = response.data.occupant
  return {
    type: LEASE_OCCUPANT_DEL_SUCCESS,
    leaseOccupant: leaseOccupant,
  }
}

function leaseOccupantDelFailure(error) {
  return {
    type: LEASE_OCCUPANT_DEL_FAILURE,
    error: error,
  }
}

export function leaseOccupantEdit(id) {
  return {
    type: LEASE_OCCUPANT_EDIT_START,
    id: id,
  }
}

export function leaseOccupantEditCancel(id) {
  return {
    type: LEASE_OCCUPANT_EDIT_CANCEL,
    id: id,
  }
}

export function leaseOccupantsLoad(tenantId, leaseId) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/tenants/' + tenantId + '/leases/' + leaseId + '/occupants',
    {},
    config,
    leaseOccupantsLoadRequest,
    leaseOccupantsLoadSuccess,
    leaseOccupantsLoadFailure,
  )
}

export function leaseOccupantSave(tenantId, leaseId, data) {
  const id = data.id || ''
  if (id === '') {
    const config = {
      method: 'post',
      body: JSON.stringify({ occupant: data }),
    }
    return callApi(
      '/tenants/' + tenantId + '/leases/' + leaseId + '/occupants',
      {},
      config,
      leaseOccupantSaveRequest,
      leaseOccupantCreateSuccess,
      leaseOccupantSaveFailure,
    )
  } else {
    const config = {
      method: 'put',
      body: JSON.stringify({ occupant: data }),
    }
    return callApi(
      '/tenants/' + tenantId + '/leases/' + leaseId + '/occupants/' + id,
      {},
      config,
      leaseOccupantSaveRequest,
      leaseOccupantSaveSuccess,
      leaseOccupantSaveFailure,
    )
  }
}

export function leaseOccupantDel(tenantId, leaseId, id) {
  const config = {
    method: 'delete',
  }
  return callApi(
    '/tenants/' + tenantId + '/leases/' + leaseId + '/occupants/' + id,
    {},
    config,
    leaseOccupantDelRequest,
    leaseOccupantDelSuccess,
    leaseOccupantDelFailure,
  )
}
