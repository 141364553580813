import { callApi } from '../utils/apiUtils'

export const LEASE_DOCUMENTS_LOAD_REQUEST = 'LEASE_DOCUMENTS_LOAD_REQUEST'
export const LEASE_DOCUMENTS_LOAD_SUCCESS = 'LEASE_DOCUMENTS_LOAD_SUCCESS'
export const LEASE_DOCUMENTS_LOAD_FAILURE = 'LEASE_DOCUMENTS_LOAD_FAILURE'

export const LEASE_DOCUMENT_SAVE_REQUEST = 'LEASE_DOCUMENT_SAVE_REQUEST'
export const LEASE_DOCUMENT_SAVE_FAILURE = 'LEASE_DOCUMENT_SAVE_FAILURE'
export const LEASE_DOCUMENT_CREATE_SUCCESS = 'LEASE_DOCUMENT_CREATE_SUCCESS'

export const LEASE_DOCUMENT_DEL_REQUEST = 'LEASE_DOCUMENT_DEL_REQUEST'
export const LEASE_DOCUMENT_DEL_SUCCESS = 'LEASE_DOCUMENT_DEL_SUCCESS'
export const LEASE_DOCUMENT_DEL_FAILURE = 'LEASE_DOCUMENT_DEL_FAILURE'

export const OPERATION_DOCUMENT_DEL_REQUEST = 'OPERATION_DOCUMENT_DEL_REQUEST'
export const OPERATION_DOCUMENT_DEL_SUCCESS = 'OPERATION_DOCUMENT_DEL_SUCCESS'
export const OPERATION_DOCUMENT_DEL_FAILURE = 'OPERATION_DOCUMENT_DEL_FAILURE'
function leaseDocumentsLoadRequest() {
  return {
    type: LEASE_DOCUMENTS_LOAD_REQUEST,
  }
}

function leaseDocumentsLoadSuccess(response) {
  const leaseDocuments = response.data.documents
  return {
    type: LEASE_DOCUMENTS_LOAD_SUCCESS,
    documents: leaseDocuments,
  }
}

function leaseDocumentsLoadFailure(error) {
  return {
    type: LEASE_DOCUMENTS_LOAD_FAILURE,
    error: error,
  }
}

function leaseDocumentSaveRequest() {
  return {
    type: LEASE_DOCUMENT_SAVE_REQUEST,
  }
}

function leaseDocumentCreateSuccess(response) {
  const leaseDocument = response.data.document
  return {
    type: LEASE_DOCUMENT_CREATE_SUCCESS,
    document: leaseDocument,
    id: 'New',
  }
}

function leaseDocumentSaveFailure(error) {
  return {
    type: LEASE_DOCUMENT_SAVE_FAILURE,
    error: error,
  }
}

function leaseDocumentDelRequest() {
  return {
    type: LEASE_DOCUMENT_DEL_REQUEST,
  }
}

function leaseDocumentDelSuccess(response) {
  const tenantDocument = response.data.document
  return {
    type: LEASE_DOCUMENT_DEL_SUCCESS,
    document: tenantDocument,
  }
}

function leaseDocumentDelFailure(error) {
  return {
    type: LEASE_DOCUMENT_DEL_FAILURE,
    error: error,
  }
}

function operationDocumentDelRequest() {
  return {
    type: OPERATION_DOCUMENT_DEL_REQUEST,
  }
}

function operationDocumentDelSuccess(response) {
  const tenantDocument = response.data.document
  return {
    type: OPERATION_DOCUMENT_DEL_SUCCESS,
    document: tenantDocument,
  }
}

function operationDocumentDelFailure(error) {
  return {
    type: OPERATION_DOCUMENT_DEL_FAILURE,
    error: error,
  }
}

export function leaseDocumentSave(tenantId, id, data) {
  const config = {
    method: 'post',
    body: JSON.stringify({ document: data }),
  }
  return callApi(
    '/tenants/' + tenantId + '/leases/' + id + '/documents/',
    {},
    config,
    leaseDocumentSaveRequest,
    leaseDocumentCreateSuccess,
    leaseDocumentSaveFailure,
  )
}

export function leaseDocumentsLoad(tenantId, id) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/tenants/' + tenantId + '/leases/' + id + '/documents/',
    {},
    config,
    leaseDocumentsLoadRequest,
    leaseDocumentsLoadSuccess,
    leaseDocumentsLoadFailure,
  )
}

export function leaseDocumentDel(tenantId, leaseId, id) {
  const config = {
    method: 'delete',
  }
  return callApi(
    '/tenants/' + tenantId + '/leases/' + leaseId + '/documents/' + id,
    {},
    config,
    leaseDocumentDelRequest,
    leaseDocumentDelSuccess,
    leaseDocumentDelFailure,
  )
}

/*******Documents in operation ****/
// function OperationDocumentDelSuccess(response) {
//   const operationDocument = response.data.document
//   return {
//     type: OPERATION_DOCUMENT_DEL_SUCCESS,
//     document: operationDocument,
//   }
// }

// function OperationDocumentDelFailure(error) {
//   return {
//     type: OPERATION_DOCUMENT_DEL_FAILURE,
//     error: error,
//   }
// }

export function OperationDocumentSave(leasing_order_id, data) {
  const config = {
    method: 'post',
    body: JSON.stringify({ document: data }),
  }
  return callApi(
    '/leasing_orders/' + leasing_order_id + '/documents/',
    {},
    config,
    leaseDocumentSaveRequest,
    leaseDocumentCreateSuccess,
    leaseDocumentSaveFailure,
  )
}
export function OperationDocumentsLoad(leasing_order_id) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/leasing_orders/' + leasing_order_id + '/documents/',
    {},
    config,
    leaseDocumentsLoadRequest,
    leaseDocumentsLoadSuccess,
    leaseDocumentsLoadFailure,
  )
}

export function OperationDocumentDel(leasing_order_id, id) {
  const config = {
    method: 'delete',
  }
  return callApi(
    '/leasing_orders/' + leasing_order_id + '/documents/' + id,

    {},
    config,
    operationDocumentDelRequest,
    operationDocumentDelSuccess,
    operationDocumentDelFailure,
  )
}
