import {
  ACCOUNTS_LOAD_REQUEST,
  ACCOUNTS_LOAD_SUCCESS,
  ACCOUNTS_LOAD_FAILURE,
  ACCOUNT_EDIT_START,
  ACCOUNT_EDIT_CANCEL,
  ACCOUNT_SAVE_SUCCESS,
  ACCOUNT_CREATE_SUCCESS,
  ACCOUNT_DEL_REQUEST,
  ACCOUNT_DEL_SUCCESS,
  ACCOUNT_DEL_FAILURE,
} from '../actions/account';

const initialState = {
  accounts: [],
  loaded: false,
  editing: {},
  saveError: {},
  loading_accounts: false,
};

function initializeState(){
  return Object.assign({}, initialState);
}

export default function account(state = initializeState(), action = {}) {
  switch (action.type) {
    case ACCOUNTS_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        loading_accounts: true,
      };
    case ACCOUNTS_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        accounts: action.accounts,
        error: null,
        loading_accounts: false,
      };
    case ACCOUNTS_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        accounts: null,
        error: action.error,
        loading_accounts: false,
      };
    case ACCOUNT_EDIT_START:
      return {
        ...state,
        editing: {
          ...state.editing,
          [action.id]: true
        }
      };
    case ACCOUNT_EDIT_CANCEL:
      return {
        ...state,
        editing: {
          ...state.editing,
          [action.id]: false
        }
      };

    case ACCOUNT_SAVE_SUCCESS:
      let accounts = [...state.accounts];
      let accountData = [];
      for (const [index, account] of accounts.entries()) {
        if (account.id === action.id) {
          accountData = [
            ...accounts.slice(0, index),
            action.account,
            ...accounts.slice(index + 1)
          ];
        }
      }
      return {
        ...state,
        accounts: accountData,
        loading_accounts: false,
        editing: {
          ...state.editing,
          [action.id]: false
        },
      };
    case ACCOUNT_CREATE_SUCCESS:
      accounts = [...state.accounts];
      return {
        ...state,
        accounts: [...accounts, action.account],
        loading_accounts: false,
        editing: {
          ...state.editing,
          [action.id]: false
        },
      };

    case ACCOUNT_DEL_REQUEST:
      return {
        ...state,
        deleting: true,
        loading_accounts: true,
      };
    case ACCOUNT_DEL_SUCCESS:
      accounts = [...state.accounts];
      for (const [index, account] of accounts.entries()) {
        if (account.id === action.account.id) {
          accounts.splice( index, 1 );
        }
      }
      return {
        ...state,
        deleting: false,
        deleted: true,
        accounts: accounts,
        error: null,
        loading_accounts: false,
      };
    case ACCOUNT_DEL_FAILURE:
      return {
        ...state,
        deleting: false,
        deleted: false,
        error: action.error,
        loading_accounts: false,
      };

    case 'ACCOUNT_SAVE_REQUEST':
      return {
        ...state,
        loading_accounts: true,
      }
      
    case 'ACCOUNT_SAVE_FAILURE':
      return {
        ...state,
        loading_accounts: false,
      }
    default:
      return state;
  }
}
