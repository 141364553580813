const DEFAULT_STATE = {
  company: null,
  action_status:{
    errMsg: null,
    success: false,
    loading: false,
  }
}

export default function dashboard(state = DEFAULT_STATE, action){
  switch (action.type) {

    // on send request
    case 'COMPANY_SIGNIN_LOAD_REQUEST':
    return {...state, action_status:{
      ...DEFAULT_STATE.action_status,
      loading: true
    }}

    // on success request
    case 'COMPANY_SIGNIN_LOAD_SUCCESS':
    localStorage.setItem("company", action.payload.company.slug)
    return {...state,
      company: action.payload.company,
      action_status:{
        ...DEFAULT_STATE.action_status,
        success: true
      }
    }

    // on failure request
    case 'COMPANY_SIGNIN_LOAD_FAILURE':
    return {...state, action_status:{
      ...DEFAULT_STATE.action_status,
      errMsg: action.payload.error,
      loading: false
    }}

    default:
      return state
  }
}
