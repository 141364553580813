import {
  TENANT_DOCUMENTS_LOAD_REQUEST,
  TENANT_DOCUMENTS_LOAD_SUCCESS,
  TENANT_DOCUMENTS_LOAD_FAILURE,
  TENANT_DOCUMENT_CREATE_SUCCESS,
  TENANT_DOCUMENT_DEL_REQUEST,
  TENANT_DOCUMENT_DEL_SUCCESS,
  TENANT_DOCUMENT_DEL_FAILURE,
} from '../actions/tenantDocument';

const initialState = {
  documents: [],
  loaded: false,
};

function initializeState(){
  return Object.assign({}, initialState);
}

export default function tenantDocument(state = initializeState(), action = {}) {
  switch (action.type) {
    case TENANT_DOCUMENTS_LOAD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case TENANT_DOCUMENTS_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        documents: action.documents,
        error: null
      };
    case TENANT_DOCUMENTS_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        documents: null,
        error: action.error
      };

    case TENANT_DOCUMENT_CREATE_SUCCESS:
      let documents = [...state.documents];
      return {
        ...state,
        documents: [action.document, ...documents],
      };

    case TENANT_DOCUMENT_DEL_REQUEST:
      return {
        ...state,
        deleting: true
      };

    case TENANT_DOCUMENT_DEL_SUCCESS:
      documents = [...state.documents];
      for (const [index, document] of documents.entries()) {
        if (document.id === action.document.id) {
          documents.splice( index, 1 );
        }
      }
      return {
        ...state,
        documents: documents,
        deleting: false,
        deleted: true,
        editing: false,
        error: null
      };

    case TENANT_DOCUMENT_DEL_FAILURE:
      return {
        ...state,
        deleting: false,
        deleted: false,
        error: action.error
      };
    default:
      return state;
  }
}
