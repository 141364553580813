import { callApi } from '../utils/apiUtils';

export const UNIT_TYPE_PRICES_LOAD_REQUEST = 'UNIT_TYPE_PRICES_LOAD_REQUEST';
export const UNIT_TYPE_PRICES_LOAD_SUCCESS = 'UNIT_TYPE_PRICES_LOAD_SUCCESS';
export const UNIT_TYPE_PRICES_LOAD_FAILURE  = 'UNIT_TYPE_PRICES_LOAD_FAILURE';
export const UNIT_TYPE_PRICE_EDIT_START = 'UNIT_TYPE_PRICE_EDIT_START';
export const UNIT_TYPE_PRICE_EDIT_CANCEL = 'UNIT_TYPE_PRICE_EDIT_CANCEL';
export const UNIT_TYPE_PRICE_SAVE_REQUEST = 'UNIT_TYPE_PRICE_SAVE_REQUEST';
export const UNIT_TYPE_PRICE_SAVE_SUCCESS = 'UNIT_TYPE_PRICE_SAVE_SUCCESS';
export const UNIT_TYPE_PRICE_SAVE_FAILURE = 'UNIT_TYPE_PRICE_SAVE_FAILURE';

function unitTypePricesLoadRequest() {
  return {
    type: UNIT_TYPE_PRICES_LOAD_REQUEST,
  };
}

function unitTypePricesLoadSuccess(response) {
  const unitTypePrices = response.data.unit_types;
  return {
    type: UNIT_TYPE_PRICES_LOAD_SUCCESS,
    unitTypePrices: unitTypePrices,
  };
}

function unitTypePricesLoadFailure(error) {
  return {
    type: UNIT_TYPE_PRICES_LOAD_FAILURE,
    error: error,
  };
}

function unitTypePriceSaveRequest() {
  return {
    type: UNIT_TYPE_PRICE_SAVE_REQUEST,
  };
}

function unitTypePriceSaveSuccess(response) {
  const unitTypePrice = response.data.unit_type;
  return {
    type: UNIT_TYPE_PRICE_SAVE_SUCCESS,
    unitTypePrice: unitTypePrice,
    id: unitTypePrice.id,
  };
}

function unitTypePriceSaveFailure(error) {
  return {
    type: UNIT_TYPE_PRICE_SAVE_FAILURE,
    error: error,
  };
}

export function unitTypePriceEdit(id) {
  return {
    type: UNIT_TYPE_PRICE_EDIT_START,
    id: id,
  };
}

export function unitTypePriceEditCancel(id) {
  return {
    type: UNIT_TYPE_PRICE_EDIT_CANCEL,
    id: id,
  };
}

export function unitTypePriceSave(data) {
  const id = data.id || '';
  const config = {
    method: 'put', 
    body: JSON.stringify({unit_type: data}),
  };
  return callApi('/unit_types/'+id, {}, config, unitTypePriceSaveRequest, unitTypePriceSaveSuccess, unitTypePriceSaveFailure);
}

export function unitTypePricesLoad() {
  const config = {
    method: 'get',    
  };
  return callApi('/unit_types/prices', {}, config, unitTypePricesLoadRequest, unitTypePricesLoadSuccess, unitTypePricesLoadFailure);
}

