import { callApi } from '../utils/apiUtils';

export const QUOTATION_SETTINGS_LOAD_REQUEST = 'QUOTATION_SETTINGS_LOAD_REQUEST';
export const QUOTATION_SETTINGS_LOAD_SUCCESS = 'QUOTATION_SETTINGS_LOAD_SUCCESS';
export const QUOTATION_SETTINGS_LOAD_FAILURE  = 'QUOTATION_SETTINGS_LOAD_FAILURE';
export const QUOTATION_SETTING_EDIT_START = 'QUOTATION_SETTING_EDIT_START';
export const QUOTATION_SETTING_EDIT_CANCEL = 'QUOTATION_SETTING_EDIT_CANCEL';
export const QUOTATION_SETTING_SAVE_REQUEST = 'QUOTATION_SETTING_SAVE_REQUEST';
export const QUOTATION_SETTING_SAVE_SUCCESS = 'QUOTATION_SETTING_SAVE_SUCCESS';
export const QUOTATION_SETTING_SAVE_FAILURE = 'QUOTATION_SETTING_SAVE_FAILURE';


function quotationSettingsLoadRequest() {
  return {
    type: QUOTATION_SETTINGS_LOAD_REQUEST,
  };
}

function quotationSettingsLoadSuccess(response) {
  const quotationSettings = response.data.quotation_settings;
  return {
    type: QUOTATION_SETTINGS_LOAD_SUCCESS,
    quotationSettings: quotationSettings,
  };
}

function quotationSettingsLoadFailure(error) {
  return {
    type: QUOTATION_SETTINGS_LOAD_FAILURE,
    error: error,
  };
}

function quotationSettingSaveRequest() {
  return {
    type: QUOTATION_SETTING_SAVE_REQUEST,
  };
}

function quotationSettingSaveSuccess(response) {
  const quotationSetting = response.data.quotation_setting;
  return {
    type: QUOTATION_SETTING_SAVE_SUCCESS,
    quotationSetting: quotationSetting,
    id: quotationSetting.id,
  };
}

function quotationSettingSaveFailure(error) {
  return {
    type: QUOTATION_SETTING_SAVE_FAILURE,
    error: error,
  };
}

export function quotationSettingEdit(id) {
  return {
    type: QUOTATION_SETTING_EDIT_START,
    id: id,
  };
}

export function quotationSettingEditCancel(id) {
  return {
    type: QUOTATION_SETTING_EDIT_CANCEL,
    id: id,
  };
}

export function quotationSettingSave(data) {
  const id = data.id || '';
  const config = {
    method: 'put', 
    body: JSON.stringify({quotation_setting: data}),
  };
  return callApi('/quotation_settings/'+id, {}, config, quotationSettingSaveRequest, quotationSettingSaveSuccess, quotationSettingSaveFailure);
}


export function quotationSettingsLoad() {
  const config = {
    method: 'get',    
  };
  return callApi('/quotation_settings', {}, config, quotationSettingsLoadRequest, quotationSettingsLoadSuccess, quotationSettingsLoadFailure);
}

