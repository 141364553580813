import {
  LEASE_DOCUMENTS_LOAD_REQUEST,
  LEASE_DOCUMENTS_LOAD_SUCCESS,
  LEASE_DOCUMENTS_LOAD_FAILURE,
  LEASE_DOCUMENT_CREATE_SUCCESS,
  LEASE_DOCUMENT_DEL_REQUEST,
  LEASE_DOCUMENT_DEL_SUCCESS,
  LEASE_DOCUMENT_DEL_FAILURE,
  OPERATION_DOCUMENT_DEL_REQUEST,
  OPERATION_DOCUMENT_DEL_SUCCESS,
  OPERATION_DOCUMENT_DEL_FAILURE,
} from '../actions/leaseDocument'

const initialState = {
  documents: [],
  loaded: false,
}

function initializeState() {
  return Object.assign({}, initialState)
}

export default function leaseDocument(state = initializeState(), action = {}) {
  switch (action.type) {
    case LEASE_DOCUMENTS_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case LEASE_DOCUMENTS_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        documents: action.documents,
        error: null,
      }
    case LEASE_DOCUMENTS_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        documents: null,
        error: action.error,
      }

    case LEASE_DOCUMENT_CREATE_SUCCESS:
      let documents = [...state.documents]
      return {
        ...state,
        documents: [action.document, ...documents],
      }

    case LEASE_DOCUMENT_DEL_REQUEST:
      return {
        ...state,
        deleting: true,
      }

    case LEASE_DOCUMENT_DEL_SUCCESS:
      documents = [...state.documents]
      for (const [index, document] of documents.entries()) {
        if (document.id === action.document.id) {
          documents.splice(index, 1)
        }
      }
      return {
        ...state,
        documents: documents,
        deleting: false,
        deleted: true,
        editing: false,
        error: null,
      }

    case LEASE_DOCUMENT_DEL_FAILURE:
      return {
        ...state,
        deleting: false,
        deleted: false,
        error: action.error,
      }

    /***operation delete*/
    case OPERATION_DOCUMENT_DEL_REQUEST:
      return {
        ...state,
        deleting: true,
      }

    case OPERATION_DOCUMENT_DEL_SUCCESS:
      documents = [...state.documents]
      for (const [index, document] of documents.entries()) {
        if (document.id === action.document.id) {
          documents.splice(index, 1)
        }
      }
      return {
        ...state,
        documents: documents,
        deleting: false,
        deleted: true,
        editing: false,
        error: null,
      }

    case OPERATION_DOCUMENT_DEL_FAILURE:
      return {
        ...state,
        deleting: false,
        deleted: false,
        error: action.error,
      }
    default:
      return state
  }
}
