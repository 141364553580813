import axios from 'axios'
import qs from 'qs'
import handleResponseErr from './handleResponseErr'

window.qs = qs

const prefix = '@RAH'
window.RAH_prefix = prefix

const DEFAULT_HEADERS = {
  Accept: 'application/json',
}

function defaultValues(endpoints) {
  let result = {}
  let newResult = {}
  const defaultRequest = {
    status: 'hold',
    error: null,
    fetching: false,
    loading: false,
  }
  endpoints.map(endpoint => {
    const m = result.requests
      ? result.requests.hasOwnProperty(endpoint.id)
      : null
    result = {
      ...result,
      [endpoint.id]: null,
      requests: {
        ...result.requests,
        [endpoint.id]: {
          ...(m ? result.requests[endpoint.id] : null),
          [endpoint.method]: defaultRequest,
        },
      },
    }
  })
  return result
}

function init(config, store) {
  store.dispatch({
    type: `${prefix}_INIT`,
    payload: defaultValues(config.endpoints),
  })
}

function sendAPIRequest(requestConfig) {
  return axios(requestConfig)
}

function removeNullValues(obj) {
  let result = {}

  if (!obj) return false

  Object.keys(obj).map(key => {
    if (!obj[key]) return false

    if (obj[key].constructor === Object && Object.keys(obj[key]).length > 0) {
      result[key] = removeNullValues(obj[key])
      return false
    }

    result[key] = obj[key]
  })
  return result
}

function generateReqConfig(config, endpoint, action) {
  const ep_headers = { ...config.headers, ...endpoint.headers }
  const headers =
    Object.keys(ep_headers).length > 0 ? ep_headers : DEFAULT_HEADERS
  const url = `${config.host}/${endpoint.url}`

  const params = removeNullValues(action.params)
  const data = action.data

  return {
    url: action.vars ? replaceUrl(url, action.vars) : url,
    method: endpoint.method,
    headers,
    data,
    params,
    paramsSerializer: function(params) {
      const serializedParams = qs.stringify(params, {
        encode: false,
        arrayFormat: 'brackets',
      })
      console.log({ params, serializedParams })
      return serializedParams
    },
  }
}

function getData(res, str) {
  if (!str) return res
  const keys = str.split('.')
  if (keys.length === 0) return res[str]
  let result = res
  keys.map(key => {
    result = result[key]
  })
  return result
}

function replaceUrl(url, data) {
  var regex = new RegExp(':(' + Object.keys(data).join('|') + ')', 'g')
  return url.replace(regex, (m, $1) => data[$1] || m)
}

export const reduxAPIHandler = config => store => {
  init(config, store)
  return next => action => {
    const endpoint = config.endpoints.filter(ep => ep.action === action.type)[0]

    if (action.type === 'RAH_RESET') {
      store.dispatch({
        type: `${prefix}_RESET`,
        keys: action.keys,
        reset: action.reset,
      })
      return false
    }

    if (endpoint) {
      const requestConfig = generateReqConfig(config, endpoint, action)
      store.dispatch({
        type: `${prefix}_${endpoint.action.toUpperCase()}_PENDING`,
        endpoint,
      })
      sendAPIRequest(requestConfig)
        .then(response => {
          store.dispatch({
            type: `${prefix}_${endpoint.action.toUpperCase()}_FULFILLED`,
            payload: getData(
              response.data,
              action.response || endpoint.response,
            ),
            endpoint,
            onSuccess: action.onSuccess,
          })
          if (action.onFulfilled) {
            action.onFulfilled(response)
          }
        })
        .catch(err => {
          const error = handleResponseErr(err, config.data_error)
          if (action.onFaild) {
            action.onFaild(error, err)
          } else if (endpoint.onFaild) {
            endpoint.onFaild(error, err)
          } else {
            config.onFaild(error, err)
          }
          store.dispatch({
            type: `${prefix}_${endpoint.action.toUpperCase()}_FAILED`,
            payload: error,
            endpoint,
          })
        })
      return false
    }

    next(action)
  }
}
