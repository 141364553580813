import React from 'react'

const Loading = props => {
  const { error, type, height, position } = props
  let className = 'loading '
  className += error ? 'error ' : ''
  className += type === 'absolute' ? 'absolute ' : ''
  const positions = {
    backgroundPositionX: 'center',
    backgroundPositionY: '100px',
  }
  return (
    <div className={`${className} ${position}`} style={{ height: height ? height : '100%' }}>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  )
}
export default Loading
