import { applyMiddleware, combineReducers, compose, createStore } from 'redux'

import { reduxAPIHandler } from './redux-api-handler'
import reduxAPIConfig from 'API/endpoints'

import thunkMiddleware from 'redux-thunk'
import createLogger from 'redux-logger'
import auth from '../reducers/auth'
import user from '../reducers/user'
import owner from '../reducers/owner'
import unitType from '../reducers/unitType'
import service from '../reducers/service'
import packageType from '../reducers/packageType'
import unitTypePrice from '../reducers/unitTypePrice'
import discount from '../reducers/discount'
import overstayCharge from '../reducers/overstayCharge'
import notificationSetting from '../reducers/notificationSetting'
import interestLevel from '../reducers/interestLevel'
import account from '../reducers/account'
import subAccount from '../reducers/subAccount'
import quotationSetting from '../reducers/quotationSetting'
import compound from '../reducers/compound'
import compoundNote from '../reducers/compoundNote'
import block from '../reducers/block'
import building from '../reducers/building'
import city from '../reducers/city'
import unit from '../reducers/unit'
import unitNote from '../reducers/unitNote'
import lead from '../reducers/lead'
import leadActivity from '../reducers/leadActivity'
import leadQuotation from '../reducers/leadQuotation'
import tenant from '../reducers/tenant'
import operation from '../reducers/operation'
import tenantPayment from '../reducers/tenantPayment'
import tenantQuotation from '../reducers/tenantQuotation'
import tenantNote from '../reducers/tenantNote'
import tenantContract from '../reducers/tenantContract'
import tenantDocument from '../reducers/tenantDocument'
import lease from '../reducers/lease'
import leaseNote from '../reducers/leaseNote'
import leaseOccupant from '../reducers/leaseOccupant'
import leaseVehicle from '../reducers/leaseVehicle'
import leaseDocument from '../reducers/leaseDocument'
import contract from '../reducers/contract'
import dashboard from '../reducers/dashboard'
import company from '../reducers/company'
import settings from '../reducers/settings'
import roles from '../reducers/roles'
import APIHandler from './redux-api-handler/reducer'

import filter from '../reducers/filter'
import postStatus from '../reducers/postStatus'
import { report } from '../reducers/report'
import notification from '../reducers/notification'

import { reducer as formReducer } from 'redux-form'

const logger = createLogger()
const rootReducer = combineReducers({
  auth,
  user,
  owner,
  unitType,
  service,
  packageType,
  unitTypePrice,
  discount,
  overstayCharge,
  notificationSetting,
  interestLevel,
  account,
  subAccount,
  quotationSetting,
  compound,
  compoundNote,
  block,
  building,
  city,
  unit,
  unitNote,
  lead,
  leadActivity,
  leadQuotation,
  tenant,
  operation,
  tenantPayment,
  tenantQuotation,
  tenantNote,
  tenantDocument,
  tenantContract,
  lease,
  leaseNote,
  leaseOccupant,
  leaseVehicle,
  leaseDocument,
  contract,
  filter,
  report,
  notification,
  dashboard,
  company,
  roles,
  settings,
  form: formReducer,
  api: APIHandler,
  postStatus,
})

const initialState = {}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default function configureStore() {
  let store
  if (module.hot) {
    store = createStore(
      rootReducer,
      initialState,
      compose(
        applyMiddleware(
          reduxAPIHandler(reduxAPIConfig),
          thunkMiddleware /** , logger */,
        ),
        window.__REDUX_DEVTOOLS_EXTENSION__ &&
          window.__REDUX_DEVTOOLS_EXTENSION__(),
      ),
    )
  } else {
    store = createStore(
      rootReducer,
      initialState,
      compose(
        applyMiddleware(reduxAPIHandler(reduxAPIConfig), thunkMiddleware),
        f => f,
      ),
    )
  }

  return store
}
