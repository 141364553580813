import {
  LEASE_OCCUPANTS_LOAD_REQUEST,
  LEASE_OCCUPANTS_LOAD_SUCCESS,
  LEASE_OCCUPANTS_LOAD_FAILURE,
  LEASE_OCCUPANT_EDIT_START,
  LEASE_OCCUPANT_EDIT_CANCEL,
  LEASE_OCCUPANT_SAVE_SUCCESS,
  LEASE_OCCUPANT_CREATE_SUCCESS,
  LEASE_OCCUPANT_DEL_REQUEST,
  LEASE_OCCUPANT_DEL_SUCCESS,
  LEASE_OCCUPANT_DEL_FAILURE,
} from '../actions/leaseOccupant'

const initialState = {
  leaseOccupants: [],
  loaded: false,
  editing: false,
  open_form: false,
}

function initializeState() {
  return Object.assign({}, initialState)
}

export default function leaseOccupant(state = initializeState(), action = {}) {
  switch (action.type) {
    case LEASE_OCCUPANTS_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case LEASE_OCCUPANTS_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        leaseOccupants: action.leaseOccupants,
        error: null,
      }
    case LEASE_OCCUPANTS_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        leaseOccupants: [],
        error: action.error,
      }
    case LEASE_OCCUPANT_EDIT_START:
      return {
        ...state,
        open_form: action.id,
        editing: {
          ...state.editing,
          [action.id]: true,
        },
      }
    case LEASE_OCCUPANT_EDIT_CANCEL:
      return {
        ...state,
        open_form: false,
        editing: {
          ...state.editing,
          [action.id]: false,
        },
      }
    case LEASE_OCCUPANT_SAVE_SUCCESS:
      let leaseOccupants = [...state.leaseOccupants]
      let leaseOccupantData = []
      for (const [index, leaseOccupant] of leaseOccupants.entries()) {
        if (leaseOccupant.id === action.id) {
          leaseOccupantData = [
            ...leaseOccupants.slice(0, index),
            action.leaseOccupant,
            ...leaseOccupants.slice(index + 1),
          ]
        }
      }
      return {
        ...state,
        leaseOccupants: leaseOccupantData,
        open_form: false,
        editing: {
          ...state.editing,
          [action.id]: false,
        },
      }
    case LEASE_OCCUPANT_CREATE_SUCCESS:
      leaseOccupants = [...state.leaseOccupants]
      return {
        ...state,
        leaseOccupants: [...leaseOccupants, action.leaseOccupant],
        open_form: false,
        editing: {
          ...state.editing,
          [action.id]: false,
        },
      }
    case LEASE_OCCUPANT_DEL_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case LEASE_OCCUPANT_DEL_SUCCESS:
      leaseOccupants = [...state.leaseOccupants]
      for (const [index, leaseOccupant] of leaseOccupants.entries()) {
        if (leaseOccupant.id === action.leaseOccupant.id) {
          leaseOccupants.splice(index, 1)
        }
      }
      return {
        ...state,
        deleting: false,
        deleted: true,
        leaseOccupants: leaseOccupants,
        error: null,
      }
    case LEASE_OCCUPANT_DEL_FAILURE:
      return {
        ...state,
        deleting: false,
        deleted: false,
        error: action.error,
      }

    default:
      return state
  }
}
