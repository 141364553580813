import { callApi } from '../utils/apiUtils';

export const ACCOUNTS_LOAD_REQUEST = 'ACCOUNTS_LOAD_REQUEST';
export const ACCOUNTS_LOAD_SUCCESS = 'ACCOUNTS_LOAD_SUCCESS';
export const ACCOUNTS_LOAD_FAILURE  = 'ACCOUNTS_LOAD_FAILURE';
export const ACCOUNT_EDIT_START = 'ACCOUNT_EDIT_START';
export const ACCOUNT_EDIT_CANCEL = 'ACCOUNT_EDIT_CANCEL';
export const ACCOUNT_SAVE_REQUEST = 'ACCOUNT_SAVE_REQUEST';
export const ACCOUNT_SAVE_SUCCESS = 'ACCOUNT_SAVE_SUCCESS';
export const ACCOUNT_SAVE_FAILURE = 'ACCOUNT_SAVE_FAILURE';
export const ACCOUNT_CREATE_SUCCESS = 'ACCOUNT_CREATE_SUCCESS';
export const ACCOUNT_DEL_REQUEST = 'ACCOUNT_DEL_REQUEST';
export const ACCOUNT_DEL_SUCCESS = 'ACCOUNT_DEL_SUCCESS';
export const ACCOUNT_DEL_FAILURE = 'ACCOUNT_DEL_FAILURE';

function accountsLoadRequest() {
  return {
    type: ACCOUNTS_LOAD_REQUEST,
  };
}

function accountsLoadSuccess(response) {
  const accounts = response.data.accounts;
  return {
    type: ACCOUNTS_LOAD_SUCCESS,
    accounts: accounts,
  };
}

function accountsLoadFailure(error) {
  return {
    type: ACCOUNTS_LOAD_FAILURE,
    error: error,
  };
}

function accountSaveRequest() {
  return {
    type: ACCOUNT_SAVE_REQUEST,
  };
}

function accountSaveSuccess(response) {
  const account = response.data.account;
  return {
    type: ACCOUNT_SAVE_SUCCESS,
    account: account,
    id: account.id,
  };
}

function accountCreateSuccess(response) {
  const account = response.data.account;
  return {
    type: ACCOUNT_CREATE_SUCCESS,
    account: account,
    id: 'New'
  };
}

function accountSaveFailure(error) {
  return {
    type: ACCOUNT_SAVE_FAILURE,
    error: error,
  };
}

function accountDelRequest() {
  return {
    type: ACCOUNT_DEL_REQUEST,
  };
}

function accountDelSuccess(response) {
  const account = response.data.account;
  return {
    type: ACCOUNT_DEL_SUCCESS,
    account: account,
  };
}

function accountDelFailure(error) {
  return {
    type: ACCOUNT_DEL_FAILURE,
    error: error,
  };
}

export function accountEdit(id) {
  return {
    type: ACCOUNT_EDIT_START,
    id: id,
  };
}

export function accountEditCancel(id) {
  return {
    type: ACCOUNT_EDIT_CANCEL,
    id: id,
  };
}

export function accountSave(data) {
  const id = data.id || '';
  if(id===''){
    const config = {
      method: 'post',
      body: JSON.stringify({account: data}),
    };
    return callApi('/accounts/', {}, config, accountSaveRequest, accountCreateSuccess, accountSaveFailure);
  } else {
    const config = {
      method: 'put',
      body: JSON.stringify({account: data}),
    };
    return callApi('/accounts/'+id, {}, config, accountSaveRequest, accountSaveSuccess, accountSaveFailure);
  }
}

export function accountDel(id) {
  const config = {
    method: 'delete',
  };
  return callApi('/accounts/'+id, {}, config, accountDelRequest, accountDelSuccess, accountDelFailure);
}

export function accountsLoad() {
  const config = {
    method: 'get',
  };
  return callApi('/accounts', {}, config, accountsLoadRequest, accountsLoadSuccess, accountsLoadFailure);
}
