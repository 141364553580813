import { callApi } from '../utils/apiUtils';

export const NOTIFICATIONS_LOAD_REQUEST = 'NOTIFICATIONS_LOAD_REQUEST';
export const NOTIFICATIONS_LOAD_SUCCESS = 'NOTIFICATIONS_LOAD_SUCCESS';
export const NOTIFICATIONS_LOAD_FAILURE  = 'NOTIFICATIONS_LOAD_FAILURE';
export const NOTIFICATIONS_RECEIVED_SUCCESS  = 'NOTIFICATIONS_RECEIVED_SUCCESS';
export const NOTIFICATIONS_RESET  = 'NOTIFICATIONS_RESET';

function notificationsLoadRequest() {
  return {
    type: NOTIFICATIONS_LOAD_REQUEST,
  };
}

function notificationsLoadSuccess(response) {
  const notifications = response.data.notifications;
  const unread_count = response.data.unread_count;
  const is_last_page = response.data.is_last_page;
  const count = response.data.count;
  return {
    type: NOTIFICATIONS_LOAD_SUCCESS,
    notifications: notifications,
    unread_count: unread_count,
    is_last_page: is_last_page,
    count: count,
  };
}

function notificationsLoadFailure(error) {
  return {
    type: NOTIFICATIONS_LOAD_FAILURE,
    error: error,
  };
}

export function notificationsReset(){
  return {
    type: NOTIFICATIONS_RESET,
  };
}

export function notificationReceived(notification){
  return {
    type: NOTIFICATIONS_RECEIVED_SUCCESS,
    notification: notification
  };
}

export function notificationsLoad(params) {
  const config = {
    method: 'get',    
  };
  return callApi('/notifications', params, config, notificationsLoadRequest, notificationsLoadSuccess, notificationsLoadFailure);
}
