import { callApi } from '../utils/apiUtils'

export const SERVICES_LOAD_REQUEST = 'SERVICES_LOAD_REQUEST'
export const SERVICES_LOAD_SUCCESS = 'SERVICES_LOAD_SUCCESS'
export const SERVICES_LOAD_FAILURE = 'SERVICES_LOAD_FAILURE'
export const SERVICE_EDIT_START = 'SERVICE_EDIT_START'
export const SERVICE_EDIT_CANCEL = 'SERVICE_EDIT_CANCEL'
export const SERVICE_SAVE_REQUEST = 'SERVICE_SAVE_REQUEST'
export const SERVICE_SAVE_SUCCESS = 'SERVICE_SAVE_SUCCESS'
export const SERVICE_SAVE_FAILURE = 'SERVICE_SAVE_FAILURE'
export const SERVICE_CREATE_SUCCESS = 'SERVICE_CREATE_SUCCESS'
export const SERVICE_DEL_REQUEST = 'SERVICE_DEL_REQUEST'
export const SERVICE_DEL_SUCCESS = 'SERVICE_DEL_SUCCESS'
export const SERVICE_DEL_FAILURE = 'SERVICE_DEL_FAILURE'

function servicesLoadRequest() {
  return {
    type: SERVICES_LOAD_REQUEST,
  }
}

function servicesLoadSuccess(response) {
  const services = response.data.services
  return {
    type: SERVICES_LOAD_SUCCESS,
    services: services,
  }
}

function servicesLoadFailure(error) {
  return {
    type: SERVICES_LOAD_FAILURE,
    error: error,
  }
}

function serviceSaveRequest() {
  return {
    type: SERVICE_SAVE_REQUEST,
  }
}

function serviceSaveSuccess(response) {
  const service = response.data.service
  return {
    type: SERVICE_SAVE_SUCCESS,
    service: service,
    id: service.id,
  }
}

function serviceCreateSuccess(response) {
  const service = response.data.service
  return {
    type: SERVICE_CREATE_SUCCESS,
    service: service,
    id: 'New',
  }
}

function serviceSaveFailure(error) {
  return {
    type: SERVICE_SAVE_FAILURE,
    error: error,
  }
}

function serviceDelRequest() {
  return {
    type: SERVICE_DEL_REQUEST,
  }
}

function serviceDelSuccess(response) {
  const service = response.data.service
  return {
    type: SERVICE_DEL_SUCCESS,
    service: service,
  }
}

function serviceDelFailure(error) {
  return {
    type: SERVICE_DEL_FAILURE,
    error: error,
  }
}

export function serviceEdit(id) {
  return {
    type: SERVICE_EDIT_START,
    id: id,
  }
}

export function serviceEditCancel(id) {
  return {
    type: SERVICE_EDIT_CANCEL,
    id: id,
  }
}

export function serviceSave(data) {
  const id = data.id || ''
  if (id === '') {
    const config = {
      method: 'post',
      body: JSON.stringify({ service: data }),
    }
    return callApi(
      '/services/',
      {},
      config,
      serviceSaveRequest,
      serviceCreateSuccess,
      serviceSaveFailure,
    )
  } else {
    const config = {
      method: 'put',
      body: JSON.stringify({ service: data }),
    }
    return callApi(
      '/services/' + id,
      {},
      config,
      serviceSaveRequest,
      serviceSaveSuccess,
      serviceSaveFailure,
    )
  }
}

export function serviceDel(id) {
  const config = {
    method: 'delete',
  }
  return callApi(
    '/services/' + id,
    {},
    config,
    serviceDelRequest,
    serviceDelSuccess,
    serviceDelFailure,
  )
}

export function servicesLoad(quotation_id) {
  const config = {
    method: 'get',
  }
  const url = quotation_id ? `/quotations/${quotation_id}/services` : '/services'
  return callApi(url, {}, config, servicesLoadRequest, servicesLoadSuccess, servicesLoadFailure)
}
