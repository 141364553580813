import {
  TENANT_CONTRACTS_LOAD_REQUEST,
  TENANT_CONTRACTS_LOAD_SUCCESS,
  TENANT_CONTRACTS_LOAD_FAILURE,
  TENANT_CONTRACT_SAVE_SUCCESS,
} from '../actions/tenantContract';

const initialState = {
  contracts: [],
  loaded: false,
  editing: false,
};

function initializeState(){
  return Object.assign({}, initialState);
}

export default function tenantContracts(state = initializeState(), action = {}) {
  switch (action.type) {
    case TENANT_CONTRACTS_LOAD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case TENANT_CONTRACTS_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        contracts: action.contracts,
        error: null
      };
    case TENANT_CONTRACTS_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        contracts: null,
        error: action.error
      };

     case TENANT_CONTRACT_SAVE_SUCCESS:
      let contracts = [...state.contracts];
      let contractData = [];
      for (const [index, contract] of contracts.entries()) {
        if (contract.id === action.id) {
          contractData = [
            ...contracts.slice(0, index),
            action.contract,
            ...contracts.slice(index + 1)
          ];
        }
      }
      return {
        ...state,
        contracts: contractData,
        editing: {
          ...state.editing,
          [action.id]: false
        },
      };
    default:
      return state;
  }
}
