import { callApi } from '../utils/apiUtils';

export const INTEREST_LEVELS_LOAD_REQUEST = 'INTEREST_LEVELS_LOAD_REQUEST';
export const INTEREST_LEVELS_LOAD_SUCCESS = 'INTEREST_LEVELS_LOAD_SUCCESS';
export const INTEREST_LEVELS_LOAD_FAILURE  = 'INTEREST_LEVELS_LOAD_FAILURE';
export const INTEREST_LEVEL_EDIT_START = 'INTEREST_LEVEL_EDIT_START';
export const INTEREST_LEVEL_EDIT_CANCEL = 'INTEREST_LEVEL_EDIT_CANCEL';
export const INTEREST_LEVEL_SAVE_REQUEST = 'INTEREST_LEVEL_SAVE_REQUEST';
export const INTEREST_LEVEL_SAVE_SUCCESS = 'INTEREST_LEVEL_SAVE_SUCCESS';
export const INTEREST_LEVEL_SAVE_FAILURE = 'INTEREST_LEVEL_SAVE_FAILURE';
export const INTEREST_LEVEL_CREATE_SUCCESS = 'INTEREST_LEVEL_CREATE_SUCCESS';
export const INTEREST_LEVEL_DEL_REQUEST = 'INTEREST_LEVEL_DEL_REQUEST';
export const INTEREST_LEVEL_DEL_SUCCESS = 'INTEREST_LEVEL_DEL_SUCCESS';
export const INTEREST_LEVEL_DEL_FAILURE = 'INTEREST_LEVEL_DEL_FAILURE';

function interestLevelsLoadRequest() {
  return {
    type: INTEREST_LEVELS_LOAD_REQUEST,
  };
}

function interestLevelsLoadSuccess(response) {
  const interestLevels = response.data.interest_levels;
  return {
    type: INTEREST_LEVELS_LOAD_SUCCESS,
    interestLevels: interestLevels,
  };
}

function interestLevelsLoadFailure(error) {
  return {
    type: INTEREST_LEVELS_LOAD_FAILURE,
    error: error,
  };
}

function interestLevelSaveRequest() {
  return {
    type: INTEREST_LEVEL_SAVE_REQUEST,
  };
}

function interestLevelSaveSuccess(response) {
  const interestLevel = response.data.interest_level;
  return {
    type: INTEREST_LEVEL_SAVE_SUCCESS,
    interestLevel: interestLevel,
    id: interestLevel.id,
  };
}

function interestLevelCreateSuccess(response) {
  const interestLevel = response.data.interest_level;
  return {
    type: INTEREST_LEVEL_CREATE_SUCCESS,
    interestLevel: interestLevel,
    id: 'New'
  };
} 

function interestLevelSaveFailure(error) {
  return {
    type: INTEREST_LEVEL_SAVE_FAILURE,
    error: error,
  };
}

function interestLevelDelRequest() {
  return {
    type: INTEREST_LEVEL_DEL_REQUEST,
  };
}

function interestLevelDelSuccess(response) {
  const interestLevel = response.data.interest_level;
  return {
    type: INTEREST_LEVEL_DEL_SUCCESS,
    interestLevel: interestLevel,
  };
}

function interestLevelDelFailure(error) {
  return {
    type: INTEREST_LEVEL_DEL_FAILURE,
    error: error,
  };
}

export function interestLevelEdit(id) {
  return {
    type: INTEREST_LEVEL_EDIT_START,
    id: id,
  };
}

export function interestLevelEditCancel(id) {
  return {
    type: INTEREST_LEVEL_EDIT_CANCEL,
    id: id,
  };
}

export function interestLevelSave(data) {
  const id = data.id || '';
  if(id===''){
    const config = {
      method: 'post', 
      body: JSON.stringify({interest_level: data}),
    };
    return callApi('/interest_levels/', {}, config, interestLevelSaveRequest, interestLevelCreateSuccess, interestLevelSaveFailure);
  } else {
    const config = {
      method: 'put', 
      body: JSON.stringify({interest_level: data}),
    };
    return callApi('/interest_levels/'+id, {}, config, interestLevelSaveRequest, interestLevelSaveSuccess, interestLevelSaveFailure);
  }
}

export function interestLevelDel(id) {
  const config = {
    method: 'delete',
  };
  return callApi('/interest_levels/'+id, {}, config, interestLevelDelRequest, interestLevelDelSuccess, interestLevelDelFailure);
}

export function interestLevelsLoad() {
  const config = {
    method: 'get',    
  };
  return callApi('/interest_levels', {}, config, interestLevelsLoadRequest, interestLevelsLoadSuccess, interestLevelsLoadFailure);
}

