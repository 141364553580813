import { callApi, sendRequest } from '../utils/apiUtils';

export const TENANT_PAYMENTS_LOAD_REQUEST = 'TENANT_PAYMENTS_LOAD_REQUEST';
export const TENANT_PAYMENTS_LOAD_SUCCESS = 'TENANT_PAYMENTS_LOAD_SUCCESS';
export const TENANT_PAYMENTS_LOAD_FAILURE  = 'TENANT_PAYMENTS_LOAD_FAILURE';

export const TENANT_PAYMENT_SAVE_REQUEST = 'TENANT_PAYMENT_SAVE_REQUEST';
export const TENANT_PAYMENT_SAVE_SUCCESS = 'TENANT_PAYMENT_SAVE_SUCCESS';
export const TENANT_PAYMENT_SAVE_FAILURE  = 'TENANT_PAYMENT_SAVE_FAILURE';


export const TENANT_PAYMENT_EDIT_START  = 'TENANT_PAYMENT_EDIT_START';
export const TENANT_PAYMENT_EDIT_CANCEL  = 'TENANT_PAYMENT_EDIT_CANCEL';


function tenantPaymentsLoadRequest() {
  return {
    type: TENANT_PAYMENTS_LOAD_REQUEST,
  };
}

function tenantPaymentsLoadSuccess(response) {
  const tenantPayments = response.data.payments;
  return {
    type: TENANT_PAYMENTS_LOAD_SUCCESS,
    tenantPayments: tenantPayments,
  };
}

function tenantPaymentsLoadFailure(error) {
  return {
    type: TENANT_PAYMENTS_LOAD_FAILURE,
    error: error,
  };
}

function tenantPaymentSaveRequest() {
  return {
    type: TENANT_PAYMENT_SAVE_REQUEST,
  };
}

function tenantPaymentInvoiceSaveSuccess(response) {
  const tenantPayment = response.data.invoice.payment;
  return {
    type: TENANT_PAYMENT_SAVE_SUCCESS,
    tenantPayment: tenantPayment,
    id: tenantPayment.id,
  };
}

function tenantPaymentCollectionSaveSuccess(response) {
  const tenantPayment = response.data.collection.payment;
  return {
    type: TENANT_PAYMENT_SAVE_SUCCESS,
    tenantPayment: tenantPayment,
    id: tenantPayment.id,
  };
}

function tenantPaymentSaveFailure(error) {
  return {
    type: TENANT_PAYMENT_SAVE_FAILURE,
    error: error,
  };
}

export function tenantPaymentEdit(values) {
  return {
    type: TENANT_PAYMENT_EDIT_START,
    id: values.id,
    action_type: values.action_type,
  };
}

export function tenantPaymentEditCancel() {
  return {
    type: TENANT_PAYMENT_EDIT_CANCEL,
  };
}

export function tenantPaymentsLoad(tenantId, params) {
  const config = {
    method: 'get',
  };
  return callApi('/tenants/'+tenantId+'/payments', params, config, tenantPaymentsLoadRequest, tenantPaymentsLoadSuccess, tenantPaymentsLoadFailure);
}

export function tenantPaymentInvoiceSave(tenantId, paymentId, data) {
  const config = {
    method: 'post',
    body: JSON.stringify({invoice: data}),
  };
  return callApi('/tenants/'+tenantId+'/payments/'+paymentId+'/invoices', {}, config, tenantPaymentSaveRequest, tenantPaymentInvoiceSaveSuccess, tenantPaymentSaveFailure);
}

export function tenantPaymentCollectionSave(tenantId, paymentId, data) {
  const id = data.id;
  const config = {
    method: 'post',
    body: JSON.stringify({payment_collection: data}),
  };
  return callApi('/tenants/'+tenantId+'/payments/'+paymentId+'/invoices/'+id+'/collections', {}, config, tenantPaymentSaveRequest, tenantPaymentCollectionSaveSuccess, tenantPaymentSaveFailure);
}

export function generatePaymentInvoice(tenant_id, payment_id){
  const actionType =  'GENERATE_PAYMENT_INVOICE'
  const URL        =  `/tenants/${tenant_id}/payments/${payment_id}/invoices`
  const config = {
    method: 'POST',
  }

  return sendRequest(
    URL,
    config,
    null,
    actionType,
  )
}
