import {
  LEAD_QUOTATIONS_LOAD_REQUEST,
  LEAD_QUOTATIONS_LOAD_SUCCESS,
  LEAD_QUOTATIONS_LOAD_FAILURE,
  LEAD_QUOTATION_LOAD_REQUEST,
  LEAD_QUOTATION_LOAD_SUCCESS,
  LEAD_QUOTATION_LOAD_FAILURE,
  LEAD_QUOTATION_EDIT_START,
  LEAD_QUOTATION_EDIT_CANCEL,
  LEAD_QUOTATION_SAVE_SUCCESS,
  LEAD_QUOTATION_SAVE_FAILURE,
  LEAD_QUOTATION_CREATE_SUCCESS,
  LEAD_QUOTATION_DEL_REQUEST,
  LEAD_QUOTATION_DEL_SUCCESS,
  LEAD_QUOTATION_DEL_FAILURE,
  LEAD_QUOTATION_UNIT_ACTION_SUCCESS,
  LEAD_QUOTATION_UNIT_ITEM_EDIT_START,
  LEAD_QUOTATION_UNIT_EDIT_START,
} from '../actions/leadQuotation'

const initialState = {
  leadQuotations: [],
  loaded: false,
  editing: false,
  itemEditing: [],
  unitEditing: [],
}

function initializeState() {
  return Object.assign({}, initialState)
}

export default function leadQuotation(state = initializeState(), action = {}) {
  switch (action.type) {
    case LEAD_QUOTATIONS_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case LEAD_QUOTATIONS_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        leadQuotations: action.leadQuotations,
        error: null,
      }
    case LEAD_QUOTATIONS_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        leadQuotations: null,
        error: action.error,
      }
    case LEAD_QUOTATION_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case LEAD_QUOTATION_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        leadQuotation: action.leadQuotation,
        error: null,
      }
    case LEAD_QUOTATION_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        leadQuotation: {},
        error: action.error,
      }
    case LEAD_QUOTATION_EDIT_START:
      return {
        ...state,
        editing: {
          id: action.id,
          action_type: action.action_type,
        },
      }
    case LEAD_QUOTATION_EDIT_CANCEL:
      return {
        ...state,
        leadQuotation: {},
        editing: false,
        itemEditing: [],
        unitEditing: [],
      }

    case 'LEAD_CLOSE_QUOTATION_EDIT':
      return {
        ...state,
        unitEditing: [],
      }

    case LEAD_QUOTATION_SAVE_SUCCESS:
      let leadQuotations = [...state.leadQuotations]
      let leadQuotationData = []
      let editing = false
      for (const [index, leadQuotation] of leadQuotations.entries()) {
        if (leadQuotation.id === action.id) {
          leadQuotationData = [
            ...leadQuotations.slice(0, index),
            action.leadQuotation,
            ...leadQuotations.slice(index + 1),
          ]
        }
      }
      if (state.editing.action_type !== 'invoice') editing = state.editing
      return {
        ...state,
        leadQuotations: leadQuotationData,
        leadQuotation: action.leadQuotation,
        editing: editing,
      }
    case LEAD_QUOTATION_CREATE_SUCCESS:
      const lead_quotations = [...state.leadQuotations]
      lead_quotations.push(action.leadQuotation)

      return {
        ...state,
        leadQuotations: lead_quotations,
        leadQuotation: action.leadQuotation,
        loading: false,
        loaded: true,
        error: null,
        editing: {
          id: action.leadQuotation.id,
          action_type: 'Edit',
        },
      }

    case LEAD_QUOTATION_SAVE_FAILURE:
      return {
        ...state,
        error: action.error,
      }

    case LEAD_QUOTATION_DEL_REQUEST:
    case 'LEAD_FORCE_DELETE_LOAD_REQUEST':
      return {
        ...state,
        deleting: true,
      }
    case LEAD_QUOTATION_DEL_SUCCESS:
    case 'LEAD_FORCE_DELETE_SUCCESS':
      leadQuotations = [...state.leadQuotations]
      for (const [index, leadQuotation] of leadQuotations.entries()) {
        if (leadQuotation.id === action.leadQuotation.id) {
          leadQuotations.splice(index, 1)
        }
      }
      return {
        ...state,
        leadQuotations: leadQuotations,
        deleting: false,
        deleted: true,
        editing: false,
        error: null,
      }

    case LEAD_QUOTATION_DEL_FAILURE:
    case 'LEAD_FORCE_DELETE_LOAD_FAILURE':
      return {
        ...state,
        deleting: false,
        deleted: false,
        error: action.error,
      }

    case LEAD_QUOTATION_UNIT_ACTION_SUCCESS:
      return {
        ...state,
        leadQuotation: action.leadQuotation,
        itemEditing: [],
        unitEditing: [],
      }

    case LEAD_QUOTATION_UNIT_ITEM_EDIT_START:
      return {
        ...state,
        itemEditing: {
          [action.unitId]: true,
        },
      }
    case LEAD_QUOTATION_UNIT_EDIT_START:
      return {
        ...state,
        unitEditing: {
          [action.unitId]: true,
        },
      }

    default:
      return state
  }
}
