import { callApi } from '../utils/apiUtils'

export const LEAD_QUOTATIONS_LOAD_REQUEST = 'LEAD_QUOTATIONS_LOAD_REQUEST'
export const LEAD_QUOTATIONS_LOAD_SUCCESS = 'LEAD_QUOTATIONS_LOAD_SUCCESS'
export const LEAD_QUOTATIONS_LOAD_FAILURE = 'LEAD_QUOTATIONS_LOAD_FAILURE'
export const LEAD_QUOTATION_LOAD_REQUEST = 'LEAD_QUOTATION_LOAD_REQUEST'
export const LEAD_QUOTATION_LOAD_SUCCESS = 'LEAD_QUOTATION_LOAD_SUCCESS'
export const LEAD_QUOTATION_LOAD_FAILURE = 'LEAD_QUOTATION_LOAD_FAILURE'

export const LEAD_QUOTATION_EDIT_START = 'LEAD_QUOTATION_EDIT_START'
export const LEAD_QUOTATION_EDIT_CANCEL = 'LEAD_QUOTATION_EDIT_CANCEL'
export const LEAD_QUOTATION_SAVE_REQUEST = 'LEAD_QUOTATION_SAVE_REQUEST'
export const LEAD_QUOTATION_SAVE_SUCCESS = 'LEAD_QUOTATION_SAVE_SUCCESS'
export const LEAD_QUOTATION_SAVE_FAILURE = 'LEAD_QUOTATION_SAVE_FAILURE'
export const LEAD_QUOTATION_CREATE_SUCCESS = 'LEAD_QUOTATION_CREATE_SUCCESS'
export const LEAD_QUOTATION_DEL_REQUEST = 'LEAD_QUOTATION_DEL_REQUEST'
export const LEAD_QUOTATION_DEL_SUCCESS = 'LEAD_QUOTATION_DEL_SUCCESS'
export const LEAD_QUOTATION_DEL_FAILURE = 'LEAD_QUOTATION_DEL_FAILURE'

export const LEAD_QUOTATION_UNIT_ACTION_SUCCESS =
  'LEAD_QUOTATION_UNIT_ACTION_SUCCESS'
export const LEAD_QUOTATION_UNIT_ITEM_EDIT_START =
  'LEAD_QUOTATION_UNIT_ITEM_EDIT_START'
export const LEAD_QUOTATION_UNIT_EDIT_START = 'LEAD_QUOTATION_UNIT_EDIT_START'

function leadQuotationsLoadRequest() {
  return {
    type: LEAD_QUOTATIONS_LOAD_REQUEST,
  }
}

function leadQuotationsLoadSuccess(response) {
  const leadQuotations = response.data.quotations
  return {
    type: LEAD_QUOTATIONS_LOAD_SUCCESS,
    leadQuotations: leadQuotations,
  }
}

function leadQuotationsLoadFailure(error) {
  return {
    type: LEAD_QUOTATIONS_LOAD_FAILURE,
    error: error,
  }
}

function leadQuotationLoadRequest() {
  return {
    type: LEAD_QUOTATION_LOAD_REQUEST,
  }
}

function leadQuotationLoadSuccess(response) {
  const leadQuotation = response.data.quotation
  return {
    type: LEAD_QUOTATION_LOAD_SUCCESS,
    leadQuotation: leadQuotation,
  }
}

function leadQuotationLoadFailure(error) {
  return {
    type: LEAD_QUOTATION_LOAD_FAILURE,
    error: error,
  }
}

function leadQuotationSaveRequest() {
  return {
    type: LEAD_QUOTATION_SAVE_REQUEST,
  }
}

function leadQuotationSaveSuccess(response) {
  const leadQuotation = response.data.quotation
  return {
    type: LEAD_QUOTATION_SAVE_SUCCESS,
    leadQuotation: leadQuotation,
    id: leadQuotation.id,
  }
}

function leadQuotationCreateSuccess(response) {
  const leadQuotation = response.data.quotation
  return {
    type: LEAD_QUOTATION_CREATE_SUCCESS,
    leadQuotation: leadQuotation,
  }
}

function leadQuotationUnitActionSuccess(response) {
  const leadQuotation = response.data.quotation
  return {
    type: LEAD_QUOTATION_UNIT_ACTION_SUCCESS,
    leadQuotation: leadQuotation,
  }
}

function leadQuotationSaveFailure(error) {
  return {
    type: LEAD_QUOTATION_SAVE_FAILURE,
    error: error.error,
  }
}

function leadQuotationDelRequest() {
  return {
    type: LEAD_QUOTATION_DEL_REQUEST,
  }
}

function leadQuotationDelSuccess(response) {
  const leadQuotation = response.data.quotation
  return {
    type: LEAD_QUOTATION_DEL_SUCCESS,
    leadQuotation: leadQuotation,
  }
}

function leadQuotationDelFailure(error) {
  return {
    type: LEAD_QUOTATION_DEL_FAILURE,
    error: error,
  }
}

export function leadQuotationEdit(values) {
  return {
    type: LEAD_QUOTATION_EDIT_START,
    id: values.id,
    action_type: values.action_type,
  }
}

export function leadQuotationEditCancel(leadId, id) {
  return {
    type: LEAD_QUOTATION_EDIT_CANCEL,
  }
}

export function leadQuotationUnitEdit(unitId) {
  return {
    type: LEAD_QUOTATION_UNIT_EDIT_START,
    unitId,
  }
}

export function leadQuotationUnitItemEdit(unitId) {
  return {
    type: LEAD_QUOTATION_UNIT_ITEM_EDIT_START,
    unitId,
  }
}

export function leadQuotationSave(leadId, data) {
  const id = data.id || ''
  const config = {
    method: 'put',
    body: JSON.stringify({ quotation: data }),
  }
  return callApi(
    '/leads/' + leadId + '/quotations/' + id,
    {},
    config,
    leadQuotationSaveRequest,
    leadQuotationSaveSuccess,
    leadQuotationSaveFailure,
  )
}

export function leadQuotationDel(leadId, id) {
  const config = {
    method: 'delete',
  }
  return callApi(
    '/leads/' + leadId + '/quotations/' + id,
    {},
    config,
    leadQuotationDelRequest,
    leadQuotationDelSuccess,
    leadQuotationDelFailure,
  )
}

export function leadQuotationsLoad(leadId, params) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/leads/' + leadId + '/quotations',
    params,
    config,
    leadQuotationsLoadRequest,
    leadQuotationsLoadSuccess,
    leadQuotationsLoadFailure,
  )
}

export function leadQuotationLoad(leadId, id, params) {
  if (id === '') {
    const config = {
      method: 'post',
      body: JSON.stringify({ quotation: params }),
    }
    return callApi(
      '/leads/' + leadId + '/quotations',
      {},
      config,
      leadQuotationLoadRequest,
      leadQuotationCreateSuccess,
      leadQuotationLoadFailure,
    )
  } else {
    const config = {
      method: 'get',
    }
    return callApi(
      '/leads/' + leadId + '/quotations/' + id,
      {},
      config,
      leadQuotationLoadRequest,
      leadQuotationLoadSuccess,
      leadQuotationLoadFailure,
    )
  }
}

export function leadQuotationStatusSave(leadId, quotationId, data) {
  const status = data.status
  const config = {
    method: 'put',
    ...(status !== 'approve' && { body: JSON.stringify({ quotation: data }) }),
  }

  const url =
    status === 'approve'
      ? `/quotations/${quotationId}/request_for_approval`
      : `/leads/${leadId}/quotations/${quotationId}/status`

  return callApi(
    url,
    {},
    config,
    leadQuotationSaveRequest,
    leadQuotationSaveSuccess,
    leadQuotationSaveFailure,
  )
}

export function leadQuotationCancel(leadId, quotationId) {
  const config = {
    method: 'put',
  }
  return callApi(
    '/leads/' + leadId + '/quotations/' + quotationId + '/cancel',
    {},
    config,
    leadQuotationSaveRequest,
    leadQuotationSaveSuccess,
    leadQuotationSaveFailure,
  )
}

export function GenerateDownPaymentInvoice(leadId, quotationId) {
  const config = {
    method: 'post',
  }
  return callApi(
    '/leads/' + leadId + '/quotations/' + quotationId + '/down_payment_invoice',
    {},
    config,
    leadQuotationSaveRequest,
    leadQuotationSaveSuccess,
    leadQuotationSaveFailure,
  )
}

// export function leadQuotationUnitSave(leadId, quotationId, data) {
//   const config = {
//     method: 'post',
//     body: JSON.stringify({quotation_unit: data}),
//   };
//   return callApi('/leads/'+leadId+'/quotations/'+quotationId+'/units', {}, config, leadQuotationSaveRequest, leadQuotationUnitActionSuccess, leadQuotationSaveFailure);
// }

export function leadQuotationUnitSave(leadId, quotationId, data, renewal) {
  const id = data.id
  if (!id) {
    const config = {
      method: 'post',
      body: JSON.stringify({ quotation_unit: data }),
    }
    return callApi(
      '/leads/' + leadId + '/quotations/' + quotationId + '/units',
      {},
      config,
      leadQuotationSaveRequest,
      leadQuotationUnitActionSuccess,
      leadQuotationSaveFailure,
    )
  } else {
    const config = {
      method: 'put',
      body: JSON.stringify({ quotation_unit: data }),
    }
    const quotation = 'quotations'
    return callApi(
      '/leads/' + leadId + '/' + quotation + '/' + quotationId + '/units/' + id,
      {},
      config,
      leadQuotationSaveRequest,
      leadQuotationUnitActionSuccess,
      leadQuotationSaveFailure,
    )
  }
}

export function leadQuotationUnitDel(leadId, quotationId, id) {
  const config = {
    method: 'delete',
  }
  return callApi(
    '/leads/' + leadId + '/quotations/' + quotationId + '/units/' + id,
    {},
    config,
    leadQuotationSaveRequest,
    leadQuotationUnitActionSuccess,
    leadQuotationSaveFailure,
  )
}

export function leadQuotationUnitItemSave(leadId, quotationId, unitId, data) {
  const config = {
    method: 'post',
    body: JSON.stringify({ quotation_unit_item: data }),
  }
  return callApi(
    '/leads/' +
      leadId +
      '/quotations/' +
      quotationId +
      '/units/' +
      unitId +
      '/items',
    {},
    config,
    leadQuotationSaveRequest,
    leadQuotationUnitActionSuccess,
    leadQuotationSaveFailure,
  )
}

export function leadQuotationUnitItemDel(leadId, quotationId, unitId, id) {
  const config = {
    method: 'delete',
  }
  return callApi(
    '/leads/' +
      leadId +
      '/quotations/' +
      quotationId +
      '/units/' +
      unitId +
      '/items/' +
      id,
    {},
    config,
    leadQuotationSaveRequest,
    leadQuotationUnitActionSuccess,
    leadQuotationSaveFailure,
  )
}

export function leadQuotationInvoiceCreate(leadId, quotationId, data) {
  const config = {
    method: 'post',
    body: JSON.stringify({ virtual_invoice: data }),
  }
  return callApi(
    '/leads/' + leadId + '/quotations/' + quotationId + '/virtual_invoice',
    {},
    config,
    leadQuotationSaveRequest,
    leadQuotationSaveSuccess,
    leadQuotationSaveFailure,
  )
}

export function tenantQuotationForceDelete(id, ref_no) {
  const config = {
    method: 'DELETE',
    body: JSON.stringify({ id, ref_no }),
  }
  return callApi(
    '/quotations_force_delete',
    {},
    config,
    quotationForceDeleteLoadRequest,
    quotationForceDeleteSuccess,
    quotationForceDeleteFailed,
  )
}

function quotationForceDeleteLoadRequest() {
  return {
    type: 'LEAD_FORCE_DELETE_LOAD_REQUEST',
  }
}

function quotationForceDeleteSuccess(response) {
  const leadQuotation = response.data.quotation
  return {
    type: 'LEAD_FORCE_DELETE_SUCCESS',
    leadQuotation: leadQuotation,
  }
}

function quotationForceDeleteFailed(error) {
  return {
    type: 'LEAD_FORCE_DELETE_LOAD_FAILURE',
    error: error,
  }
}
