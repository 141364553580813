import {
  NOTIFICATION_SETTINGS_LOAD_REQUEST,
  NOTIFICATION_SETTINGS_LOAD_SUCCESS,
  NOTIFICATION_SETTINGS_LOAD_FAILURE,
  NOTIFICATION_SETTING_EDIT_START,
  NOTIFICATION_SETTING_EDIT_CANCEL,
  NOTIFICATION_SETTING_SAVE_SUCCESS,
} from '../actions/notificationSetting';

const initialState = {
  notificationSettings: [],
  loaded: false,
  editing: {},
};

function initializeState(){
  return Object.assign({}, initialState);
}

export default function notificationSetting(state = initializeState(), action = {}) {
  switch (action.type) {
    case NOTIFICATION_SETTINGS_LOAD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case NOTIFICATION_SETTINGS_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        notificationSettings: action.notificationSettings,
        error: null
      };
    case NOTIFICATION_SETTINGS_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        notificationSettings: null,
        error: action.error
      };
    case NOTIFICATION_SETTING_EDIT_START:
      return {
        ...state,
        editing: {
          ...state.editing,
          [action.id]: true
        }
      };
    case NOTIFICATION_SETTING_EDIT_CANCEL:
      return {
        ...state,
        editing: {
          ...state.editing,
          [action.id]: false
        }
      };

    case NOTIFICATION_SETTING_SAVE_SUCCESS:
      let notificationSettings = [...state.notificationSettings];
      let notificationSettingData = [];
      for (const [index, notificationSetting] of notificationSettings.entries()) {
        if (notificationSetting.id === action.id) {
          notificationSettingData = [
            ...notificationSettings.slice(0, index),
            action.notificationSetting,
            ...notificationSettings.slice(index + 1)
          ];
        }
      }
      return {
        ...state,
        notificationSettings: notificationSettingData,
        editing: {
          ...state.editing,
          [action.id]: false
        },
      };
    
    default:
      return state;
  }
}
