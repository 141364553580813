import {
  COMPOUND_ID,
  BUILDING_ID, 
  BLOCK_ID,
  UNIT_TYPE_ID,
  LEAD_STATUS,
  STATUS,
  RESET,
} from '../actions/filter';

const initialState = {
  compound_id: '',
  block_id: '',
  building_id: '',
  unit_type_id: '',
  lead_status: '',
  status: '',
};

function initializeState(){
  return Object.assign({}, initialState);
}

export default function block(state = initializeState(), action = {}) {
  switch (action.type) {
    
    case COMPOUND_ID:
      return {
        ...state,
        compound_id: action.id
      };
    case BLOCK_ID:
      return {
        ...state,
        block_id: action.id
      };
    case BUILDING_ID:
      return {
        ...state,
        building_id: action.id
      };
    case UNIT_TYPE_ID:
      return {
        ...state,
        unit_type_id: action.id
      };
    case LEAD_STATUS:
      return {
        ...state,
        lead_status: action.value
      };
    case STATUS:
      return {
        ...state,
        status: action.value
      };
    case RESET:
      return {
        ...state,
        building_id: '',
        block_id: '',
        compound_id: '',
        unit_type_id: '',
        lead_status: '',
        status: '',
      };

    default:
      return state;
  }
}
