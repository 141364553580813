import { callApi } from '../utils/apiUtils'

export const REPORT_LOAD_REQUEST = 'REPORT_LOAD_REQUEST'
export const REPORT_LOAD_SUCCESS = 'REPORT_LOAD_SUCCESS'
export const REPORT_LOAD_FAILURE = 'REPORT_LOAD_FAILURE'
export const REPORT_RESET = 'REPORT_RESET'

function reportLoadRequest() {
  return {
    type: REPORT_LOAD_REQUEST,
  }
}

function reportLoadSuccess(response) {
  return {
    type: REPORT_LOAD_SUCCESS,
    report: response.data.report,
  }
}

function reportLoadFailure() {
  return {
    type: REPORT_LOAD_FAILURE,
  }
}

export function resetReport() {
  return {
    type: REPORT_RESET,
  }
}

export function vacantUnitReport(params) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/reports/units/vacant',
    params,
    config,
    reportLoadRequest,
    reportLoadSuccess,
    reportLoadFailure,
  )
}

export function vatReport(params) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/reports/vat',
    params,
    config,
    reportLoadRequest,
    reportLoadSuccess,
    reportLoadFailure,
  )
}

export function maintenanceReport(params) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/reports/work_orders_by_category',
    params,
    config,
    reportLoadRequest,
    reportLoadSuccess,
    reportLoadFailure,
  )
}

export function vacantUnitByTypeReport(params) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/reports/unit_types/vacant',
    params,
    config,
    reportLoadRequest,
    reportLoadSuccess,
    reportLoadFailure,
  )
}

export function outOfOrderUnitReport(params) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/reports/units/out_of_order',
    params,
    config,
    reportLoadRequest,
    reportLoadSuccess,
    reportLoadFailure,
  )
}

export function outOfOrderUnitByTypeReport(params) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/reports/unit_types/out_of_order',
    params,
    config,
    reportLoadRequest,
    reportLoadSuccess,
    reportLoadFailure,
  )
}

export function opportunityCostReport(params) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/reports/unit_types/opportunity_cost',
    params,
    config,
    reportLoadRequest,
    reportLoadSuccess,
    reportLoadFailure,
  )
}

export function customerLeaseReport(params) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/reports/customer_leases',
    params,
    config,
    reportLoadRequest,
    reportLoadSuccess,
    reportLoadFailure,
  )
}

export function unitLeaseReport(params) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/reports/unit_leases',
    params,
    config,
    reportLoadRequest,
    reportLoadSuccess,
    reportLoadFailure,
  )
}

export function leadTransformationReport(params) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/reports/leads/transformations',
    params,
    config,
    reportLoadRequest,
    reportLoadSuccess,
    reportLoadFailure,
  )
}

export function occupancyRateReport(params) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/reports/occupancy_rate',
    params,
    config,
    reportLoadRequest,
    reportLoadSuccess,
    reportLoadFailure,
  )
}

export function grossLeasableAreaReport(params) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/reports/gross_leasable_area',
    params,
    config,
    reportLoadRequest,
    reportLoadSuccess,
    reportLoadFailure,
  )
}

export function occupancy(params) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/reports/occupancy',
    params,
    config,
    reportLoadRequest,
    reportLoadSuccess,
    reportLoadFailure,
  )
}

/***occupancy details****/
export function occupancyDetails(params) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/reports/occupancy_details',
    params,
    config,
    reportLoadRequest,
    reportLoadSuccess,
    reportLoadFailure,
  )
}
/*******End Occupancy Details********/
export function occupancyNationality(params) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/reports/occupancy_by_nationality',
    params,
    config,
    reportLoadRequest,
    reportLoadSuccess,
    reportLoadFailure,
  )
}

export function contractMovementReport(params) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/reports/contracts/movement',
    params,
    config,
    reportLoadRequest,
    reportLoadSuccess,
    reportLoadFailure,
  )
}

export function contractReport(params) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/reports/contracts',
    params,
    config,
    reportLoadRequest,
    reportLoadSuccess,
    reportLoadFailure,
  )
}

export function agreementReport(params) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/reports/agreements',
    params,
    config,
    reportLoadRequest,
    reportLoadSuccess,
    reportLoadFailure,
  )
}

export function invoiceReport(params) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/reports/invoices',
    params,
    config,
    reportLoadRequest,
    reportLoadSuccess,
    reportLoadFailure,
  )
}

export function serviceReport(params) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/reports/services',
    params,
    config,
    reportLoadRequest,
    reportLoadSuccess,
    reportLoadFailure,
  )
}

export function revenueReport(params) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/reports/revenue',
    params,
    config,
    reportLoadRequest,
    reportLoadSuccess,
    reportLoadFailure,
  )
}

export function detailedRevenueReport(params) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/reports/detailed_revenue',
    params,
    config,
    reportLoadRequest,
    reportLoadSuccess,
    reportLoadFailure,
  )
}

export function agingReport(params) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/reports/aging',
    params,
    config,
    reportLoadRequest,
    reportLoadSuccess,
    reportLoadFailure,
  )
}

export function agingInvoiceReport(params) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/reports/aging_invoice',
    params,
    config,
    reportLoadRequest,
    reportLoadSuccess,
    reportLoadFailure,
  )
}

export function collectionReport(params) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/reports/collections',
    params,
    config,
    reportLoadRequest,
    reportLoadSuccess,
    reportLoadFailure,
  )
}

export function allocationsReport(params) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/reports/allocations',
    params,
    config,
    reportLoadRequest,
    reportLoadSuccess,
    reportLoadFailure,
  )
}

export function collectionsReport(params) {
  const config = {
    method: 'get',
  }
  return callApi(
      '/reports/collections',
      params,
      config,
      reportLoadRequest,
      reportLoadSuccess,
      reportLoadFailure,
  )
}

export function expectedCollectionReport(params) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/reports/expected_collections',
    params,
    config,
    reportLoadRequest,
    reportLoadSuccess,
    reportLoadFailure,
  )
}

export function expectedAllocationsReport(params) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/reports/expected_allocations',
    params,
    config,
    reportLoadRequest,
    reportLoadSuccess,
    reportLoadFailure,
  )
}

export function rentOverdueReport(params) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/reports/rent_overdue',
    params,
    config,
    reportLoadRequest,
    reportLoadSuccess,
    reportLoadFailure,
  )
}
