import {
  UNIT_TYPE_PRICES_LOAD_REQUEST,
  UNIT_TYPE_PRICES_LOAD_SUCCESS,
  UNIT_TYPE_PRICES_LOAD_FAILURE,
  UNIT_TYPE_PRICE_EDIT_START,
  UNIT_TYPE_PRICE_EDIT_CANCEL,
  UNIT_TYPE_PRICE_SAVE_SUCCESS,
} from '../actions/unitTypePrice';

const initialState = {
  unitTypePrices: [],
  loaded: false,
  editing: {},
  saveError: {},
  loading_unitTypePrices: false,
};

function initializeState(){
  return Object.assign({}, initialState);
}

export default function unitTypePrice(state = initializeState(), action = {}) {
  switch (action.type) {
    case UNIT_TYPE_PRICES_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        loading_unitTypePrices: true,
      };
    case UNIT_TYPE_PRICES_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        unitTypePrices: action.unitTypePrices,
        error: null,
        loading_unitTypePrices: false,
      };
    case UNIT_TYPE_PRICES_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        unitTypePrices: null,
        error: action.error,
        loading_unitTypePrices: false,
      };
    case UNIT_TYPE_PRICE_EDIT_START:
      return {
        ...state,
        editing: {
          ...state.editing,
          [action.id]: true
        }
      };
    case UNIT_TYPE_PRICE_EDIT_CANCEL:
      return {
        ...state,
        editing: {
          ...state.editing,
          [action.id]: false
        }
      };

    case UNIT_TYPE_PRICE_SAVE_SUCCESS:
      let unitTypePrices = [...state.unitTypePrices];
      let unitTypePriceData = [];
      for (const [index, unitTypePrice] of unitTypePrices.entries()) {
        if (unitTypePrice.id === action.id) {
          unitTypePriceData = [
            ...unitTypePrices.slice(0, index),
            action.unitTypePrice,
            ...unitTypePrices.slice(index + 1)
          ];
        }
      }
      return {
        ...state,
        unitTypePrices: unitTypePriceData,
        loading_unitTypePrices: false,
        editing: {
          ...state.editing,
          [action.id]: false
        },
      };

    case 'UNIT_TYPE_PRICE_SAVE_REQUEST':
      return {
        ...state,
        loading_unitTypePrices: true,
      }

  
    default:
      return state;
  }
}
