import {
  BLOCKS_LOAD_REQUEST,
  BLOCKS_LOAD_SUCCESS,
  BLOCKS_LOAD_FAILURE,
  BLOCK_EDIT_START,
  BLOCK_EDIT_CANCEL,
  BLOCK_SAVE_SUCCESS,
  BLOCK_CREATE_SUCCESS,
  BLOCK_DEL_REQUEST,
  BLOCK_DEL_SUCCESS,
  BLOCK_DEL_FAILURE,
} from '../actions/block';

const initialState = {
  blocks: [],
  loaded: false,
  editing: false,
};

function initializeState(){
  return Object.assign({}, initialState);
}

export default function block(state = initializeState(), action = {}) {
  switch (action.type) {
    
    case BLOCKS_LOAD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case BLOCKS_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        blocks: action.blocks,
        error: null
      };
    case BLOCKS_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        blocks: [],
        error: action.error
      };
    case BLOCK_EDIT_START:
      return {
        ...state,
        editing: {
          ...state.editing,
          [action.id]: true
        }
      };
    case BLOCK_EDIT_CANCEL:
      return {
        ...state,
        editing: {
          ...state.editing,
          [action.id]: false
        }
      };
    case BLOCK_SAVE_SUCCESS:
      let blocks = [...state.blocks];
      let blockData = [];
      for (const [index, block] of blocks.entries()) {
        if (block.id === action.id) {
          blockData = [
            ...blocks.slice(0, index),
            action.block,
            ...blocks.slice(index + 1)
          ];
        }
      }
      return {
        ...state,
        blocks: blockData,
        editing: {
          ...state.editing,
          [action.id]: false
        },
      };
    case BLOCK_CREATE_SUCCESS:
      blocks = [...state.blocks];
      return {
        ...state,
        blocks: [...blocks, action.block],
        editing: {
          ...state.editing,
          [action.id]: false
        },
      };
    case BLOCK_DEL_REQUEST:
      return {
        ...state,
        deleting: true
      };
    case BLOCK_DEL_SUCCESS:
      blocks = [...state.blocks];
      for (const [index, block] of blocks.entries()) {
        if (block.id === action.block.id) {
          blocks.splice( index, 1 );
        }
      }
      return {
        ...state,
        deleting: false,
        deleted: true,
        blocks: blocks,
        error: null
      };
    case BLOCK_DEL_FAILURE:
      return {
        ...state,
        deleting: false,
        deleted: false,
        error: action.error
      };

    default:
      return state;
  }
}
