const DEFAULT_STATE = {
  roles: null,
  role: null,
  loading: false,
  role_loading: false,
  error: null,
  create_role: {
    loading: false,
    success: false,
  },
  edit_role: {
    loading: false,
    success: false,
  },
}

export default function roles(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case 'ROLLES_LOAD_REQUEST':
      return { ...state, loading: true }

    case 'ROLLES_LOAD_SUCCESS':
      return {
        ...state,
        roles: action.payload.roles,
        pagination: action.payload.pagination,
        loading: false,
      }

    case 'CREATE_ROLE_LOAD_SUCCESS':
      return {
        ...state,
        roles: [...state.roles, action.payload.role],
        role: action.payload.role,
        loading: false,
        create_role: {
          success: true,
          loading: false,
        },
      }

    case 'DELETE_ROLE_LOAD_SUCCESS':
      const roles = state.roles.filter(ro => ro.id !== action.payload.role.id)
      return { ...state, roles, loading: false }

    case 'RESET_ROLE_FORM':
      return {
        ...state,
        create_role: DEFAULT_STATE.create_role,
        edit_role: DEFAULT_STATE.edit_role,
        role: null,
      }

    case 'RESET_CREATE_ROLE':
      return { ...state, create_role: DEFAULT_STATE.create_role }

    case 'RESET_EDIT_ROLE':
      return { ...state, edit_role: DEFAULT_STATE.create_role }

    case 'ROLE_LOAD_REQUEST':
      return { ...state, role_loading: true }

    case 'ROLE_LOAD_SUCCESS':
      return { ...state, role: action.payload.role, role_loading: false }

    case 'EDIT_ROLE_LOAD_SUCCESS':
      return {
        ...state,
        edit_role: {
          success: true,
        },
      }

    case 'CREATE_ROLE_LOAD_REQUEST':
    case 'DELETE_ROLE_LOAD_REQUEST':
      return {
        ...state,
        loading: true,
      }

    case 'CREATE_ROLE_LOAD_FAILURE':
    case 'DELETE_ROLE_LOAD_FAILURE':
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}
