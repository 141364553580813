import {
  SUB_ACCOUNTS_LOAD_REQUEST,
  SUB_ACCOUNTS_LOAD_SUCCESS,
  SUB_ACCOUNTS_LOAD_FAILURE,
  SUB_ACCOUNT_EDIT_START,
  SUB_ACCOUNT_EDIT_CANCEL,
  SUB_ACCOUNT_SAVE_SUCCESS,
  SUB_ACCOUNT_CREATE_SUCCESS,
  SUB_ACCOUNT_DEL_REQUEST,
  SUB_ACCOUNT_DEL_SUCCESS,
  SUB_ACCOUNT_DEL_FAILURE,
} from '../actions/subAccount';

const initialState = {
  subAccounts: [],
  loaded: false,
  editing: {},
  saveError: {},
  loading_subAccounts: false,
};

function initializeState(){
  return Object.assign({}, initialState);
}

export default function subAccount(state = initializeState(), action = {}) {
  switch (action.type) {
    case SUB_ACCOUNTS_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        loading_subAccounts: true,
      };
    case SUB_ACCOUNTS_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        subAccounts: action.subAccounts,
        error: null,
        loading_subAccounts: false,
      };
    case SUB_ACCOUNTS_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        subAccounts: null,
        error: action.error,
        loading_subAccounts: false,
      };
    case SUB_ACCOUNT_EDIT_START:
      return {
        ...state,
        editing: {
          ...state.editing,
          [action.id]: true
        }
      };
    case SUB_ACCOUNT_EDIT_CANCEL:
      return {
        ...state,
        editing: {
          ...state.editing,
          [action.id]: false
        }
      };

    case SUB_ACCOUNT_SAVE_SUCCESS:
      let subAccounts = [...state.subAccounts];
      let subAccountData = [];
      for (const [index, subAccount] of subAccounts.entries()) {
        if (subAccount.id === action.id) {
          subAccountData = [
            ...subAccounts.slice(0, index),
            action.subAccount,
            ...subAccounts.slice(index + 1)
          ];
        }
      }
      return {
        ...state,
        subAccounts: subAccountData,
        loading_subAccounts: false,
        editing: {
          ...state.editing,
          [action.id]: false
        },
      };
    case SUB_ACCOUNT_CREATE_SUCCESS:
      subAccounts = [...state.subAccounts];
      return {
        ...state,
        subAccounts: [...subAccounts, action.subAccount],
        loading_subAccounts: false,
        editing: {
          ...state.editing,
          [action.id]: false
        },
      };

    case SUB_ACCOUNT_DEL_REQUEST:
      return {
        ...state,
        deleting: true,
        loading_subAccounts: true,
      };
    case SUB_ACCOUNT_DEL_SUCCESS:
      subAccounts = [...state.subAccounts];
      for (const [index, subAccount] of subAccounts.entries()) {
        if (subAccount.id === action.subAccount.id) {
          subAccounts.splice( index, 1 );
        }
      }
      return {
        ...state,
        deleting: false,
        deleted: true,
        subAccounts: subAccounts,
        error: null,
        loading_subAccounts: false,
      };
    case SUB_ACCOUNT_DEL_FAILURE:
      return {
        ...state,
        deleting: false,
        deleted: false,
        error: action.error,
        loading_subAccounts: false,
      }

    case 'SUB_ACCOUNT_SAVE_REQUEST':
      return {
        ...state,
        loading_subAccounts: true,
      }

    case 'SUB_ACCOUNT_SAVE_FAILURE':
      return {
        ...state,
        loading_subAccounts: false,
      }
    default:
      return state;
  }
}
