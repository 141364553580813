import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from 'store'
import asyncComponent from './components/AsyncComponent'
import 'rc-datepicker/lib/style.css'
// import './notifications'
import Loading from 'components/Loading'
import { ActionCableProvider } from 'react-actioncable-provider'
import ActionCable from 'actioncable'

const MainApp = asyncComponent(() => import('./MainApp.js'))

const cable = ActionCable.createConsumer('wss://api-dev.brickspms.click/cable')

ReactDOM.render(
  <Provider store={store}>
    <ActionCableProvider cable={cable}>
      <MainApp />
    </ActionCableProvider>
  </Provider>,
  document.getElementById('root'),
)
