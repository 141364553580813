import {
  callApi,
  setIdToken,
  removeIdToken,
  sendRequest,
} from '../utils/apiUtils'

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE'
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE'
export const LOGOUT = 'LOGOUT'

function loginRequest() {
  return {
    type: LOGIN_REQUEST,
  }
}

function loginSuccess(response) {
  const idToken = response.data.auth_token
  const user_id = response.data.user.id
  const user = response.data.user
  const currency = response.data.currency
  const company_id = user.company.id
  localStorage.setItem('companyID', company_id)
  idToken && setIdToken(idToken, user_id)
  return {
    type: LOGIN_SUCCESS,
    user: user,
    currency: currency,
  }
}

function loginFailure(error) {
  removeIdToken()
  return {
    type: LOGIN_FAILURE,
    error: error,
  }
}

function forgotPasswordRequest() {
  return {
    type: FORGOT_PASSWORD_REQUEST,
  }
}

function forgotPasswordSuccess(response) {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
  }
}

function forgotPasswordFailure(error) {
  removeIdToken()
  return {
    type: FORGOT_PASSWORD_FAILURE,
    error: error,
  }
}

function resetPasswordRequest() {
  return {
    type: RESET_PASSWORD_REQUEST,
  }
}

function resetPasswordSuccess() {
  return {
    type: RESET_PASSWORD_SUCCESS,
  }
}

function resetPasswordFailure(error) {
  removeIdToken()
  return {
    type: RESET_PASSWORD_FAILURE,
    error: error,
  }
}

export function login(data) {
  const config = {
    method: 'post',
    body: JSON.stringify(data),
  }
  sessionStorage.removeItem('compounds_filter')
  return callApi(
    '/sessions',
    {},
    config,
    loginRequest,
    loginSuccess,
    loginFailure,
  )
}

export function logout() {
  removeIdToken()
  localStorage.removeItem('company')
  localStorage.removeItem('currency')
  return {
    type: LOGOUT,
    user: null,
  }
}

export function forgotPassword(data) {
  const config = {
    method: 'post',
    body: JSON.stringify(data),
  }
  return callApi(
    '/passwords',
    {},
    config,
    forgotPasswordRequest,
    forgotPasswordSuccess,
    forgotPasswordFailure,
  )
}

export function resetPassword(data, requester) {
  const id = data.id
  const config = {
    method: 'put',
    body: JSON.stringify(data),
  }
  let endpoint = '/passwords/'

  if (requester === 'MobileAccess') {
    endpoint = '/app/passwords/'
  }

  return callApi(
    endpoint + id,
    {},
    config,
    resetPasswordRequest,
    resetPasswordSuccess,
    resetPasswordFailure,
  )
}

export function currentUserLoad() {
  const config = {
    method: 'get',
  }
  return callApi(
    '/users/profile',
    {},
    config,
    loginRequest,
    loginSuccess,
    loginFailure,
  )
}

export function getUserPrivileges(user_id) {
  return {
    type: 'USER_PRIVILEGES',
  }
  // const actionType = 'USER_PRIVILEGES'
  // const URL = '/users/profile'
  // const config = {
  //   method: 'GET',
  // }

  // return sendRequest(URL, config, null, actionType)
}
