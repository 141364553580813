import { callApi } from '../utils/apiUtils';

export const BUILDINGS_LOAD_REQUEST = 'BUILDINGS_LOAD_REQUEST';
export const BUILDINGS_LOAD_SUCCESS = 'BUILDINGS_LOAD_SUCCESS';
export const BUILDINGS_LOAD_FAILURE  = 'BUILDINGS_LOAD_FAILURE';
export const BUILDING_EDIT_START = 'BUILDING_EDIT_START';
export const BUILDING_EDIT_CANCEL = 'BUILDING_EDIT_CANCEL';
export const BUILDING_SAVE_REQUEST = 'BUILDING_SAVE_REQUEST';
export const BUILDING_SAVE_SUCCESS = 'BUILDING_SAVE_SUCCESS';
export const BUILDING_SAVE_FAILURE = 'BUILDING_SAVE_FAILURE';
export const BUILDING_CREATE_SUCCESS = 'BUILDING_CREATE_SUCCESS';
export const BUILDING_DEL_REQUEST = 'BUILDING_DEL_REQUEST';
export const BUILDING_DEL_SUCCESS = 'BUILDING_DEL_SUCCESS';
export const BUILDING_DEL_FAILURE = 'BUILDING_DEL_FAILURE';

function buildingsLoadRequest() {
  return {
    type: BUILDINGS_LOAD_REQUEST,
  };
}

function buildingsLoadSuccess(response) {
  const buildings = response.data.buildings;
  return {
    type: BUILDINGS_LOAD_SUCCESS,
    buildings: buildings,
  };
}

function buildingsLoadFailure(error) {
  return {
    type: BUILDINGS_LOAD_FAILURE,
    error: error,
  };
}

function buildingSaveRequest() {
  return {
    type: BUILDING_SAVE_REQUEST,
  };
}

function buildingSaveSuccess(response) {
  const building = response.data.building;
  return {
    type: BUILDING_SAVE_SUCCESS,
    building: building,
    id: building.id,
  };
}

function buildingCreateSuccess(response) {
  const building = response.data.building;
  return {
    type: BUILDING_CREATE_SUCCESS,
    building: building,
    id: 'New'
  };
}

function buildingSaveFailure(error) {
  return {
    type: BUILDING_SAVE_FAILURE,
    error: error,
  };
}

function buildingDelRequest() {
  return {
    type: BUILDING_DEL_REQUEST,
  };
}

function buildingDelSuccess(response) {
  const building = response.data.building;
  return {
    type: BUILDING_DEL_SUCCESS,
    building: building,
  };
}

function buildingDelFailure(error) {
  return {
    type: BUILDING_DEL_FAILURE,
    error: error,
  };
}


export function buildingsLoad(params) {
  const config = {
    method: 'get',
  };
  return callApi('/buildings', params, config, buildingsLoadRequest, buildingsLoadSuccess, buildingsLoadFailure);
}


export function buildingEdit(id) {
  return {
    type: BUILDING_EDIT_START,
    id: id,
  };
}

export function buildingEditCancel(id) {
  return {
    type: BUILDING_EDIT_CANCEL,
    id: id,
  };
}

export function buildingSave(data) {
  const id = data.id || '';
  if(id===''){
    const config = {
      method: 'post',
      body: JSON.stringify({building: data}),
    };
    return callApi('/buildings', {}, config, buildingSaveRequest, buildingCreateSuccess, buildingSaveFailure);
  } else {
    const config = {
      method: 'put',
      body: JSON.stringify({building: data}),
    };
    return callApi('/buildings/'+id, {}, config, buildingSaveRequest, buildingSaveSuccess, buildingSaveFailure);
  }
}

export function buildingDel(id) {
  const config = {
    method: 'delete',
  };
  return callApi('/buildings/'+id, {}, config, buildingDelRequest, buildingDelSuccess, buildingDelFailure);
}
