import {
  USERS_LOAD_REQUEST,
  USERS_LOAD_SUCCESS,
  USERS_LOAD_FAILURE,
  USER_EDIT_START,
  USER_EDIT_CANCEL,
  USER_SAVE_SUCCESS,
  USER_CREATE_SUCCESS,
  USER_DEL_REQUEST,
  USER_DEL_SUCCESS,
  USER_DEL_FAILURE,
} from '../actions/user';


const initialState = {
  users: [],
  loaded: false,
  editing: {},
  saveError: {},
  loading_users: false,
};

function initializeState(){
  return Object.assign({}, initialState);
}


export default function user(state = initializeState(), action = {}) {
  switch (action.type) {
    case USERS_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        loading_users: true,
      };
    case USERS_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        users: action.users,
        error: null,
        loading_users: false,
      };
    case USERS_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        users: null,
        error: action.error,
        loading_users: false,
      };
    case USER_EDIT_START:
      return {
        ...state,
        editing: {
          ...state.editing,
          [action.id]: true
        }
      };
    case USER_EDIT_CANCEL:
      return {
        ...state,
        editing: {
          ...state.editing,
          [action.id]: false
        }
      };

    case USER_SAVE_SUCCESS:
      let users = [...state.users];
      let userData = [];
      for (const [index, user] of users.entries()) {
        if (user.id === action.id) {
          userData = [
            ...users.slice(0, index),
            action.user,
            ...users.slice(index + 1)
          ];
        }
      }
      return {
        ...state,
        users: userData,
        loading_users: false,
        editing: {
          ...state.editing,
          [action.id]: false
        },
      };
    case USER_CREATE_SUCCESS:
      users = [...state.users];
      return {
        ...state,
        users: [...users, action.user],
        loading_users: false,
        editing: {
          ...state.editing,
          [action.id]: false
        },
      };

    case USER_DEL_REQUEST:
      return {
        ...state,
        deleting: true,
        loading_users: true,
      };
    case USER_DEL_SUCCESS:
      users = [...state.users];
      for (const [index, user] of users.entries()) {
        if (user.id === action.user.id) {
          users.splice( index, 1 );
        }
      }
      return {
        ...state,
        deleting: false,
        deleted: true,
        users: users,
        error: null,
        loading_users: false,
      };
    case USER_DEL_FAILURE:
      return {
        ...state,
        deleting: false,
        deleted: false,
        error: action.error,
        loading_users: false,
      };

      case 'USER_SAVE_REQUEST':
        return {
          ...state,
          loading_users: true,
        }


    default:
      return state;
  }


}
