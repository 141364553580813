import { callApi } from '../utils/apiUtils';

export const CONTRACTS_LOAD_REQUEST = 'CONTRACTS_LOAD_REQUEST';
export const CONTRACTS_LOAD_SUCCESS = 'CONTRACTS_LOAD_SUCCESS';
export const CONTRACTS_LOAD_FAILURE  = 'CONTRACTS_LOAD_FAILURE';

function contractsLoadRequest() {
  return {
    type: CONTRACTS_LOAD_REQUEST,
  };
}

function contractsLoadSuccess(response) {
  const contracts = response.data.contracts;
  return {
    type: CONTRACTS_LOAD_SUCCESS,
    contracts: contracts,
  };
}

function contractsLoadFailure(error) {
  return {
    type: CONTRACTS_LOAD_FAILURE,
    error: error,
  };
}

export function contractsLoad(params) {
  const config = {
    method: 'get',    
  };
  return callApi('/contracts', params, config, contractsLoadRequest, contractsLoadSuccess, contractsLoadFailure);
}
