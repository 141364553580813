import { callApi } from '../utils/apiUtils'

export const USERS_LOAD_REQUEST = 'USERS_LOAD_REQUEST'
export const USERS_LOAD_SUCCESS = 'USERS_LOAD_SUCCESS'
export const USERS_LOAD_FAILURE = 'USERS_LOAD_FAILURE'
export const USER_EDIT_START = 'USER_EDIT_START'
export const USER_EDIT_CANCEL = 'USER_EDIT_CANCEL'
export const USER_SAVE_REQUEST = 'USER_SAVE_REQUEST'
export const USER_SAVE_SUCCESS = 'USER_SAVE_SUCCESS'
export const USER_SAVE_FAILURE = 'USER_SAVE_FAILURE'
export const USER_CREATE_SUCCESS = 'USER_CREATE_SUCCESS'
export const USER_DEL_REQUEST = 'USER_DEL_REQUEST'
export const USER_DEL_SUCCESS = 'USER_DEL_SUCCESS'
export const USER_DEL_FAILURE = 'USER_DEL_FAILURE'

function usersLoadRequest() {
  return {
    type: USERS_LOAD_REQUEST,
  }
}

function usersLoadSuccess(response) {
  const users = response.data.users
  return {
    type: USERS_LOAD_SUCCESS,
    users: users,
  }
}

function usersLoadFailure(error) {
  return {
    type: USERS_LOAD_FAILURE,
    error: error,
  }
}

function userSaveRequest() {
  return {
    type: USER_SAVE_REQUEST,
  }
}

function userSaveSuccess(response) {
  const user = response.data.user
  return {
    type: USER_SAVE_SUCCESS,
    user: user,
    id: user.id,
  }
}

function userCreateSuccess(response) {
  const user = response.data.user
  return {
    type: USER_CREATE_SUCCESS,
    user: user,
    id: 'New',
  }
}

function userSaveFailure(error) {
  return {
    type: USER_SAVE_FAILURE,
    error: error,
  }
}

function userDelRequest() {
  return {
    type: USER_DEL_REQUEST,
  }
}

function userDelSuccess(response) {
  const user = response.data.user
  return {
    type: USER_DEL_SUCCESS,
    user: user,
  }
}

function userDelFailure(error) {
  return {
    type: USER_DEL_FAILURE,
    error: error,
  }
}

export function userEdit(id) {
  return {
    type: USER_EDIT_START,
    id: id,
  }
}

export function userEditCancel(id) {
  return {
    type: USER_EDIT_CANCEL,
    id: id,
  }
}

export function userSave(data) {
  const id = data.id || ''
  if (id === '') {
    const config = {
      method: 'post',
      body: JSON.stringify({ user: data }),
    }
    return callApi('/users/', {}, config, userSaveRequest, userCreateSuccess, userSaveFailure)
  } else {
    const config = {
      method: 'put',
      body: JSON.stringify({ user: data }),
    }
    return callApi('/users/' + id, {}, config, userSaveRequest, userSaveSuccess, userSaveFailure)
  }
}

export function userDel(id) {
  const config = {
    method: 'delete',
  }
  return callApi('/users/' + id, {}, config, userDelRequest, userDelSuccess, userDelFailure)
}

export function usersLoad() {
  const config = {
    method: 'get',
  }
  return callApi(
    '/users',
    { filter: { without_provider: 1 } },
    config,
    usersLoadRequest,
    usersLoadSuccess,
    usersLoadFailure,
  )
}
