import { callApi } from '../utils/apiUtils'

export const LEADS_LOAD_REQUEST = 'LEADS_LOAD_REQUEST'
export const LEADS_LOAD_SUCCESS = 'LEADS_LOAD_SUCCESS'
export const LEADS_LOAD_FAILURE = 'LEADS_LOAD_FAILURE'

export const LEAD_LOAD_REQUEST = 'LEAD_LOAD_REQUEST'
export const LEAD_LOAD_SUCCESS = 'LEAD_LOAD_SUCCESS'
export const LEAD_LOAD_FAILURE = 'LEAD_LOAD_FAILURE'

//export const LEAD_EDIT_START = 'LEAD_EDIT_START';
export const LEAD_EDIT_CANCEL = 'LEAD_EDIT_CANCEL'
export const LEAD_SAVE_REQUEST = 'LEAD_SAVE_REQUEST'
export const LEAD_SAVE_SUCCESS = 'LEAD_SAVE_SUCCESS'
export const LEAD_SAVE_FAILURE = 'LEAD_SAVE_FAILURE'
export const LEAD_CREATE_SUCCESS = 'LEAD_CREATE_SUCCESS'
export const LEAD_DEL_REQUEST = 'LEAD_DEL_REQUEST'
export const LEAD_DEL_SUCCESS = 'LEAD_DEL_SUCCESS'
export const LEAD_DEL_FAILURE = 'LEAD_DEL_FAILURE'

export const LEAD_LOGS_LOAD_REQUEST = 'LEAD_LOGS_LOAD_REQUEST'
export const LEAD_LOGS_LOAD_SUCCESS = 'LEAD_LOGS_LOAD_SUCCESS'
export const LEAD_LOGS_LOAD_FAILURE = 'LEAD_LOGS_LOAD_FAILURE'

export const LEAD_LOST_SUCCESS = 'LEAD_LOST_SUCCESS'

function leadsLoadRequest() {
  return {
    type: LEADS_LOAD_REQUEST,
  }
}

function leadsLoadSuccess(response) {
  const data = response.data
  return {
    type: LEADS_LOAD_SUCCESS,
    data,
  }
}

function leadsLoadFailure(error) {
  return {
    type: LEADS_LOAD_FAILURE,
    error: error,
  }
}

function leadLoadRequest() {
  return {
    type: LEAD_LOAD_REQUEST,
  }
}

function leadLoadSuccess(response) {
  const lead = response.data.lead
  return {
    type: LEAD_LOAD_SUCCESS,
    lead: lead,
  }
}

function leadLoadFailure(error) {
  return {
    type: LEAD_LOAD_FAILURE,
    error: error,
  }
}

function leadSaveRequest() {
  return {
    type: LEAD_SAVE_REQUEST,
  }
}

function leadSaveSuccess(response) {
  const lead = response.data.lead
  return {
    type: LEAD_SAVE_SUCCESS,
    lead: lead,
    id: lead.id,
  }
}

function leadCreateSuccess(response) {
  const lead = response.data.lead
  return {
    type: LEAD_CREATE_SUCCESS,
    lead: lead,
    id: 'New',
  }
}

function leadSaveFailure(error) {
  return {
    type: LEAD_SAVE_FAILURE,
    error: error.error,
  }
}

function leadDelRequest() {
  return {
    type: LEAD_DEL_REQUEST,
  }
}

function leadDelSuccess(response) {
  const lead = response.data.lead
  return {
    type: LEAD_DEL_SUCCESS,
    lead: lead,
  }
}

function leadDelFailure(error) {
  return {
    type: LEAD_DEL_FAILURE,
    error: error,
  }
}

export function leadEdit(values) {
  return {
    type: 'LEAD_EDIT_START',
    id: values.id,
    action_type: values.action_type,
  }
}

function leadLogsLoadRequest() {
  return {
    type: LEAD_LOGS_LOAD_REQUEST,
  }
}

function leadLogsLoadSuccess(response) {
  const leadLogs = response.data.logs
  return {
    type: LEAD_LOGS_LOAD_SUCCESS,
    logs: leadLogs,
  }
}

function leadLogsLoadFailure(error) {
  return {
    type: LEAD_LOGS_LOAD_FAILURE,
    error: error,
  }
}

function leadLostSuccess(response) {
  const lead = response.data.lead
  return {
    type: LEAD_LOST_SUCCESS,
    lead: lead,
  }
}

export function leadEditCancel() {
  return {
    type: LEAD_EDIT_CANCEL,
  }
}

export function leadSave(data) {
  const id = data.id || ''
  if (id === '') {
    const config = {
      method: 'post',
      body: JSON.stringify({ lead: data }),
    }
    return callApi(
      '/leads/',
      {},
      config,
      leadSaveRequest,
      leadCreateSuccess,
      leadSaveFailure,
    )
  } else {
    const config = {
      method: 'put',
      body: JSON.stringify({ lead: data }),
    }
    return callApi(
      '/leads/' + id,
      {},
      config,
      leadSaveRequest,
      leadSaveSuccess,
      leadSaveFailure,
    )
  }
}

export function leadDel(id) {
  const config = {
    method: 'delete',
  }
  return callApi(
    '/leads/' + id,
    {},
    config,
    leadDelRequest,
    leadDelSuccess,
    leadDelFailure,
  )
}

export function leadsLoad(params) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/leads',
    params,
    config,
    leadsLoadRequest,
    leadsLoadSuccess,
    leadsLoadFailure,
  )
}

export function leadLoad(id) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/leads/' + id,
    {},
    config,
    leadLoadRequest,
    leadLoadSuccess,
    leadLoadFailure,
  )
}

export function leadLogsLoad(leadId) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/leads/' + leadId + '/logs',
    {},
    config,
    leadLogsLoadRequest,
    leadLogsLoadSuccess,
    leadLogsLoadFailure,
  )
}

export function leadLostSave(leadId, data) {
  const config = {
    method: 'put',
    body: JSON.stringify({ lost: data }),
  }
  return callApi(
    '/leads/' + leadId + '/lost',
    {},
    config,
    leadSaveRequest,
    leadLostSuccess,
    leadSaveFailure,
  )
}

export function leadStatusSave(leadId, data) {
  const config = {
    method: 'put',
    body: JSON.stringify({ lead: data }),
  }
  return callApi(
    '/leads/' + leadId + '/status',
    {},
    config,
    leadSaveRequest,
    leadLostSuccess,
    leadSaveFailure,
  )
}

function leadFinalizePending() {
  return {
    type: 'LEAD_FINALIZE_PENDING',
  }
}
export function leadFinalizeSave(leadId, data) {
  const config = {
    method: 'put',
    body: JSON.stringify({ lead: data }),
  }
  return callApi(
    '/leads/' + leadId + '/finalize',
    {},
    config,
    leadFinalizePending,
    leadLostSuccess,
    leadSaveFailure,
  )
}

export function leadsLoadMore(params) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/leads',
    params,
    config,
    () => ({ type: 'LEADS_LOAD_MORE_PENDING' }),
    response => ({ type: 'LEADS_LOAD_MORE_SUCCESS', payload: response.data }),
    error => ({ type: 'LEADS_LOAD_MORE_FAILURE', error }),
  )
}
