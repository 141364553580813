const actionBase = 'DASHBOARD_VACANCY_BY_UNIT_TYPE'
const STATE = {
  vacancy_by_unit_type: null,
  errMsg: null,
  sucMsg: null,
  loading: false,
}

export function vacancyByUnitType(action){

  switch (action.type) {

    // On Pending
    case `${actionBase}_LOAD_REQUEST`:
      return {...STATE, loading: true}

    // On success request
    case `${actionBase}_LOAD_SUCCESS`:
      return {
        ...STATE,
        vacancy_by_unit_type: action.payload.vacancy_by_unit_type,
        loading: false
      }

    // On failed request
    case `${actionBase}_LOAD_FAILURE`:
      return {
        ...STATE,
        errMsg: action.payload,
        loading: false
      }

    default:
      return STATE

  }

}
