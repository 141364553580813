import { callApi } from '../utils/apiUtils';

export const TENANT_QUOTATIONS_LOAD_REQUEST = 'TENANT_QUOTATIONS_LOAD_REQUEST';
export const TENANT_QUOTATIONS_LOAD_SUCCESS = 'TENANT_QUOTATIONS_LOAD_SUCCESS';
export const TENANT_QUOTATIONS_LOAD_FAILURE  = 'TENANT_QUOTATIONS_LOAD_FAILURE';
export const TENANT_QUOTATION_LOAD_REQUEST = 'TENANT_QUOTATION_LOAD_REQUEST';
export const TENANT_QUOTATION_LOAD_SUCCESS = 'TENANT_QUOTATION_LOAD_SUCCESS';
export const TENANT_QUOTATION_LOAD_FAILURE  = 'TENANT_QUOTATION_LOAD_FAILURE';

export const TENANT_QUOTATION_EDIT_START = 'TENANT_QUOTATION_EDIT_START';
export const TENANT_QUOTATION_EDIT_CANCEL = 'TENANT_QUOTATION_EDIT_CANCEL';
export const TENANT_QUOTATION_SAVE_REQUEST = 'TENANT_QUOTATION_SAVE_REQUEST';
export const TENANT_QUOTATION_SAVE_SUCCESS = 'TENANT_QUOTATION_SAVE_SUCCESS';
export const TENANT_QUOTATION_SAVE_FAILURE = 'TENANT_QUOTATION_SAVE_FAILURE';
export const TENANT_QUOTATION_CREATE_SUCCESS = 'TENANT_QUOTATION_CREATE_SUCCESS';
export const TENANT_QUOTATION_DEL_REQUEST = 'TENANT_QUOTATION_DEL_REQUEST';
export const TENANT_QUOTATION_DEL_SUCCESS = 'TENANT_QUOTATION_DEL_SUCCESS';
export const TENANT_QUOTATION_DEL_FAILURE = 'TENANT_QUOTATION_DEL_FAILURE';

export const TENANT_QUOTATION_UNIT_ACTION_SUCCESS = 'TENANT_QUOTATION_UNIT_ACTION_SUCCESS';
export const TENANT_QUOTATION_UNIT_ITEM_EDIT_START = 'TENANT_QUOTATION_UNIT_ITEM_EDIT_START';
export const TENANT_QUOTATION_UNIT_EDIT_START = 'TENANT_QUOTATION_UNIT_EDIT_START';

function tenantQuotationsLoadRequest() {
  return {
    type: TENANT_QUOTATIONS_LOAD_REQUEST,
  };
}

function tenantQuotationsLoadSuccess(response) {
  const tenantQuotations = [...response.data.quotations, ...response.data.history];;
  return {
    type: TENANT_QUOTATIONS_LOAD_SUCCESS,
    tenantQuotations: tenantQuotations,
  };
}

function tenantQuotationsLoadFailure(error) {
  return {
    type: TENANT_QUOTATIONS_LOAD_FAILURE,
    error: error,
  };
}

function tenantQuotationLoadRequest() {
  return {
    type: TENANT_QUOTATION_LOAD_REQUEST,
  };
}

function tenantQuotationLoadSuccess(response) {
  const tenantQuotation = response.data.quotation;
  return {
    type: TENANT_QUOTATION_LOAD_SUCCESS,
    tenantQuotation: tenantQuotation,
  };
}

function tenantQuotationLoadFailure(error) {
  return {
    type: TENANT_QUOTATION_LOAD_FAILURE,
    error: error,
  };
}


function tenantQuotationSaveRequest() {
  return {
    type: TENANT_QUOTATION_SAVE_REQUEST,
  };
}

function tenantQuotationSaveSuccess(response) {
  const tenantQuotation = response.data.quotation;
  return {
    type: TENANT_QUOTATION_SAVE_SUCCESS,
    tenantQuotation: tenantQuotation,
    id: tenantQuotation.id,
  };
}

function tenantQuotationCreateSuccess(response) {
  const tenantQuotation = response.data.quotation;
  return {
    type: TENANT_QUOTATION_CREATE_SUCCESS,
    tenantQuotation: tenantQuotation,
  };
}

function tenantQuotationUnitActionSuccess(response) {
  const tenantQuotation = response.data.quotation;
  return {
    type: TENANT_QUOTATION_UNIT_ACTION_SUCCESS,
    tenantQuotation: tenantQuotation,
  };
}

function tenantQuotationSaveFailure(error) {
  return {
    type: TENANT_QUOTATION_SAVE_FAILURE,
    error: error.error,
  };
}

function tenantQuotationDelRequest() {
  return {
    type: TENANT_QUOTATION_DEL_REQUEST,
  };
}

function tenantQuotationDelSuccess(response) {
  const tenantQuotation = response.data.quotation;
  return {
    type: TENANT_QUOTATION_DEL_SUCCESS,
    tenantQuotation: tenantQuotation,
  };
}

function tenantQuotationDelFailure(error) {
  return {
    type: TENANT_QUOTATION_DEL_FAILURE,
    error: error,
  };
}

export function tenantQuotationEdit(values) {
  return {
    type: TENANT_QUOTATION_EDIT_START,
    id: values.id,
    action_type: values.action_type,
  };
}

export function tenantQuotationUnitEdit(unitId) {
  return {
    type: TENANT_QUOTATION_UNIT_EDIT_START,
    unitId,
  };
}

export function tenantQuotationUnitItemEdit(unitId) {
  return {
    type: TENANT_QUOTATION_UNIT_ITEM_EDIT_START,
    unitId,
  };
}

export function tenantQuotationEditCancel(tenantId, id) {
  return {
    type: TENANT_QUOTATION_EDIT_CANCEL,
  };
}

export function tenantQuotationSave(tenantId, data) {
  const id = data.id;
  const config = {
    method: 'put',
    body: JSON.stringify({quotation: data}),
  };
  return callApi('/tenants/'+tenantId+'/quotations/'+id, {}, config, tenantQuotationSaveRequest, tenantQuotationSaveSuccess, tenantQuotationSaveFailure);
}

export function tenantQuotationDel(tenantId, id) {
  const config = {
    method: 'delete',
  };
  return callApi('/tenants/'+tenantId+'/quotations/'+id, {}, config, tenantQuotationDelRequest, tenantQuotationDelSuccess, tenantQuotationDelFailure);
}

export function tenantQuotationsLoad(tenantId, params) {
  const config = {
    method: 'get',
  };
  return callApi('/tenants/'+tenantId+'/quotations', params, config, tenantQuotationsLoadRequest, tenantQuotationsLoadSuccess, tenantQuotationsLoadFailure);
}

export function tenantQuotationLoad(tenantId, id, params) {
  if(id===''){
    const config = {
      method: 'post',
      body: JSON.stringify({quotation: params}),
    };
    return callApi('/tenants/'+tenantId+'/quotations', {}, config, tenantQuotationLoadRequest, tenantQuotationCreateSuccess, tenantQuotationLoadFailure);
  } else{
    const config = {
      method: 'get',
    };
    return callApi('/tenants/'+tenantId+'/quotations/'+id, {}, config, tenantQuotationLoadRequest, tenantQuotationLoadSuccess, tenantQuotationLoadFailure);
  }
}

export function tenantQuotationFinalize(tenantId, quotationId) {
  const config = {
    method: 'put',
  };
  return callApi('/tenants/'+tenantId+'/quotations/'+quotationId+'/finalize', {}, config, tenantQuotationSaveRequest, tenantQuotationSaveSuccess, tenantQuotationSaveFailure);
}

export function tenantQuotationCancel(tenantId, quotationId) {
  const config = {
    method: 'put',
  };
  return callApi('/tenants/'+tenantId+'/quotations/'+quotationId+'/cancel', {}, config, tenantQuotationSaveRequest, tenantQuotationSaveSuccess, tenantQuotationSaveFailure);
}

export function tenantQuotationStatusSave(tenantId, quotationId, data) {
  const config = {
    method: 'put',
    body: JSON.stringify({quotation: data}),
  };
  return callApi('/tenants/'+tenantId+'/quotations/'+quotationId+'/status', {}, config, tenantQuotationSaveRequest, tenantQuotationSaveSuccess, tenantQuotationSaveFailure);
}


export function tenantQuotationUnitSave(tenantId, quotationId, data, renewal) {
  const id = data.id;
  if(id===''){
    const config = {
      method: 'post',
      body: JSON.stringify({quotation_unit: data}),
    };
    return callApi('/tenants/'+tenantId+'/quotations/'+quotationId+'/units', {}, config, tenantQuotationSaveRequest, tenantQuotationUnitActionSuccess, tenantQuotationSaveFailure);
  }else{
    const config = {
      method: 'put',
      body: JSON.stringify({quotation_unit: data}),
    };
    const quotation = renewal ? 'quotations' : 'quotations'
    return callApi('/tenants/'+tenantId+'/'+quotation+'/'+quotationId+'/units/'+id, {}, config, tenantQuotationSaveRequest, tenantQuotationUnitActionSuccess, tenantQuotationSaveFailure);
  }
}


export function tenantQuotationUnitDel(tenantId, quotationId, id) {
  const config = {
    method: 'delete',
  };
  return callApi('/tenants/'+tenantId+'/quotations/'+quotationId+'/units/'+id, {}, config, tenantQuotationSaveRequest, tenantQuotationUnitActionSuccess, tenantQuotationSaveFailure);
}


export function tenantQuotationUnitItemSave(tenantId, quotationId, unitId, data) {
  const config = {
    method: 'post',
    body: JSON.stringify({quotation_unit_item: data}),
  };
  return callApi('/tenants/'+tenantId+'/quotations/'+quotationId+'/units/'+unitId+'/items', {}, config, tenantQuotationSaveRequest, tenantQuotationUnitActionSuccess, tenantQuotationSaveFailure);
}


export function tenantQuotationUnitItemDel(tenantId, quotationId, unitId, id) {
  const config = {
    method: 'delete',
  };
  return callApi('/tenants/'+tenantId+'/quotations/'+quotationId+'/units/'+unitId+'/items/'+id, {}, config, tenantQuotationSaveRequest, tenantQuotationUnitActionSuccess, tenantQuotationSaveFailure);
}

export function tenantQuotationRenewCreate(tenantId, id) {
  const config = {
    method: 'post',
  };
  return callApi('/tenants/'+tenantId+'/quotations/'+id+'/renewals', {}, config, tenantQuotationLoadRequest, tenantQuotationCreateSuccess, tenantQuotationLoadFailure);
}


export function tenantQuotationForceDelete(id, ref_no) {
  const config = {
    method: 'DELETE',
    body: JSON.stringify({id, ref_no}),
  };
  return callApi('/quotations_force_delete', {}, config, quotationForceDeleteLoadRequest, quotationForceDeleteSuccess, quotationForceDeleteFailed);
}

function quotationForceDeleteLoadRequest(){
  return {
    type: 'FORCE_DELETE_LOAD_REQUEST',
  };
}

function quotationForceDeleteSuccess(response){
  const tenantQuotation = response.data.quotation;
  return {
    type: 'FORCE_DELETE_SUCCESS',
    tenantQuotation: tenantQuotation,
  };
}

function quotationForceDeleteFailed(error){
  return {
    type: 'FORCE_DELETE_LOAD_FAILURE',
    error: error,
  };
}

export function tenantQuotationInvoiceCreate(tenantId, quotationId, data) {
  const config = {
    method: 'post',
    body: JSON.stringify({virtual_invoice: data}),
  };
  return callApi('/tenants/'+tenantId+'/quotations/'+quotationId+'/virtual_invoice', {}, config, tenantQuotationSaveRequest, tenantQuotationSaveSuccess, tenantQuotationSaveFailure);
}
