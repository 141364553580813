import { callApi } from '../utils/apiUtils'

export const LEASES_LOAD_REQUEST = 'LEASES_LOAD_REQUEST'
export const LEASES_LOAD_SUCCESS = 'LEASES_LOAD_SUCCESS'
export const LEASES_LOAD_FAILURE = 'LEASES_LOAD_FAILURE'
export const LEASE_LOAD_REQUEST = 'LEASE_LOAD_REQUEST'
export const LEASE_LOAD_SUCCESS = 'LEASE_LOAD_SUCCESS'
export const LEASE_LOAD_FAILURE = 'LEASE_LOAD_FAILURE'

export const LEASE_EDIT_START = 'LEASE_EDIT_START'
export const LEASE_EDIT_CANCEL = 'LEASE_EDIT_CANCEL'
export const LEASE_SAVE_REQUEST = 'LEASE_SAVE_REQUEST'
export const LEASE_SAVE_SUCCESS = 'LEASE_SAVE_SUCCESS'
export const LEASE_SAVE_FAILURE = 'LEASE_SAVE_FAILURE'

function leasesLoadRequest() {
  return {
    type: LEASES_LOAD_REQUEST,
  }
}

function leasesLoadSuccess(response) {
  const leases = response.data.leases
  const count = response.data.count
  return {
    type: LEASES_LOAD_SUCCESS,
    leases: leases,
    count: count,
  }
}

function leasesLoadFailure(error) {
  return {
    type: LEASES_LOAD_FAILURE,
    error: error,
  }
}

function leaseLoadRequest() {
  return {
    type: LEASE_LOAD_REQUEST,
  }
}

function leaseLoadSuccess(response) {
  const lease = response.data.lease
  return {
    type: LEASE_LOAD_SUCCESS,
    lease: lease,
  }
}

function leaseLoadFailure(error) {
  return {
    type: LEASE_LOAD_FAILURE,
    error: error,
  }
}

function leaseSaveRequest() {
  return {
    type: LEASE_SAVE_REQUEST,
  }
}

function leaseSaveSuccess(response) {
  const lease = response.data.lease
  return {
    type: LEASE_SAVE_SUCCESS,
    lease: lease,
    id: lease.id,
  }
}

function leaseTerminateSuccess(response) {
  const lease = response.data.lease
  const { id, clearance_type } = lease.leasing_clearance
  const url = `#/operations/clearance/${id}`
  window.location = url
  return {
    type: LEASE_SAVE_SUCCESS,
    lease: lease,
    id: lease.id,
  }
}

function leaseSaveFailure(error) {
  return {
    type: LEASE_SAVE_FAILURE,
    error: error,
  }
}

export function leaseEdit(values) {
  return {
    type: LEASE_EDIT_START,
    id: values.id,
    action_type: values.action_type,
  }
}

export function leaseEditCancel() {
  return {
    type: LEASE_EDIT_CANCEL,
  }
}

export function leasesLoad(tenantId, params) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/tenants/' + tenantId + '/leases',
    params,
    config,
    leasesLoadRequest,
    leasesLoadSuccess,
    leasesLoadFailure,
  )
}

export function leaseLoad(tenantId, id) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/tenants/' + tenantId + '/leases/' + id,
    {},
    config,
    leaseLoadRequest,
    leaseLoadSuccess,
    leaseLoadFailure,
  )
}

export function leaseSave(data) {
  const id = data.id
  const config = {
    method: 'put',
    body: JSON.stringify({ lease: data }),
  }
  return callApi('/leases/' + id, {}, config, leaseSaveRequest, leaseSaveSuccess, leaseSaveFailure)
}

export function renewLease(tenantId, id) {
  const config = {
    method: 'post',
  }
  return callApi(
    '/tenants/' + tenantId + '/leases/' + id + '/renewals',
    {},
    config,
    leaseSaveRequest,
    leaseSaveSuccess,
    leaseSaveFailure,
  )
}

export function leaseServiceSave(tenantId, id, data) {
  const config = {
    method: 'post',
    body: JSON.stringify({ service: data }),
  }
  return callApi(
    '/tenants/' + tenantId + '/leases/' + id + '/services',
    {},
    config,
    leaseSaveRequest,
    leaseSaveSuccess,
    leaseSaveFailure,
  )
}

export function leaseTerminate(tenantId, id) {
  const config = {
    method: 'post',
  }
  return callApi(
    '/tenants/' + tenantId + '/leases/' + id + '/terminate',
    {},
    config,
    leaseSaveRequest,
    leaseTerminateSuccess,
    leaseSaveFailure,
  )
}
