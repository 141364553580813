import {
  REPORT_LOAD_REQUEST,
  REPORT_LOAD_SUCCESS,
  REPORT_LOAD_FAILURE,
  REPORT_RESET,
} from '../actions/report'

const initialState = {
  records: [],
  loaded: false,
  editing: false,
  loading_records: false,
}

function initializeState() {
  return Object.assign({}, initialState)
}

export function report(state = initializeState(), action = {}) {
  switch (action.type) {
    case REPORT_LOAD_REQUEST:
      return {
        ...state,
        records: [],
        loading: true,
        loading_records: true,
      }
    case REPORT_LOAD_SUCCESS:
      return {
        ...state,
        averages: null,
        totals: null,
        ...action.report,
        loading: false,
        loaded: true,
        error: null,
        loading_records: false,
      }
    case REPORT_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        compounds: null,
        error: action.error,
        loading_records: false,
      }
    case REPORT_RESET:
      return {
        // ...state,
        records: [],
      }
    default:
      return state
  }
}
