import {
  TENANT_QUOTATIONS_LOAD_REQUEST,
  TENANT_QUOTATIONS_LOAD_SUCCESS,
  TENANT_QUOTATIONS_LOAD_FAILURE,
  TENANT_QUOTATION_LOAD_REQUEST,
  TENANT_QUOTATION_LOAD_SUCCESS,
  TENANT_QUOTATION_LOAD_FAILURE,
  TENANT_QUOTATION_EDIT_START,
  TENANT_QUOTATION_EDIT_CANCEL,
  TENANT_QUOTATION_SAVE_SUCCESS,
  TENANT_QUOTATION_SAVE_FAILURE,
  TENANT_QUOTATION_CREATE_SUCCESS,
  TENANT_QUOTATION_DEL_REQUEST,
  TENANT_QUOTATION_DEL_SUCCESS,
  TENANT_QUOTATION_DEL_FAILURE,
  TENANT_QUOTATION_UNIT_ACTION_SUCCESS,
  TENANT_QUOTATION_UNIT_ITEM_EDIT_START,
  TENANT_QUOTATION_UNIT_EDIT_START,
} from '../actions/tenantQuotation';

const initialState = {
  tenantQuotations: [],
  loaded: false,
  editing: false,
  itemEditing: [],
  unitEditing: [],
};

function initializeState(){
  return Object.assign({}, initialState);
}

export default function tenantQuotation(state = initializeState(), action = {}) {
  switch (action.type) {
    case TENANT_QUOTATIONS_LOAD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case TENANT_QUOTATIONS_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        tenantQuotations: action.tenantQuotations,
        error: null
      };
    case TENANT_QUOTATIONS_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        tenantQuotations: null,
        error: action.error
      };
    case TENANT_QUOTATION_LOAD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case TENANT_QUOTATION_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        tenantQuotation: action.tenantQuotation,
        error: null
      };
    case TENANT_QUOTATION_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        tenantQuotation: {},
        error: action.error
      };
    case TENANT_QUOTATION_EDIT_START:
      return {
        ...state,
        editing:  {
          id: action.id,
          action_type: action.action_type,
        }
      };
    case TENANT_QUOTATION_EDIT_CANCEL:
      return {
        ...state,
        tenantQuotation: {},
        editing: false,
        itemEditing: [],
        unitEditing: [],
      };

    case TENANT_QUOTATION_SAVE_SUCCESS:
      let tenantQuotations = [...state.tenantQuotations];
      let tenantQuotationData = [];
      let editing=false;
      for (const [index, tenantQuotation] of tenantQuotations.entries()) {
        if (tenantQuotation.id === action.id) {
          tenantQuotationData = [
            ...tenantQuotations.slice(0, index),
            action.tenantQuotation,
            ...tenantQuotations.slice(index + 1)
          ];
        }
      }
      if(state.editing.action_type!=='invoice')
        editing=state.editing
      return {
        ...state,
        tenantQuotations: tenantQuotationData,
        tenantQuotation: action.tenantQuotation,
        editing: editing
      };
    case TENANT_QUOTATION_CREATE_SUCCESS:
      tenantQuotations = [...state.tenantQuotations];
      return {
        ...state,
        tenantQuotations: [action.tenantQuotation, ...tenantQuotations],
        tenantQuotation: action.tenantQuotation,
        loading: false,
        loaded: true,
        error: null,
        editing:  {
          id: action.tenantQuotation.id,
          action_type: 'Edit',
        },
      };

    case TENANT_QUOTATION_SAVE_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case TENANT_QUOTATION_DEL_REQUEST:
    case 'FORCE_DELETE_LOAD_REQUEST':
      return {
        ...state,
        deleting: true
      };
    case TENANT_QUOTATION_DEL_SUCCESS:
    case 'FORCE_DELETE_SUCCESS':
      tenantQuotations = [...state.tenantQuotations];
      for (const [index, tenantQuotation] of tenantQuotations.entries()) {
        if (tenantQuotation.id === action.tenantQuotation.id) {
          tenantQuotations.splice( index, 1 );
        }
      }
      return {
        ...state,
        tenantQuotations: tenantQuotations,
        deleting: false,
        deleted: true,
        editing: false,
        error: null
      };

    case TENANT_QUOTATION_DEL_FAILURE:
    case 'FORCE_DELETE_LOAD_FAILURE':
      return {
        ...state,
        deleting: false,
        deleted: false,
        error: action.error
      };

    case TENANT_QUOTATION_UNIT_ACTION_SUCCESS:
      return {
        ...state,
        tenantQuotation: action.tenantQuotation,
        itemEditing: [],
        unitEditing: [],
      };

    case TENANT_QUOTATION_UNIT_ITEM_EDIT_START:
      return {
        ...state,
        itemEditing: {
          [action.unitId]: true
        }
      };

    case TENANT_QUOTATION_UNIT_EDIT_START:
      return {
        ...state,
        unitEditing: {
          [action.unitId]: true
        }
      };

    default:
      return state;
  }
}
