import {
  QUOTATION_SETTINGS_LOAD_REQUEST,
  QUOTATION_SETTINGS_LOAD_SUCCESS,
  QUOTATION_SETTINGS_LOAD_FAILURE,
  QUOTATION_SETTING_EDIT_START,
  QUOTATION_SETTING_EDIT_CANCEL,
  QUOTATION_SETTING_SAVE_SUCCESS,
} from '../actions/quotationSetting';

const initialState = {
  quotationSettings: [],
  loaded: false,
  editing: {},
  loading_quotationSettings: false,
};

function initializeState(){
  return Object.assign({}, initialState);
}

export default function quotationSetting(state = initializeState(), action = {}) {
  switch (action.type) {
    case QUOTATION_SETTINGS_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        loading_quotationSettings: true,
      };
    case QUOTATION_SETTINGS_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        quotationSettings: action.quotationSettings,
        error: null,
        loading_quotationSettings: false,
      };
    case QUOTATION_SETTINGS_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        quotationSettings: null,
        error: action.error,
        loading_quotationSettings: false,
      };
    case QUOTATION_SETTING_EDIT_START:
      return {
        ...state,
        editing: {
          [action.id]: true
        }
      };
    case QUOTATION_SETTING_EDIT_CANCEL:
      return {
        ...state,
        editing: {
          ...state.editing,
          [action.id]: false
        }
      };

    case QUOTATION_SETTING_SAVE_SUCCESS:
      let quotationSettings = [...state.quotationSettings];
      let quotationSettingData = [];
      for (const [index, quotationSetting] of quotationSettings.entries()) {
        if (quotationSetting.id === action.id) {
          quotationSettingData = [
            ...quotationSettings.slice(0, index),
            action.quotationSetting,
            ...quotationSettings.slice(index + 1)
          ];
        }
      }
      return {
        ...state,
        quotationSettings: quotationSettingData,
        loading_quotationSettings: false,
        editing: {
          ...state.editing,
          [action.id]: false
        },
      };

    case 'QUOTATION_SETTING_SAVE_REQUEST':
      return {
        ...state,
        loading_quotationSettings: true,
      }

    case 'QUOTATION_SETTING_SAVE_FAILURE':
      return {
        ...state,
        loading_quotationSettings: false,
      }

    default:
      return state;
  }
}
