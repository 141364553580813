import { callApi } from '../utils/apiUtils'

export const UNIT_TYPES_LOAD_REQUEST = 'UNIT_TYPES_LOAD_REQUEST'
export const UNIT_TYPES_LOAD_SUCCESS = 'UNIT_TYPES_LOAD_SUCCESS'
export const UNIT_TYPES_LOAD_FAILURE = 'UNIT_TYPES_LOAD_FAILURE'
export const UNIT_TYPE_EDIT_START = 'UNIT_TYPE_EDIT_START'
export const UNIT_TYPE_EDIT_CANCEL = 'UNIT_TYPE_EDIT_CANCEL'
export const UNIT_TYPE_SAVE_REQUEST = 'UNIT_TYPE_SAVE_REQUEST'
export const UNIT_TYPE_SAVE_SUCCESS = 'UNIT_TYPE_SAVE_SUCCESS'
export const UNIT_TYPE_SAVE_FAILURE = 'UNIT_TYPE_SAVE_FAILURE'
export const UNIT_TYPE_CREATE_SUCCESS = 'UNIT_TYPE_CREATE_SUCCESS'
export const UNIT_TYPE_DEL_REQUEST = 'UNIT_TYPE_DEL_REQUEST'
export const UNIT_TYPE_DEL_SUCCESS = 'UNIT_TYPE_DEL_SUCCESS'
export const UNIT_TYPE_DEL_FAILURE = 'UNIT_TYPE_DEL_FAILURE'

function unitTypesLoadRequest() {
  return {
    type: UNIT_TYPES_LOAD_REQUEST,
  }
}

function unitTypesLoadSuccess(response) {
  const unitTypes = response.data.unit_types
  return {
    type: UNIT_TYPES_LOAD_SUCCESS,
    unitTypes: unitTypes,
  }
}

function unitTypesLoadFailure(error) {
  return {
    type: UNIT_TYPES_LOAD_FAILURE,
    error: error,
  }
}

function unitTypeSaveRequest() {
  return {
    type: UNIT_TYPE_SAVE_REQUEST,
  }
}

function unitTypeSaveSuccess(response) {
  const unitType = response.data.unit_type
  return {
    type: UNIT_TYPE_SAVE_SUCCESS,
    unitType: unitType,
    id: unitType.id,
  }
}

function unitTypeCreateSuccess(response) {
  const unitType = response.data.unit_type
  return {
    type: UNIT_TYPE_CREATE_SUCCESS,
    unitType: unitType,
    id: 'New',
  }
}

function unitTypeSaveFailure(error) {
  return {
    type: UNIT_TYPE_SAVE_FAILURE,
    error: error,
  }
}

function unitTypeDelRequest() {
  return {
    type: UNIT_TYPE_DEL_REQUEST,
  }
}

function unitTypeDelSuccess(response) {
  const unitType = response.data.unit_type
  return {
    type: UNIT_TYPE_DEL_SUCCESS,
    unitType: unitType,
  }
}

function unitTypeDelFailure(error) {
  return {
    type: UNIT_TYPE_DEL_FAILURE,
    error: error,
  }
}

export function unitTypeEdit(id) {
  return {
    type: UNIT_TYPE_EDIT_START,
    id: id,
  }
}

export function unitTypeEditCancel(id) {
  return {
    type: UNIT_TYPE_EDIT_CANCEL,
    id: id,
  }
}

export function unitTypeSave(data) {
  const id = data.id || ''
  if (id === '') {
    const config = {
      method: 'post',
      body: JSON.stringify({ unit_type: data }),
    }
    return callApi(
      '/unit_types/',
      {},
      config,
      unitTypeSaveRequest,
      unitTypeCreateSuccess,
      unitTypeSaveFailure,
    )
  } else {
    const config = {
      method: 'put',
      body: JSON.stringify({ unit_type: data }),
    }
    return callApi(
      '/unit_types/' + id,
      {},
      config,
      unitTypeSaveRequest,
      unitTypeSaveSuccess,
      unitTypeSaveFailure,
    )
  }
}

export function unitTypeDel(id) {
  const config = {
    method: 'delete',
  }
  return callApi(
    '/unit_types/' + id,
    {},
    config,
    unitTypeDelRequest,
    unitTypeDelSuccess,
    unitTypeDelFailure,
  )
}

export function unitTypesLoad(params = {}) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/unit_types',
    params,
    config,
    unitTypesLoadRequest,
    unitTypesLoadSuccess,
    unitTypesLoadFailure,
  )
}

export function unitTypesLoadBrief(params = {}) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/unit_types/brief',
    params,
    config,
    unitTypesLoadRequest,
    unitTypesLoadSuccess,
    unitTypesLoadFailure,
  )
}
