import { callApi } from '../utils/apiUtils';

export const PACKAGE_TYPES_LOAD_REQUEST = 'PACKAGE_TYPES_LOAD_REQUEST';
export const PACKAGE_TYPES_LOAD_SUCCESS = 'PACKAGE_TYPES_LOAD_SUCCESS';
export const PACKAGE_TYPES_LOAD_FAILURE  = 'PACKAGE_TYPES_LOAD_FAILURE';
export const PACKAGE_TYPE_EDIT_START = 'PACKAGE_TYPE_EDIT_START';
export const PACKAGE_TYPE_EDIT_CANCEL = 'PACKAGE_TYPE_EDIT_CANCEL';
export const PACKAGE_TYPE_SAVE_REQUEST = 'PACKAGE_TYPE_SAVE_REQUEST';
export const PACKAGE_TYPE_SAVE_SUCCESS = 'PACKAGE_TYPE_SAVE_SUCCESS';
export const PACKAGE_TYPE_SAVE_FAILURE = 'PACKAGE_TYPE_SAVE_FAILURE';
export const PACKAGE_TYPE_CREATE_SUCCESS = 'PACKAGE_TYPE_CREATE_SUCCESS';
export const PACKAGE_TYPE_DEL_REQUEST = 'PACKAGE_TYPE_DEL_REQUEST';
export const PACKAGE_TYPE_DEL_SUCCESS = 'PACKAGE_TYPE_DEL_SUCCESS';
export const PACKAGE_TYPE_DEL_FAILURE = 'PACKAGE_TYPE_DEL_FAILURE';

function packageTypesLoadRequest() {
  return {
    type: PACKAGE_TYPES_LOAD_REQUEST,
  };
}

function packageTypesLoadSuccess(response) {
  const packageTypes = response.data.packages;
  return {
    type: PACKAGE_TYPES_LOAD_SUCCESS,
    packageTypes: packageTypes,
  };
}

function packageTypesLoadFailure(error) {
  return {
    type: PACKAGE_TYPES_LOAD_FAILURE,
    error: error,
  };
}

function packageTypeSaveRequest() {
  return {
    type: PACKAGE_TYPE_SAVE_REQUEST,
  };
}

function packageTypeSaveSuccess(response) {
  const packageType = response.data.package;
  return {
    type: PACKAGE_TYPE_SAVE_SUCCESS,
    packageType: packageType,
    id: packageType.id,
  };
}

function packageTypeCreateSuccess(response) {
  const packageType = response.data.package;
  return {
    type: PACKAGE_TYPE_CREATE_SUCCESS,
    packageType: packageType,
    id: 'New'
  };
}

function packageTypeSaveFailure(error) {
  return {
    type: PACKAGE_TYPE_SAVE_FAILURE,
    error: error,
  };
}

function packageTypeDelRequest() {
  return {
    type: PACKAGE_TYPE_DEL_REQUEST,
  };
}

function packageTypeDelSuccess(response) {
  const packageType = response.data.package;
  return {
    type: PACKAGE_TYPE_DEL_SUCCESS,
    packageType: packageType,
  };
}

function packageTypeDelFailure(error) {
  return {
    type: PACKAGE_TYPE_DEL_FAILURE,
    error: error,
  };
}

export function packageTypeEdit(id) {
  return {
    type: PACKAGE_TYPE_EDIT_START,
    id: id,
  };
}

export function packageTypeEditCancel(id) {
  return {
    type: PACKAGE_TYPE_EDIT_CANCEL,
    id: id,
  };
}

export function packageTypeSave(data) {
  const id = data.id || '';
  if(id===''){
    const config = {
      method: 'post',
      body: JSON.stringify({package: data}),
    };
    return callApi('/packages/', {}, config, packageTypeSaveRequest, packageTypeCreateSuccess, packageTypeSaveFailure);
  } else {
    const config = {
      method: 'put',
      body: JSON.stringify({package: data}),
    };
    return callApi('/packages/'+id, {}, config, packageTypeSaveRequest, packageTypeSaveSuccess, packageTypeSaveFailure);
  }
}

export function packageTypeDel(id) {
  const config = {
    method: 'delete',
  };
  return callApi('/packages/'+id, {}, config, packageTypeDelRequest, packageTypeDelSuccess, packageTypeDelFailure);
}

export function packageTypesLoad() {
  const config = {
    method: 'get',
  };
  return callApi('/packages', {}, config, packageTypesLoadRequest, packageTypesLoadSuccess, packageTypesLoadFailure);
}
