const actionBase = 'DASHBOARD_AGING'
const STATE = {
  aging: null,
  errMsg: null,
  loading: false,
}

export function aging(action){
  switch (action.type) {

    // On Pending
    case `${actionBase}_LOAD_REQUEST`:
      return {...STATE, loading: true}

    // On success request
    case `${actionBase}_LOAD_SUCCESS`:
      return {
        ...STATE,
        aging: action.payload.aging,
        loading: false
      }

    // On failed request
    case `${actionBase}_LOAD_FAILURE`:
      return {
        ...STATE,
        errMsg: action.payload,
        loading: true
      }

    default:
      return STATE

  }

}
