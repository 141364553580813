import {
  UNIT_NOTES_LOAD_REQUEST,
  UNIT_NOTES_LOAD_SUCCESS,
  UNIT_NOTES_LOAD_FAILURE,
  UNIT_NOTE_EDIT_START,
  UNIT_NOTE_EDIT_CANCEL,
  UNIT_NOTE_CREATE_SUCCESS,
} from '../actions/unitNote';

const initialState = {
  notes: [],
  loaded: false,
  editing: false,
};

function initializeState(){
  return Object.assign({}, initialState);
}

export default function unitNotes(state = initializeState(), action = {}) {
  switch (action.type) {
    case UNIT_NOTES_LOAD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case UNIT_NOTES_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        notes: action.notes,
        error: null
      };
    case UNIT_NOTES_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        notes: null,
        error: action.error
      };

    case UNIT_NOTE_EDIT_START:
      return {
        ...state,
        editing: true
      };
    case UNIT_NOTE_EDIT_CANCEL:
      return {
        ...state,
        editing: false
      };

    case UNIT_NOTE_CREATE_SUCCESS:
      let notes = [...state.notes];
      return {
        ...state,
        notes: [action.note, ...notes],
        editing: false,
      };

    default:
      return state;
  }
}
