import {
  TENANT_PAYMENTS_LOAD_REQUEST,
  TENANT_PAYMENTS_LOAD_SUCCESS,
  TENANT_PAYMENTS_LOAD_FAILURE,
  TENANT_PAYMENT_SAVE_REQUEST,
  TENANT_PAYMENT_SAVE_SUCCESS,
  TENANT_PAYMENT_SAVE_FAILURE,
  TENANT_PAYMENT_EDIT_START,
  TENANT_PAYMENT_EDIT_CANCEL
} from '../actions/tenantPayment';
var Toastr = require('toastr/build/toastr.min.js');

const initialState = {
  tenantPayments: [],
  loaded: false,
  editing: false,
  loading: false,
  generat_invoice: false,
};

function initializeState(){
  return Object.assign({}, initialState);
}

export default function tenant(state = initializeState(), action = {}) {
  switch (action.type) {
    case TENANT_PAYMENTS_LOAD_REQUEST:
      return {
        ...state,
        generat_invoice: false,
      }
    case 'GENERATE_PAYMENT_INVOICE_LOAD_REQUEST':
      return {
        ...state,
        loading: true
      };
    case TENANT_PAYMENTS_LOAD_SUCCESS:
      return {
        ...state,
        tenantPayments: action.tenantPayments,
        loading: false,
        loaded: true,
        error: null
      };
    case TENANT_PAYMENTS_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        tenants: null,
        error: action.error
      };

    case TENANT_PAYMENT_SAVE_REQUEST:
      return {
        ...state,
        saving: true,
      };

    case TENANT_PAYMENT_SAVE_SUCCESS:
      let tenantPayments = [...state.tenantPayments];
      let tenantPaymentData = [];
      for (const [index, tenantPayment] of tenantPayments.entries()) {
        if (tenantPayment.id === action.id) {
          tenantPaymentData = [
            ...tenantPayments.slice(0, index),
            action.tenantPayment,
            ...tenantPayments.slice(index + 1)
          ];
        }
      }
      return {
        ...state,
        tenantPayments: tenantPaymentData,
        editing: false,
        saving: false,
      };

    case TENANT_PAYMENT_SAVE_FAILURE:
      return {
        ...state,
        error: action.error,
        saving: false,
      };

    case TENANT_PAYMENT_EDIT_START:
      var breakups=[];
      if(action.action_type==='invoice'){
        state.tenantPayments.map((payment)=> {
          if(payment.id===action.id){
            payment.breakups_with_due_amount.map((breakup)=> {
              breakups.push({id: breakup.id, name: breakup.quotation_unit_item.item_type==="unit" ? "Lease Amount" : breakup.quotation_unit_item.description})
            })
            return;
          }
        })
      }
      return {
        ...state,
        editing:  {
          id: action.id,
          action_type: action.action_type,
          breakups: breakups,
        }
      };
    case TENANT_PAYMENT_EDIT_CANCEL:
      return {
        ...state,
        editing: false
      };

    case 'GENERATE_PAYMENT_INVOICE_LOAD_FAILURE':
      return {
        ...state,
        loading: false,
      }

    case 'GENERATE_PAYMENT_INVOICE_LOAD_SUCCESS':
      Toastr.success('Invoice generated successfully');
      return {
        ...state,
        loading: false,
        generat_invoice: true,
      }

    default:
      return state;
  }
}
