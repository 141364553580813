const defaultRequest = {
  status: 'hold',
  fetching: false,
  loading: false,
  error: null,
}
function handleOnSuccess(data, payload, ep, status) {
  const arr = typeof data === 'object' && data ? data : []
  switch (status) {
    case 'unshift':
      arr.unshift(payload)
      return arr

    case 'push':
      arr.push(payload)
      return arr

    case 'remove':
      return arr.filter(item => item[ep.removeBy] != payload[ep.removeBy])

    case 'update':
      const objIndex = arr.findIndex(
        obj => obj[ep.updateBy] === payload[ep.updateBy],
      )
      arr[objIndex] = payload
      return [...arr]

    case 'merge':
      return [...arr, ...payload]

    default:
      return payload
  }
}

function handleOnPending(data, action) {
  switch (action) {
    case 'reset':
      return null

    default:
      return data
  }
}

export default function reducer(state = {}, action) {
  const prefix = window.RAH_prefix

  if (action.type === `${prefix}_INIT`) {
    return action.payload
  }

  if (action.type === `${prefix}_RESET`) {
    action.keys.map(key => {
      state[key] = !action.reset
        ? null
        : action.reset === 'data' || action.reset === 'all'
        ? null
        : state[key]
      Object.keys(state.requests[key]).map(method => {
        state.requests[key][method] = !action.reset
          ? defaultRequest
          : action.reset === 'request' || action.reset === 'all'
          ? defaultRequest
          : state.requests[key][method]
      })
    })
    return state
  }

  if (action.type.startsWith(prefix)) {
    const status = action.type.split('_').slice(-1)[0]
    const ep = action.endpoint

    switch (status) {
      case 'PENDING':
        return {
          ...state,
          [ep.id]: handleOnPending(state[ep.id], ep.onPending),
          requests: {
            ...state.requests,
            [ep.id]: {
              ...state.requests[ep.id],
              [ep.method]: {
                ...state.requests[ep.id][ep.method],
                status: 'pending',
                fetching: true,
                loading: state[ep.id] ? false : true,
                error: null,
              },
            },
          },
        }

      case 'FULFILLED':
        return {
          ...state,
          [ep.id]: handleOnSuccess(
            state[ep.id],
            action.payload,
            ep,
            ep.onSuccess || action.onSuccess,
          ),
          requests: {
            ...state.requests,
            [ep.id]: {
              ...state.requests[ep.id],
              [ep.method]: {
                ...state.requests[ep.id][ep.method],
                status: 'fulfilled',
                loading: false,
                fetching: false,
                error: null,
              },
            },
          },
        }

      case 'FAILED':
        return {
          ...state,
          requests: {
            ...state.requests,
            [ep.id]: {
              ...state.requests[ep.id],
              [ep.method]: {
                ...state.requests[ep.id][ep.method],
                status: 'failed',
                loading: false,
                fetching: false,
                error: action.payload,
              },
            },
          },
        }
    }
  }

  return state
}
