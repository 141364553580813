const actionBase = 'DASHBOARD_SIGNED_CONTRACTS'
const STATE = {
  signed_contracts: null,
  errMsg: null,
  loading: false,
}

export function signedContracts(action){
  switch (action.type) {

    // On Pending
    case `${actionBase}_LOAD_REQUEST`:
      return {...STATE, loading: true}

    // On success request
    case `${actionBase}_LOAD_SUCCESS`:

      return {
        ...STATE,
        signed_contracts: action.payload.signed_contracts,
        loading: false
      }

    // On failed request
    case `${actionBase}_LOAD_FAILURE`:
      return {
        ...STATE,
        errMsg: action.payload,
        loading: true
      }

    default:
      return STATE

  }

}
