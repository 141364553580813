import { callApi } from '../utils/apiUtils';

export const TENANT_CONTRACTS_LOAD_REQUEST = 'TENANT_CONTRACTS_LOAD_REQUEST';
export const TENANT_CONTRACTS_LOAD_SUCCESS = 'TENANT_CONTRACTS_LOAD_SUCCESS';
export const TENANT_CONTRACTS_LOAD_FAILURE  = 'TENANT_CONTRACTS_LOAD_FAILURE';

export const TENANT_CONTRACT_SAVE_REQUEST = 'TENANT_CONTRACT_SAVE_REQUEST';
export const TENANT_CONTRACT_SAVE_FAILURE = 'TENANT_CONTRACT_SAVE_FAILURE';
export const TENANT_CONTRACT_SAVE_SUCCESS = 'TENANT_CONTRACT_SAVE_SUCCESS';


function tenantContractsLoadRequest() {
  return {
    type: TENANT_CONTRACTS_LOAD_REQUEST,
  };
}

function tenantContractsLoadSuccess(response) {
  const tenantContracts = response.data.contracts;
  return {
    type: TENANT_CONTRACTS_LOAD_SUCCESS,
    contracts: tenantContracts,
  };
}

function tenantContractsLoadFailure(error) {
  return {
    type: TENANT_CONTRACTS_LOAD_FAILURE,
    error: error,
  };
}

function tenantContractSaveRequest() {
  return {
    type: TENANT_CONTRACT_SAVE_REQUEST,
  };
}

function tenantContractSaveSuccess(response) {
  const tenantContract = response.data.contract;
  return {
    type: TENANT_CONTRACT_SAVE_SUCCESS,
    contract: tenantContract,
    id: tenantContract.id
  };
}


function tenantContractSaveFailure(error) {
  return {
    type: TENANT_CONTRACT_SAVE_FAILURE,
    error: error,
  };
}

export function tenantContractStatusSave(tenantId, contractId, data) {
  const config = {
    method: 'put',
    body: JSON.stringify({contract: data}),
  };
  return callApi('/tenants/'+tenantId+'/contracts/'+contractId+'/status', {}, config, tenantContractSaveRequest, tenantContractSaveSuccess, tenantContractSaveFailure);
}

export function tenantContractSave(tenantId, data) {
  const id = data.id;
  const config = {
    method: 'put',
    body: JSON.stringify({contract: data}),
  };
  return callApi('/tenants/'+tenantId+'/contracts/'+id, {}, config, tenantContractSaveRequest, tenantContractSaveSuccess, tenantContractSaveFailure);
}


export function tenantContractsLoad(tenantId) {
  const config = {
    method: 'get',
  };
  return callApi('/tenants/'+tenantId+'/contracts/', {}, config, tenantContractsLoadRequest, tenantContractsLoadSuccess, tenantContractsLoadFailure);
}
