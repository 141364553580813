import { callApi } from '../utils/apiUtils';

export const TENANT_DOCUMENTS_LOAD_REQUEST = 'TENANT_DOCUMENTS_LOAD_REQUEST';
export const TENANT_DOCUMENTS_LOAD_SUCCESS = 'TENANT_DOCUMENTS_LOAD_SUCCESS';
export const TENANT_DOCUMENTS_LOAD_FAILURE  = 'TENANT_DOCUMENTS_LOAD_FAILURE';

export const TENANT_DOCUMENT_SAVE_REQUEST = 'TENANT_DOCUMENT_SAVE_REQUEST';
export const TENANT_DOCUMENT_SAVE_FAILURE = 'TENANT_DOCUMENT_SAVE_FAILURE';
export const TENANT_DOCUMENT_CREATE_SUCCESS = 'TENANT_DOCUMENT_CREATE_SUCCESS';

export const TENANT_DOCUMENT_DEL_REQUEST = 'TENANT_DOCUMENT_DEL_REQUEST';
export const TENANT_DOCUMENT_DEL_SUCCESS = 'TENANT_DOCUMENT_DEL_SUCCESS';
export const TENANT_DOCUMENT_DEL_FAILURE = 'TENANT_DOCUMENT_DEL_FAILURE';


function tenantDocumentsLoadRequest() {
  return {
    type: TENANT_DOCUMENTS_LOAD_REQUEST,
  };
}

function tenantDocumentsLoadSuccess(response) {
  const tenantDocuments = response.data.documents;
  return {
    type: TENANT_DOCUMENTS_LOAD_SUCCESS,
    documents: tenantDocuments,
  };
}

function tenantDocumentsLoadFailure(error) {
  return {
    type: TENANT_DOCUMENTS_LOAD_FAILURE,
    error: error,
  };
}

function tenantDocumentSaveRequest() {
  return {
    type: TENANT_DOCUMENT_SAVE_REQUEST,
  };
}

function tenantDocumentCreateSuccess(response) {
  const tenantDocument = response.data.document;
  return {
    type: TENANT_DOCUMENT_CREATE_SUCCESS,
    document: tenantDocument,
    id: 'New'
  };
}

function tenantDocumentSaveFailure(error) {
  return {
    type: TENANT_DOCUMENT_SAVE_FAILURE,
    error: error,
  };
}

function tenantDocumentDelRequest() {
  return {
    type: TENANT_DOCUMENT_DEL_REQUEST,
  };
}

function tenantDocumentDelSuccess(response) {
  const tenantDocument = response.data.document;
  return {
    type: TENANT_DOCUMENT_DEL_SUCCESS,
    document: tenantDocument,
  };
}

function tenantDocumentDelFailure(error) {
  return {
    type: TENANT_DOCUMENT_DEL_FAILURE,
    error: error,
  };
}

export function tenantDocumentSave(tenantId, data) {
  const config = {
    method: 'post',
    body: JSON.stringify({document: data}),
  };
  return callApi('/tenants/'+tenantId+'/documents/', {}, config, tenantDocumentSaveRequest, tenantDocumentCreateSuccess, tenantDocumentSaveFailure);
}

export function tenantDocumentsLoad(tenantId) {
  const config = {
    method: 'get',
  };
  return callApi('/tenants/'+tenantId+'/documents/', {}, config, tenantDocumentsLoadRequest, tenantDocumentsLoadSuccess, tenantDocumentsLoadFailure);
}

export function tenantDocumentDel(tenantId, id) {
  const config = {
    method: 'delete',
  };
  return callApi('/tenants/'+tenantId+'/documents/'+id, {}, config, tenantDocumentDelRequest, tenantDocumentDelSuccess, tenantDocumentDelFailure);
}
