import {
  OWNERS_LOAD_REQUEST,
  OWNERS_LOAD_SUCCESS,
  OWNERS_LOAD_FAILURE,
  OWNER_EDIT_START,
  OWNER_EDIT_CANCEL,
  OWNER_SAVE_SUCCESS,
  OWNER_CREATE_SUCCESS,
  OWNER_DEL_REQUEST,
  OWNER_DEL_SUCCESS,
  OWNER_DEL_FAILURE,
} from '../actions/owner';


const initialState = {
  owners: [],
  loaded: false,
  editing: {},
  saveError: {},
  loading_owners: false,
};

function initializeState(){
  return Object.assign({}, initialState);
}

export default function owner(state = initializeState(), action = {}) {
  switch (action.type) {
    case OWNERS_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        loading_owners: true,
      };
    case OWNERS_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        owners: action.owners,
        error: null,
        loading_owners: false,
      };
    case OWNERS_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        owners: null,
        error: action.error,
        loading_owners: false,
      };
    case OWNER_EDIT_START:
      return {
        ...state,
        editing: {
          ...state.editing,
          [action.id]: true
        }
      };
    case OWNER_EDIT_CANCEL:
      return {
        ...state,
        editing: {
          ...state.editing,
          [action.id]: false
        }
      };

    case OWNER_SAVE_SUCCESS:
      let owners = [...state.owners];
      let ownerData = [];
      for (const [index, owner] of owners.entries()) {
        if (owner.id === action.id) {
          ownerData = [
            ...owners.slice(0, index),
            action.owner,
            ...owners.slice(index + 1)
          ];
        }
      }
      return {
        ...state,
        owners: ownerData,
        loading_owners: false,
        editing: {
          ...state.editing,
          [action.id]: false
        },
      };
    case OWNER_CREATE_SUCCESS:
      owners = [...state.owners];
      return {
        ...state,
        owners: [...owners, action.owner],
        loading_owners: false,
        editing: {
          ...state.editing,
          [action.id]: false
        },
      };

    case OWNER_DEL_REQUEST:
      return {
        ...state,
        deleting: true,
        loading_owners: true,
      };
    case OWNER_DEL_SUCCESS:
      owners = [...state.owners];
      for (const [index, owner] of owners.entries()) {
        if (owner.id === action.owner.id) {
          owners.splice( index, 1 );
        }
      }
      return {
        ...state,
        deleting: false,
        deleted: true,
        owners: owners,
        error: null,
        loading_owners: false,
      };
    case OWNER_DEL_FAILURE:
      return {
        ...state,
        deleting: false,
        deleted: false,
        error: action.error,
        loading_owners: false,
      };

    case 'OWNER_SAVE_REQUEST':
      return {
        ...state,
        loading_owners: true,
      }
    default:
      return state;
  }


}
