import {
  UNITS_LOAD_REQUEST,
  UNITS_LOAD_SUCCESS,
  UNITS_LOAD_FAILURE,
  UNIT_LOAD_REQUEST,
  UNIT_LOAD_SUCCESS,
  UNIT_LOAD_FAILURE,
  UNIT_EDIT_START,
  UNIT_EDIT_CANCEL,
  UNIT_SAVE_SUCCESS,
  UNIT_CREATE_SUCCESS,
  UNIT_DEL_REQUEST,
  UNIT_DEL_SUCCESS,
  UNIT_DEL_FAILURE,
  UNIT_HISTORY_LOAD_REQUEST,
  UNIT_HISTORY_LOAD_SUCCESS,
  UNIT_HISTORY_LOAD_FAILURE,
} from '../actions/unit';

const initialState = {
  units: [],
  unit: {block:{}, building:{}, compound:{}},
  loaded: false,
  editing: false,
  saveError: {},
  loading_units: false,
  loading_unit: false
};

function initializeState(){
  return Object.assign({}, initialState);
}

export default function unit(state = initializeState(), action = {}) {
  switch (action.type) {
    case UNITS_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        loading_units: true,
      };
    case UNITS_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        units: action.units,
        count: action.count,
        error: null,
        loading_units: false,
      };
    case UNITS_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        units: null,
        error: action.error,
        loading_units: false,
      };
    case UNIT_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        loading_unit: true,
      };
    case UNIT_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        unit: action.unit,
        error: null,
        loading_unit: false,
      };
    case UNIT_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        unit: {},
        error: action.error,
        loading_unit: false,
      };
    case UNIT_EDIT_START:
      return {
        ...state,
        editing:  {
          id: action.id,
          action_type: action.action_type,
        },
        loading_unit: false,
      };
    case UNIT_EDIT_CANCEL:
      return {
        ...state,
        editing: false
      };

    case UNIT_SAVE_SUCCESS:
      let units = [...state.units];
      let unitData = [];
      for (const [index, unit] of units.entries()) {
        if (unit.id === action.id) {
          unitData = [
            ...units.slice(0, index),
            action.unit,
            ...units.slice(index + 1)
          ];
        }
      }
      return {
        ...state,
        units: unitData,
        editing: false,
        loading_unit: false,
      };
    case UNIT_CREATE_SUCCESS:
      units = [...state.units];
      return {
        ...state,
        units: [action.unit, ...units],
        count: state.count+1,
        editing: false,
      };

    case UNIT_DEL_REQUEST:
      return {
        ...state,
        deleting: true
      };
    case UNIT_DEL_SUCCESS:
      units = [...state.units];
      for (const [index, unit] of units.entries()) {
        if (unit.id === action.unit.id) {
          units.splice( index, 1 );
        }
      }
      return {
        ...state,
        units: units,
        deleting: false,
        deleted: true,
        editing: false,
        error: null
      };
    case UNIT_DEL_FAILURE:
      return {
        ...state,
        deleting: false,
        deleted: false,
        error: action.error
      };
    case UNIT_HISTORY_LOAD_REQUEST:
      return {
        ...state,
        history_loading: true
      };
    case UNIT_HISTORY_LOAD_SUCCESS:
      return {
        ...state,
        history_loading: false,
        history_loaded: true,
        history: action.history,
        history_error: null
      };
    case UNIT_HISTORY_LOAD_FAILURE:
      return {
        ...state,
        history_loading: false,
        history_loaded: false,
        history: [],
        history_error: action.error
      };

    case 'UNIT_SAVE_REQUEST':
      return {
        ...state,
        loading_unit: true,
      }

    case 'UNIT_SAVE_FAILURE':
    case 'UNIT_CREATE_SUCCESS':
      return {
        ...state,
        loading_unit: false,
      }

    default:
      return state;
  }
}
