const DEFAULT_STATE = {
  status: 0,
}

export default function postStatus(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case 'SET_MEDIA_STATUS':
      return { ...state, status: action.payload }

    default:
      return state
  }
}
