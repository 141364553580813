import { callApi } from '../utils/apiUtils';

export const DISCOUNTS_LOAD_REQUEST = 'DISCOUNTS_LOAD_REQUEST';
export const DISCOUNTS_LOAD_SUCCESS = 'DISCOUNTS_LOAD_SUCCESS';
export const DISCOUNTS_LOAD_FAILURE  = 'DISCOUNTS_LOAD_FAILURE';
export const DISCOUNT_EDIT_START = 'DISCOUNT_EDIT_START';
export const DISCOUNT_EDIT_CANCEL = 'DISCOUNT_EDIT_CANCEL';
export const DISCOUNT_SAVE_REQUEST = 'DISCOUNT_SAVE_REQUEST';
export const DISCOUNT_SAVE_SUCCESS = 'DISCOUNT_SAVE_SUCCESS';
export const DISCOUNT_SAVE_FAILURE = 'DISCOUNT_SAVE_FAILURE';
export const DISCOUNT_CREATE_SUCCESS = 'DISCOUNT_CREATE_SUCCESS';
export const DISCOUNT_DEL_REQUEST = 'DISCOUNT_DEL_REQUEST';
export const DISCOUNT_DEL_SUCCESS = 'DISCOUNT_DEL_SUCCESS';
export const DISCOUNT_DEL_FAILURE = 'DISCOUNT_DEL_FAILURE';

function discountsLoadRequest() {
  return {
    type: DISCOUNTS_LOAD_REQUEST,
  };
}

function discountsLoadSuccess(response) {
  const discounts = response.data.discounts;
  return {
    type: DISCOUNTS_LOAD_SUCCESS,
    discounts: discounts,
  };
}

function discountsLoadFailure(error) {
  return {
    type: DISCOUNTS_LOAD_FAILURE,
    error: error,
  };
}

function discountSaveRequest() {
  return {
    type: DISCOUNT_SAVE_REQUEST,
  };
}

function discountSaveSuccess(response) {
  const discount = response.data.discount;
  return {
    type: DISCOUNT_SAVE_SUCCESS,
    discount: discount,
    id: discount.id,
  };
}

function discountCreateSuccess(response) {
  const discount = response.data.discount;
  return {
    type: DISCOUNT_CREATE_SUCCESS,
    discount: discount,
    id: 'New'
  };
} 

function discountSaveFailure(error) {
  return {
    type: DISCOUNT_SAVE_FAILURE,
    error: error,
  };
}

function discountDelRequest() {
  return {
    type: DISCOUNT_DEL_REQUEST,
  };
}

function discountDelSuccess(response) {
  const discount = response.data.discount;
  return {
    type: DISCOUNT_DEL_SUCCESS,
    discount: discount,
  };
}

function discountDelFailure(error) {
  return {
    type: DISCOUNT_DEL_FAILURE,
    error: error,
  };
}

export function discountEdit(id) {
  return {
    type: DISCOUNT_EDIT_START,
    id: id,
  };
}

export function discountEditCancel(id) {
  return {
    type: DISCOUNT_EDIT_CANCEL,
    id: id,
  };
}

export function discountSave(data) {
  const id = data.id || '';
  if(id===''){
    const config = {
      method: 'post', 
      body: JSON.stringify({discount: data}),
    };
    return callApi('/discounts/', {}, config, discountSaveRequest, discountCreateSuccess, discountSaveFailure);
  } else {
    const config = {
      method: 'put', 
      body: JSON.stringify({discount: data}),
    };
    return callApi('/discounts/'+id, {}, config, discountSaveRequest, discountSaveSuccess, discountSaveFailure);
  }
}

export function discountDel(id) {
  const config = {
    method: 'delete',
  };
  return callApi('/discounts/'+id, {}, config, discountDelRequest, discountDelSuccess, discountDelFailure);
}

export function discountsLoad() {
  const config = {
    method: 'get',    
  };
  return callApi('/discounts', {}, config, discountsLoadRequest, discountsLoadSuccess, discountsLoadFailure);
}

