import { callApi, sendRequest } from '../utils/apiUtils';

export const TENANTS_LOAD_REQUEST = 'TENANTS_LOAD_REQUEST';
export const TENANTS_LOAD_SUCCESS = 'TENANTS_LOAD_SUCCESS';
export const TENANTS_LOAD_FAILURE  = 'TENANTS_LOAD_FAILURE';

export const TENANT_LOAD_REQUEST = 'TENANT_LOAD_REQUEST';
export const TENANT_LOAD_SUCCESS = 'TENANT_LOAD_SUCCESS';
export const TENANT_LOAD_FAILURE  = 'TENANT_LOAD_FAILURE';

export const TENANT_EDIT_START = 'TENANT_EDIT_START';
export const TENANT_EDIT_CANCEL = 'TENANT_EDIT_CANCEL';
export const TENANT_SAVE_REQUEST = 'TENANT_SAVE_REQUEST';
export const TENANT_SAVE_SUCCESS = 'TENANT_SAVE_SUCCESS';
export const TENANT_SAVE_FAILURE = 'TENANT_SAVE_FAILURE';
export const TENANT_CREATE_SUCCESS = 'TENANT_CREATE_SUCCESS';
export const TENANT_DEL_REQUEST = 'TENANT_DEL_REQUEST';
export const TENANT_DEL_SUCCESS = 'TENANT_DEL_SUCCESS';
export const TENANT_DEL_FAILURE = 'TENANT_DEL_FAILURE';

export const TENANT_HISTORY_LOAD_REQUEST = 'TENANT_HISTORY_LOAD_REQUEST';
export const TENANT_HISTORY_LOAD_SUCCESS = 'TENANT_HISTORY_LOAD_SUCCESS';
export const TENANT_HISTORY_LOAD_FAILURE  = 'TENANT_HISTORY_LOAD_FAILURE';

function tenantsLoadRequest() {
  return {
    type: TENANTS_LOAD_REQUEST,
  };
}

function tenantsLoadSuccess(response) {
  const data = response.data;
  return {
    type: TENANTS_LOAD_SUCCESS,
    data,
  };
}

function tenantsLoadFailure(error) {
  return {
    type: TENANTS_LOAD_FAILURE,
    error: error,
  };
}

function tenantLoadRequest() {
  return {
    type: TENANT_LOAD_REQUEST,
  };
}

function tenantLoadSuccess(response) {
  const tenant = response.data.tenant;
  return {
    type: TENANT_LOAD_SUCCESS,
    tenant: tenant,
  };
}

function tenantLoadFailure(error) {
  return {
    type: TENANT_LOAD_FAILURE,
    error: error,
  };
}


function tenantSaveRequest() {
  return {
    type: TENANT_SAVE_REQUEST,
  };
}

function tenantSaveSuccess(response) {
  const tenant = response.data.tenant;
  return {
    type: TENANT_SAVE_SUCCESS,
    tenant: tenant,
    id: tenant.id,
  };
}

function tenantCreateSuccess(response) {
  const tenant = response.data.tenant;
  return {
    type: TENANT_CREATE_SUCCESS,
    tenant: tenant,
    id: 'New'
  };
}

function tenantSaveFailure(error) {
  return {
    type: TENANT_SAVE_FAILURE,
    error: error.error,
  };
}

function tenantDelRequest() {
  return {
    type: TENANT_DEL_REQUEST,
  };
}

function tenantDelSuccess(response) {
  const tenant = response.data.tenant;
  return {
    type: TENANT_DEL_SUCCESS,
    tenant: tenant,
  };
}

function tenantDelFailure(error) {
  return {
    type: TENANT_DEL_FAILURE,
    error: error,
  };
}

function tenantHistoryLoadRequest() {
  return {
    type: TENANT_HISTORY_LOAD_REQUEST,
  };
}

function tenantHistoryLoadSuccess(response) {
  const tenant_history = response.data.tenant_history;
  return {
    type: TENANT_HISTORY_LOAD_SUCCESS,
    history: tenant_history,
  };
}

function tenantHistoryLoadFailure(error) {
  return {
    type: TENANT_HISTORY_LOAD_FAILURE,
    error: error,
  };
}

export function tenantEdit(values) {
  return {
    type: TENANT_EDIT_START,
    id: values.id,
    action_type: values.action_type,
  };
}

export function tenantEditCancel() {
  return {
    type: TENANT_EDIT_CANCEL,
  };
}

export function tenantSave(data) {
  const id = data.id || '';
  if(id===''){
    const config = {
      method: 'post',
      body: JSON.stringify({tenant: data}),
    };
    return callApi('/tenants/', {}, config, tenantSaveRequest, tenantCreateSuccess, tenantSaveFailure);
  } else {
    const config = {
      method: 'put',
      body: JSON.stringify({tenant: data}),
    };
    return callApi('/tenants/'+id, {}, config, tenantSaveRequest, tenantSaveSuccess, tenantSaveFailure);
  }
}

export function tenantDel(id) {
  const config = {
    method: 'delete',
  };
  return callApi('/tenants/'+id, {}, config, tenantDelRequest, tenantDelSuccess, tenantDelFailure);
}

export function tenantsLoad(params) {
  const config = {
    method: 'get',
  };
  return callApi('/tenants', params, config, tenantsLoadRequest, tenantsLoadSuccess, tenantsLoadFailure);
}

export function tenantLoad(id) {
  const config = {
    method: 'get',
  };
  return callApi('/tenants/'+id, {}, config, tenantLoadRequest, tenantLoadSuccess, tenantLoadFailure);
}


export function tenantHistoryLoad(tenantId) {
  const config = {
    method: 'get',
  };
  return callApi('/tenants/'+tenantId+'/histories', {}, config, tenantHistoryLoadRequest, tenantHistoryLoadSuccess, tenantHistoryLoadFailure);
}

export function resendPassword(tenant_id) {
  const actionType =  'RESEND_TENANT_PASSWORD'
  const URL        =  `/tenants/${tenant_id}/passwords/reset`
  const config = {
    method: 'PUT',
  }

  return sendRequest(
    URL,
    config,
    null,
    actionType,
  )
}
