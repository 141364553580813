const actionBase = 'DASHBOARD_LEADS'
const LEADS_STATE = {
  leads: null,
  errMsg: null,
  loading: false,
}

export function leader(action){

  switch (action.type) {

    // On Pending
    case `${actionBase}_LOAD_REQUEST`:
      return {...LEADS_STATE, loading: true}

    // On success request
    case `${actionBase}_LOAD_SUCCESS`:
      return {
        ...LEADS_STATE,
        leads: action.payload.leads,
        loading: false
      }

    // On failed request
    case `${actionBase}_LOAD_FAILURE`:
      return {
        ...LEADS_STATE,
        errMsg: action.payload,
        loading: false
      }

    default:
      return LEADS_STATE

  }

}
