import {
  NOTIFICATIONS_LOAD_REQUEST,
  NOTIFICATIONS_LOAD_SUCCESS,
  NOTIFICATIONS_LOAD_FAILURE,
  NOTIFICATIONS_RECEIVED_SUCCESS,
  NOTIFICATIONS_RESET,
} from '../actions/notification';

const initialState = {
  notifications: [],
  unread_count: 0,
  is_last_page: false,
  loaded: false,
};

function initializeState(){
  return Object.assign({}, initialState);
}

export default function notification(state = initializeState(), action = {}) {
  switch (action.type) {
    case NOTIFICATIONS_LOAD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case NOTIFICATIONS_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        notifications: [...state.notifications, ...action.notifications],
        unread_count: action.unread_count,
        is_last_page: action.is_last_page,
        count: action.count,
        error: null
      };
    case NOTIFICATIONS_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        notifications: null,
        error: action.error
      };
    case NOTIFICATIONS_RECEIVED_SUCCESS:
      return {
        ...state,
        notifications: [...action.notification, ...state.notifications],
        unread_count: state.unread_count+1,
        error: null
      };
    case NOTIFICATIONS_RESET:
      return {
        ...state,
        notifications: [],
        error: null
      };
    default:
      return state;
  }
}
