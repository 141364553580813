import {
  COMPOUNDS_LOAD_REQUEST,
  COMPOUNDS_LOAD_SUCCESS,
  COMPOUNDS_LOAD_FAILURE,
  COMPOUND_LOAD_REQUEST,
  COMPOUND_LOAD_SUCCESS,
  COMPOUND_LOAD_FAILURE,
  COMPOUND_EDIT_START,
  COMPOUND_EDIT_CANCEL,
  COMPOUND_SAVE_REQUEST,
  COMPOUND_SAVE_SUCCESS,
  COMPOUND_SAVE_FAILURE,
  COMPOUND_CREATE_SUCCESS,
  COMPOUND_DEL_REQUEST,
  COMPOUND_DEL_SUCCESS,
  COMPOUND_DEL_FAILURE,
  COMPOUND_HISTORY_LOAD_REQUEST,
  COMPOUND_HISTORY_LOAD_SUCCESS,
  COMPOUND_HISTORY_LOAD_FAILURE,
} from '../actions/compound';

const initialState = {
  compounds: [],
  compound: {owner:{}},
  loaded: false,
  editing: false,
  loading_compounds: false,
  loading_compound: false

};

function initializeState(){
  return Object.assign({}, initialState);
}

export default function compound(state = initializeState(), action = {}) {
  switch (action.type) {
    case COMPOUNDS_LOAD_REQUEST:
      return {
        ...state,
        loading_compounds: true,
        loading: true
      };
    case COMPOUNDS_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        compounds: action.compounds,
        error: null,
        loading_compounds: false
      };
    case COMPOUNDS_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        compounds: null,
        error: action.error,
        loading_compounds: false
      };
    case COMPOUND_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        loading_compound: true,
      };
    case COMPOUND_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        compound: action.compound,
        error: null,
        loading_compound: false,
      };
    case COMPOUND_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        compound: {owner:{}},
        error: action.error,
        loading_compound: false,
      };
    case COMPOUND_EDIT_START:
      return {
        ...state,
        editing:  {
          id: action.id,
          action_type: action.action_type,
        }
      };
    case COMPOUND_EDIT_CANCEL:
      return {
        ...state,
        compound: {owner:{}},
        editing: false
      };

    case COMPOUND_SAVE_REQUEST:
      return {
        ...state,
        saving: true,
        loading_compound: true,
      };

    case COMPOUND_SAVE_SUCCESS:
      let compounds = [...state.compounds];
      let compoundData = [];
      for (const [index, compound] of compounds.entries()) {
        if (compound.id === action.id) {
          compoundData = [
            ...compounds.slice(0, index),
            action.compound,
            ...compounds.slice(index + 1)
          ];
        }
      }
      return {
        ...state,
        compounds: compoundData,
        compound: {owner:{}},
        editing: false,
        saving: false,
        loading_compound: false,
      };
    case COMPOUND_CREATE_SUCCESS:
      compounds = [...state.compounds];
      return {
        ...state,
        compounds: [action.compound, ...compounds],
        compound: {owner:{}},
        editing: false,
        saving: false,
      };

    case COMPOUND_SAVE_FAILURE:
      return {
        ...state,
        error: action.error,
        saving: false,
        loading_compound: false
      };

    case COMPOUND_DEL_REQUEST:
      return {
        ...state,
        deleting: true
      };
    case COMPOUND_DEL_SUCCESS:
      compounds = [...state.compounds];
      for (const [index, compound] of compounds.entries()) {
        if (compound.id === action.compound.id) {
          compounds.splice( index, 1 );
        }
      }
      return {
        ...state,
        compounds: compounds,
        deleting: false,
        deleted: true,
        editing: false,
        error: null
      };

    case COMPOUND_DEL_FAILURE:
      return {
        ...state,
        deleting: false,
        deleted: false,
        error: action.error
      };
    case COMPOUND_HISTORY_LOAD_REQUEST:
      return {
        ...state,
        history_loading: true
      };
    case COMPOUND_HISTORY_LOAD_SUCCESS:
      return {
        ...state,
        history_loading: false,
        history_loaded: true,
        history: action.history,
        history_error: null
      };
    case COMPOUND_HISTORY_LOAD_FAILURE:
      return {
        ...state,
        history_loading: false,
        history_loaded: false,
        history: [],
        history_error: action.error
      };

    case 'BLOCK_SAVE_REQUEST':
    case 'BUILDING_SAVE_REQUEST':
      return {
        ...state,
        loading_compound: true,
      }

    case 'BLOCK_CREATE_SUCCESS':
    case 'BLOCK_SAVE_SUCCESS':
    case 'BUILDING_CREATE_SUCCESS':
    case 'BUILDING_SAVE_SUCCESS':
      return {
        ...state,
        loading_compound: false,
      }

    default:
      return state;
  }
}
