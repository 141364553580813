import { callApi } from '../utils/apiUtils';

export const LEASE_NOTES_LOAD_REQUEST = 'LEASE_NOTES_LOAD_REQUEST';
export const LEASE_NOTES_LOAD_SUCCESS = 'LEASE_NOTES_LOAD_SUCCESS';
export const LEASE_NOTES_LOAD_FAILURE  = 'LEASE_NOTES_LOAD_FAILURE';

export const LEASE_NOTE_EDIT_START = 'LEASE_NOTE_EDIT_START';
export const LEASE_NOTE_EDIT_CANCEL = 'LEASE_NOTE_EDIT_CANCEL';
export const LEASE_NOTE_SAVE_REQUEST = 'LEASE_NOTE_SAVE_REQUEST';
export const LEASE_NOTE_SAVE_FAILURE = 'LEASE_NOTE_SAVE_FAILURE';
export const LEASE_NOTE_CREATE_SUCCESS = 'LEASE_NOTE_CREATE_SUCCESS';


function leaseNotesLoadRequest() {
  return {
    type: LEASE_NOTES_LOAD_REQUEST,
  };
}

function leaseNotesLoadSuccess(response) {
  const leaseNotes = response.data.notes;
  return {
    type: LEASE_NOTES_LOAD_SUCCESS,
    notes: leaseNotes,
  };
}

function leaseNotesLoadFailure(error) {
  return {
    type: LEASE_NOTES_LOAD_FAILURE,
    error: error,
  };
}

function leaseNoteSaveRequest() {
  return {
    type: LEASE_NOTE_SAVE_REQUEST,
  };
}

function leaseNoteCreateSuccess(response) {
  const leaseNote = response.data.note;
  return {
    type: LEASE_NOTE_CREATE_SUCCESS,
    note: leaseNote,
    id: 'New'
  };
} 

function leaseNoteSaveFailure(error) {
  return {
    type: LEASE_NOTE_SAVE_FAILURE,
    error: error,
  };
}

export function leaseNoteEdit(values) {
  return {
    type: LEASE_NOTE_EDIT_START,
  };
}

export function leaseNoteEditCancel() {
  return {
    type: LEASE_NOTE_EDIT_CANCEL,
  };
}

export function leaseNoteSave(tenantId, id, data) {
  const config = {
    method: 'post', 
    body: JSON.stringify({note: data}),
  };
  return callApi('/tenants/'+tenantId+'/leases/'+id+'/notes/', {}, config, leaseNoteSaveRequest, leaseNoteCreateSuccess, leaseNoteSaveFailure);
}

export function leaseNotesLoad(tenantId, id) {
  const config = {
    method: 'get',    
  };
  return callApi('/tenants/'+tenantId+'/leases/'+id+'/notes/', {}, config, leaseNotesLoadRequest, leaseNotesLoadSuccess, leaseNotesLoadFailure);
}