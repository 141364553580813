import { callApi } from '../utils/apiUtils';

export const LEAD_ACTIVITIES_LOAD_REQUEST = 'LEAD_ACTIVITIES_LOAD_REQUEST';
export const LEAD_ACTIVITIES_LOAD_SUCCESS = 'LEAD_ACTIVITIES_LOAD_SUCCESS';
export const LEAD_ACTIVITIES_LOAD_FAILURE  = 'LEAD_ACTIVITIES_LOAD_FAILURE';

export const LEAD_ACTIVITY_EDIT_START = 'LEAD_ACTIVITY_EDIT_START';
export const LEAD_ACTIVITY_EDIT_CANCEL = 'LEAD_ACTIVITY_EDIT_CANCEL';
export const LEAD_ACTIVITY_SAVE_REQUEST = 'LEAD_ACTIVITY_SAVE_REQUEST';
export const LEAD_ACTIVITY_SAVE_FAILURE = 'LEAD_ACTIVITY_SAVE_FAILURE';
export const LEAD_ACTIVITY_CREATE_SUCCESS = 'LEAD_ACTIVITY_CREATE_SUCCESS';


function leadActivitiesLoadRequest() {
  return {
    type: LEAD_ACTIVITIES_LOAD_REQUEST,
  };
}

function leadActivitiesLoadSuccess(response) {
  const leadActivities = response.data.activities;
  return {
    type: LEAD_ACTIVITIES_LOAD_SUCCESS,
    activities: leadActivities,
  };
}

function leadActivitiesLoadFailure(error) {
  return {
    type: LEAD_ACTIVITIES_LOAD_FAILURE,
    error: error,
  };
}

function leadActivitySaveRequest() {
  return {
    type: LEAD_ACTIVITY_SAVE_REQUEST,
  };
}

function leadActivityCreateSuccess(response) {
  const leadActivity = response.data.activity;
  return {
    type: LEAD_ACTIVITY_CREATE_SUCCESS,
    activity: leadActivity,
    id: 'New'
  };
} 

function leadActivitySaveFailure(error) {
  return {
    type: LEAD_ACTIVITY_SAVE_FAILURE,
    error: error,
  };
}

export function leadActivityEdit() {
  return {
    type: LEAD_ACTIVITY_EDIT_START,
  };
}

export function leadActivityEditCancel() {
  return {
    type: LEAD_ACTIVITY_EDIT_CANCEL,
  };
}

export function leadActivitySave(leadId, data) {
  const config = {
    method: 'post', 
    body: JSON.stringify({activity: data}),
  };
  return callApi('/leads/'+leadId+'/activities/', {}, config, leadActivitySaveRequest, leadActivityCreateSuccess, leadActivitySaveFailure);
}

export function leadActivitiesLoad(leadId) {
  const config = {
    method: 'get',    
  };
  return callApi('/leads/'+leadId+'/activities/', {}, config, leadActivitiesLoadRequest, leadActivitiesLoadSuccess, leadActivitiesLoadFailure);
}