import {
  LEASE_NOTES_LOAD_REQUEST,
  LEASE_NOTES_LOAD_SUCCESS,
  LEASE_NOTES_LOAD_FAILURE,
  LEASE_NOTE_EDIT_START,
  LEASE_NOTE_EDIT_CANCEL,
  LEASE_NOTE_CREATE_SUCCESS,
} from '../actions/leaseNote';

const initialState = {
  notes: [],
  loaded: false,
  editing: false,
};

function initializeState(){
  return Object.assign({}, initialState);
}

export default function leaseNotes(state = initializeState(), action = {}) {
  switch (action.type) {
    case LEASE_NOTES_LOAD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case LEASE_NOTES_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        notes: action.notes,
        error: null
      };
    case LEASE_NOTES_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        notes: null,
        error: action.error
      };

    case LEASE_NOTE_EDIT_START:
      return {
        ...state,
        editing: true
      };
    case LEASE_NOTE_EDIT_CANCEL:
      return {
        ...state,
        editing: false
      };

    case LEASE_NOTE_CREATE_SUCCESS:
      let notes = [...state.notes];
      return {
        ...state,
        notes: [action.note, ...notes],
        editing: false,
      };

    default:
      return state;
  }
}
