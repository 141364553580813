import { callApi } from '../utils/apiUtils';

export const OWNERS_LOAD_REQUEST = 'OWNERS_LOAD_REQUEST';
export const OWNERS_LOAD_SUCCESS = 'OWNERS_LOAD_SUCCESS';
export const OWNERS_LOAD_FAILURE  = 'OWNERS_LOAD_FAILURE';
export const OWNER_EDIT_START = 'OWNER_EDIT_START';
export const OWNER_EDIT_CANCEL = 'OWNER_EDIT_CANCEL';
export const OWNER_SAVE_REQUEST = 'OWNER_SAVE_REQUEST';
export const OWNER_SAVE_SUCCESS = 'OWNER_SAVE_SUCCESS';
export const OWNER_SAVE_FAILURE = 'OWNER_SAVE_FAILURE';
export const OWNER_CREATE_SUCCESS = 'OWNER_CREATE_SUCCESS';
export const OWNER_DEL_REQUEST = 'OWNER_DEL_REQUEST';
export const OWNER_DEL_SUCCESS = 'OWNER_DEL_SUCCESS';
export const OWNER_DEL_FAILURE = 'OWNER_DEL_FAILURE';

function ownersLoadRequest() {
  return {
    type: OWNERS_LOAD_REQUEST,
  };
}

function ownersLoadSuccess(response) {
  const owners = response.data.owners;
  return {
    type: OWNERS_LOAD_SUCCESS,
    owners: owners,
  };
}

function ownersLoadFailure(error) {
  return {
    type: OWNERS_LOAD_FAILURE,
    error: error,
  };
}

function ownerSaveRequest() {
  return {
    type: OWNER_SAVE_REQUEST,
  };
}

function ownerSaveSuccess(response) {
  const owner = response.data.owner;
  return {
    type: OWNER_SAVE_SUCCESS,
    owner: owner,
    id: owner.id,
  };
}

function ownerCreateSuccess(response) {
  const owner = response.data.owner;
  return {
    type: OWNER_CREATE_SUCCESS,
    owner: owner,
    id: 'New'
  };
} 

function ownerSaveFailure(error) {
  return {
    type: OWNER_SAVE_FAILURE,
    error: error,
  };
}

function ownerDelRequest() {
  return {
    type: OWNER_DEL_REQUEST,
  };
}

function ownerDelSuccess(response) {
  const owner = response.data.owner;
  return {
    type: OWNER_DEL_SUCCESS,
    owner: owner,
  };
}

function ownerDelFailure(error) {
  return {
    type: OWNER_DEL_FAILURE,
    error: error,
  };
}

export function ownerEdit(id) {
  return {
    type: OWNER_EDIT_START,
    id: id,
  };
}

export function ownerEditCancel(id) {
  return {
    type: OWNER_EDIT_CANCEL,
    id: id,
  };
}

export function ownerSave(data) {
  const id = data.id || '';
  if(id===''){
    const config = {
      method: 'post', 
      body: JSON.stringify({owner: data}),
    };
    return callApi('/owners/', {}, config, ownerSaveRequest, ownerCreateSuccess, ownerSaveFailure);
  } else {
    const config = {
      method: 'put', 
      body: JSON.stringify({owner: data}),
    };
    return callApi('/owners/'+id, {}, config, ownerSaveRequest, ownerSaveSuccess, ownerSaveFailure);
  }
}

export function ownerDel(id) {
  const config = {
    method: 'delete',
  };
  return callApi('/owners/'+id, {}, config, ownerDelRequest, ownerDelSuccess, ownerDelFailure);
}

export function ownersLoad() {
  const config = {
    method: 'get',    
  };
  return callApi('/owners', {}, config, ownersLoadRequest, ownersLoadSuccess, ownersLoadFailure);
}

