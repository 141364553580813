import {
  CITIES_LOAD_REQUEST,
  CITIES_LOAD_SUCCESS,
  CITIES_LOAD_FAILURE,
} from '../actions/city';

const initialState = {
  cities: [],
  loaded: false,
};

function initializeState(){
  return Object.assign({}, initialState);
}

export default function city(state = initializeState(), action = {}) {
  switch (action.type) {
    case CITIES_LOAD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case CITIES_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        cities: action.cities,
        error: null
      };
    case CITIES_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        cities: null,
        error: action.error
      };

    default:
      return state;
  }
}
