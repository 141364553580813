import {
  LEASES_LOAD_REQUEST,
  LEASES_LOAD_SUCCESS,
  LEASES_LOAD_FAILURE,
  LEASE_LOAD_REQUEST,
  LEASE_LOAD_SUCCESS,
  LEASE_LOAD_FAILURE,
  LEASE_EDIT_START,
  LEASE_EDIT_CANCEL,
  LEASE_SAVE_SUCCESS,
} from '../actions/lease';

const initialState = {
  leases: [],
  lease: {},
  loaded: false,
  editing: false,
  saveError: {},
  loading_leases: false,
  loading_lease: false,
};

function initializeState(){
  return Object.assign({}, initialState);
}

export default function lease(state = initializeState(), action = {}) {
  switch (action.type) {
    case LEASES_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        loading_leases: true,
      };
    case LEASES_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        leases: action.leases,
        count: action.count,
        error: null,
        loading_leases: false,
      };
    case LEASES_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        leases: null,
        error: action.error,
        loading_leases: false,
      };
    case LEASE_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        loading_lease: true,
      };
    case LEASE_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        lease: action.lease,
        error: null,
        loading_lease: false,
      };
    case LEASE_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        lease: {},
        error: action.error,
        loading_lease: false,
      };
    case LEASE_EDIT_START:
      return {
        ...state,
        editing:  {
          id: action.id,
          action_type: action.action_type,
        }
      };
    case LEASE_EDIT_CANCEL:
      return {
        ...state,
        editing: false
      };

    case LEASE_SAVE_SUCCESS:
      let leases = [...state.leases];
      let leaseData = [];
      for (const [index, lease] of leases.entries()) {
        if (lease.id === action.id) {
          leaseData = [
            ...leases.slice(0, index),
            action.lease,
            ...leases.slice(index + 1)
          ];
        }
      }
      return {
        ...state,
        leases: leaseData,
        lease: action.lease,
        loading_lease: false,
      };

    case 'LEASE_SAVE_REQUEST':
    case 'LEASE_OCCUPANT_SAVE_REQUEST':
    case 'LEASE_VEHICLE_SAVE_REQUEST':
      return {
        ...state,
        loading_lease: true,
      }

    case 'LEASE_SAVE_SUCCESS':
    case 'LEASE_SAVE_FAILURE':
    case 'LEASE_OCCUPANT_CREATE_SUCCESS':
    case 'LEASE_OCCUPANT_CREATE_FAILURE':
    case 'LEASE_VEHICLE_CREATE_SUCCESS':
    case 'LEASE_VEHICLE_CREATE_FAILURE':
    case 'LEASE_VEHICLE_SAVE_FAILURE':
    case 'LEASE_OCCUPANT_SAVE_SUCCESS':
      return {
        ...state,
        loading_lease: false,
      }

    default:
      return state;
  }
}
