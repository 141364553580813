import { callApi } from '../utils/apiUtils'

export const UNITS_LOAD_REQUEST = 'UNITS_LOAD_REQUEST'
export const UNITS_LOAD_SUCCESS = 'UNITS_LOAD_SUCCESS'
export const UNITS_LOAD_FAILURE = 'UNITS_LOAD_FAILURE'
export const UNIT_LOAD_REQUEST = 'UNIT_LOAD_REQUEST'
export const UNIT_LOAD_SUCCESS = 'UNIT_LOAD_SUCCESS'
export const UNIT_LOAD_FAILURE = 'UNIT_LOAD_FAILURE'

export const UNIT_EDIT_START = 'UNIT_EDIT_START'
export const UNIT_EDIT_CANCEL = 'UNIT_EDIT_CANCEL'
export const UNIT_SAVE_REQUEST = 'UNIT_SAVE_REQUEST'
export const UNIT_SAVE_SUCCESS = 'UNIT_SAVE_SUCCESS'
export const UNIT_SAVE_FAILURE = 'UNIT_SAVE_FAILURE'
export const UNIT_CREATE_SUCCESS = 'UNIT_CREATE_SUCCESS'
export const UNIT_DEL_REQUEST = 'UNIT_DEL_REQUEST'
export const UNIT_DEL_SUCCESS = 'UNIT_DEL_SUCCESS'
export const UNIT_DEL_FAILURE = 'UNIT_DEL_FAILURE'

export const UNIT_HISTORY_LOAD_REQUEST = 'UNIT_HISTORY_LOAD_REQUEST'
export const UNIT_HISTORY_LOAD_SUCCESS = 'UNIT_HISTORY_LOAD_SUCCESS'
export const UNIT_HISTORY_LOAD_FAILURE = 'UNIT_HISTORY_LOAD_FAILURE'

function unitsLoadRequest() {
  return {
    type: UNITS_LOAD_REQUEST,
  }
}

function unitsLoadSuccess(response) {
  const units = response.data.units
  const count = response.data.pagination && response.data.pagination.total_entries
  return {
    type: UNITS_LOAD_SUCCESS,
    units: units,
    count: count,
  }
}

function unitsLoadFailure(error) {
  return {
    type: UNITS_LOAD_FAILURE,
    error: error,
  }
}

function unitLoadRequest() {
  return {
    type: UNIT_LOAD_REQUEST,
  }
}

function unitLoadSuccess(response) {
  const unit = response.data.unit
  return {
    type: UNIT_LOAD_SUCCESS,
    unit: unit,
  }
}

function unitLoadFailure(error) {
  return {
    type: UNIT_LOAD_FAILURE,
    error: error,
  }
}

function unitSaveRequest() {
  return {
    type: UNIT_SAVE_REQUEST,
  }
}

function unitSaveSuccess(response) {
  const unit = response.data.unit
  return {
    type: UNIT_SAVE_SUCCESS,
    unit: unit,
    id: unit.id,
  }
}

function unitCreateSuccess(response) {
  const unit = response.data.unit
  return {
    type: UNIT_CREATE_SUCCESS,
    unit: unit,
    id: 'New',
  }
}

function unitSaveFailure(error) {
  return {
    type: UNIT_SAVE_FAILURE,
    error: error,
  }
}

function unitDelRequest() {
  return {
    type: UNIT_DEL_REQUEST,
  }
}

function unitDelSuccess(response) {
  const unit = response.data.unit
  return {
    type: UNIT_DEL_SUCCESS,
    unit: unit,
  }
}

function unitDelFailure(error) {
  return {
    type: UNIT_DEL_FAILURE,
    error: error,
  }
}

function unitHistoryLoadRequest() {
  return {
    type: UNIT_HISTORY_LOAD_REQUEST,
  }
}

function unitHistoryLoadSuccess(response) {
  const unit_history = response.data.unit_history
  return {
    type: UNIT_HISTORY_LOAD_SUCCESS,
    history: unit_history,
  }
}

function unitHistoryLoadFailure(error) {
  return {
    type: UNIT_HISTORY_LOAD_FAILURE,
    error: error,
  }
}

export function unitEdit(values) {
  return {
    type: UNIT_EDIT_START,
    id: values.id,
    action_type: values.action_type,
  }
}

export function unitEditCancel() {
  return {
    type: UNIT_EDIT_CANCEL,
  }
}

export function unitSave(data) {
  const id = data.id || ''
  if (id === '') {
    const config = {
      method: 'post',
      body: JSON.stringify({ unit: data }),
    }
    return callApi('/units/', {}, config, unitSaveRequest, unitCreateSuccess, unitSaveFailure)
  } else {
    const config = {
      method: 'put',
      body: JSON.stringify({ unit: data }),
    }
    return callApi('/units/' + id, {}, config, unitSaveRequest, unitSaveSuccess, unitSaveFailure)
  }
}

export function unitDel(id) {
  const config = {
    method: 'delete',
  }
  return callApi('/units/' + id, {}, config, unitDelRequest, unitDelSuccess, unitDelFailure)
}

export function unitsLoad(params) {
  const config = {
    method: 'get',
  }
  return callApi('/units', params, config, unitsLoadRequest, unitsLoadSuccess, unitsLoadFailure)
}

export function unitLoad(id) {
  const config = {
    method: 'get',
  }
  return callApi('/units/' + id, {}, config, unitLoadRequest, unitLoadSuccess, unitLoadFailure)
}

export function unitHistoryLoad(unitId) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/units/' + unitId + '/history',
    {},
    config,
    unitHistoryLoadRequest,
    unitHistoryLoadSuccess,
    unitHistoryLoadFailure,
  )
}
