import {
  PACKAGE_TYPES_LOAD_REQUEST,
  PACKAGE_TYPES_LOAD_SUCCESS,
  PACKAGE_TYPES_LOAD_FAILURE,
  PACKAGE_TYPE_EDIT_START,
  PACKAGE_TYPE_EDIT_CANCEL,
  PACKAGE_TYPE_SAVE_SUCCESS,
  PACKAGE_TYPE_CREATE_SUCCESS,
  PACKAGE_TYPE_DEL_REQUEST,
  PACKAGE_TYPE_DEL_SUCCESS,
  PACKAGE_TYPE_DEL_FAILURE,
} from '../actions/packageType';

const initialState = {
  packageTypes: [],
  loaded: false,
  editing: {},
  saveError: {},
  loading_packageTypes: false,
};

function initializeState(){
  return Object.assign({}, initialState);
}

export default function packageType(state = initializeState(), action = {}) {
  switch (action.type) {
    case PACKAGE_TYPES_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        loading_packageTypes: true,
      };
    case PACKAGE_TYPES_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        packageTypes: action.packageTypes,
        error: null,
        loading_packageTypes: false,
      };
    case PACKAGE_TYPES_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        packageTypes: null,
        error: action.error,
        loading_packageTypes: false,
      };
    case PACKAGE_TYPE_EDIT_START:
      return {
        ...state,
        editing: {
          ...state.editing,
          [action.id]: true
        }
      };
    case PACKAGE_TYPE_EDIT_CANCEL:
      return {
        ...state,
        editing: {
          ...state.editing,
          [action.id]: false
        }
      };

    case PACKAGE_TYPE_SAVE_SUCCESS:
      let packageTypes = [...state.packageTypes];
      let packageTypeData = [];
      for (const [index, packageType] of packageTypes.entries()) {
        if (packageType.id === action.id) {
          packageTypeData = [
            ...packageTypes.slice(0, index),
            action.packageType,
            ...packageTypes.slice(index + 1)
          ];
        }
      }
      return {
        ...state,
        packageTypes: packageTypeData,
        loading_packageTypes: false,
        editing: {
          ...state.editing,
          [action.id]: false
        },
      };
    case PACKAGE_TYPE_CREATE_SUCCESS:
      packageTypes = [...state.packageTypes];
      return {
        ...state,
        packageTypes: [...packageTypes, action.packageType],
        loading_packageTypes: false,
        editing: {
          ...state.editing,
          [action.id]: false
        },
      };

    case PACKAGE_TYPE_DEL_REQUEST:
      return {
        ...state,
        deleting: true,
        loading_packageTypes: true,
      };
    case PACKAGE_TYPE_DEL_SUCCESS:
      packageTypes = [...state.packageTypes];
      for (const [index, packageType] of packageTypes.entries()) {
        if (packageType.id === action.packageType.id) {
          packageTypes.splice( index, 1 );
        }
      }
      return {
        ...state,
        deleting: false,
        deleted: true,
        packageTypes: packageTypes,
        error: null,
        loading_packageTypes: false,
      };
    case PACKAGE_TYPE_DEL_FAILURE:
      return {
        ...state,
        deleting: false,
        deleted: false,
        error: action.error,
        loading_packageTypes: false,
      };

    case 'PACKAGE_TYPE_SAVE_REQUEST':
      return {
        ...state,
        loading_packageTypes: true,
      }

    case 'PACKAGE_TYPE_SAVE_FAILURE':
      return {
        ...state,
        loading_packageTypes: false,
      }


    default:
      return state;
  }
}
