const actionBase = 'DASHBOARD_MAINTENANCE'
const STATE = {
  maintenance: null,
  errMsg: null,
  loading: false,
}

export function maintenance(action) {
  switch (action.type) {
    // On Pending
    case `${actionBase}_LOAD_REQUEST`:
      return { ...STATE, loading: true }

    // On success request
    case `${actionBase}_LOAD_SUCCESS`:
      return {
        ...STATE,
        maintenance: action.payload.work_orders,
        loading: false,
      }

    // On failed request
    case `${actionBase}_LOAD_FAILURE`:
      return {
        ...STATE,
        errMsg: action.payload,
        loading: true,
      }

    default:
      return STATE
  }
}
