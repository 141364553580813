import Toastr from 'toastr'
import { getApiUrl, httpProtocol } from 'utils/apiUtils'

const config = {
  //host: 'http://api.dev.leasing.clicksandbox.com:8080/v1',
  host: getApiUrl(httpProtocol),
  headers: {
    Accept: 'application/json',
    'content-type': 'application/json',
    'cache-control': 'reload',
  },
  data_error: 'error',
  onFaild: err => Toastr.error(err),
  endpoints: [
    {
      url: 'maintenance/issues',
      method: 'get',
      id: 'issues',
      action: 'GET_MAINTENANCE_ISSUES',
      response: 'data.issues',
    },
    {
      url: 'maintenance/issues/:issue_id',
      method: 'get',
      id: 'issue',
      action: 'GET_MAINTENANCE_ISSUE',
      response: 'data.issue',
      onPending: 'reset',
    },
    {
      url: 'maintenance/issues',
      method: 'post',
      id: 'issues',
      action: 'CREATE_MAINTENANCE_ISSUE',
      response: 'data.issue',
    },
    {
      url: 'maintenance/issues/:issue_id/close_issue',
      method: 'put',
      id: 'close_issue',
      action: 'UPDATE_MAINTENANCE_CLOSE_ISSUE',
      response: 'data.issue',
    },
    {
      url: 'maintenance/issues/:issue_id',
      method: 'put',
      id: 'issue',
      action: 'UPDATE_MAINTENANCE_ISSUE',
      response: 'data.issue',
    },
    {
      url: 'maintenance/issues/:issue_id/cancel_issue',
      method: 'put',
      id: 'issue',
      action: 'CANCEL_MAINTENANCE_ISSUE',
      response: 'data.issue',
    },
    {
      url: 'maintenance/issues/:issue_id/comments',
      method: 'get',
      id: 'issue_comments',
      action: 'GET_ISSUE_COMMENTS',
      response: 'data',
    },
    {
      url: 'maintenance/issues/:issue_id/comments',
      method: 'post',
      id: 'issue_comment',
      action: 'CREATE_ISSUE_COMMENT',
      response: 'data.comment',
    },
    {
      url: 'maintenance/issues/:issue_id/comments/:comment_id',
      method: 'put',
      id: 'issue_comment',
      action: 'UPDATE_ISSUE_COMMENT',
      response: 'data.comment',
    },
    {
      url: 'provider/issues',
      method: 'get',
      id: 'issues',
      action: 'GET_PROVIDER_ISSUES',
      response: 'data.issues',
    },
    {
      url: 'provider/issues/:issue_id',
      method: 'get',
      id: 'issue',
      action: 'GET_PROVIDER_ISSUE',
      response: 'data.issue',
      onPending: 'reset',
    },
    {
      url: 'provider/issues/:issue_id',
      method: 'put',
      id: 'issue',
      action: 'UPDATE_PROVIDER_ISSUE',
      response: 'data.issue',
    },
    {
      url: 'provider/issues/:issue_id/assignee',
      method: 'put',
      id: 'issue',
      action: 'ASSIGN_ISSUE_TO_ME',
      response: 'data.issue',
    },
    {
      url: 'maintenance/providers',
      method: 'get',
      id: 'providers',
      action: 'GET_PROVIDERS',
      response: 'data.providers',
    },
    {
      url: 'maintenance/providers',
      method: 'get',
      id: 'providers_data',
      action: 'GET_MAINTENANCE_PROVIDERS',
      response: 'data',
    },
    {
      url: 'maintenance/providers',
      method: 'post',
      id: 'providers',
      action: 'CREATE_PROVIDER',
      response: 'data.providers',
    },
    {
      url: 'maintenance/providers/:provider_id',
      method: 'get',
      id: 'provider',
      action: 'GET_PROVIDER',
      response: 'data.provider',
    },
    {
      url: 'maintenance/providers/:provider_id',
      method: 'put',
      id: 'providers',
      action: 'UPDATE_PROVIDER',
      response: 'data.provider',
      onSuccess: 'update',
      updateBy: 'id',
    },
    {
      url: 'maintenance/categories',
      method: 'get',
      id: 'maintenance_categories',
      action: 'GET_MAINTENANCE_CATEGORIES',
      response: 'data.categories',
    },
    {
      url: 'maintenance/categories/:category_id',
      method: 'get',
      id: 'maintenance_category',
      action: 'GET_MAINTENANCE_CATEGORY',
      response: 'data.category',
    },
    {
      url: 'maintenance/categories',
      method: 'post',
      id: 'maintenance_categories',
      action: 'CREATE_MAINTENANCE_CATEGORY',
      response: 'data.categories',
    },
    {
      url: 'maintenance/categories/:category_id',
      method: 'put',
      id: 'maintenance_categories',
      action: 'UPDATE_MAINTENANCE_CATEGORY',
      response: 'data.categories',
    },
    {
      url: 'maintenance/types',
      method: 'get',
      id: 'maintenance_types',
      action: 'GET_MAINTENANCE_TYPES',
      response: 'data.maintenance_types',
    },
    {
      url: 'maintenance/types',
      method: 'post',
      id: 'maintenance_types',
      action: 'CREATE_MAINTENANCE_TYPE',
      response: 'data.maintenance_type',
      onSuccess: 'push',
    },
    {
      url: 'maintenance/types/:type_id',
      method: 'get',
      id: 'maintenance_type',
      action: 'GET_MAINTENANCE_TYPE',
      response: 'data.maintenance_type',
    },
    {
      url: 'maintenance/types/:type_id',
      method: 'put',
      id: 'maintenance_type',
      action: 'UPDATE_MAINTENANCE_TYPE',
      response: 'data.maintenance_type',
    },
    {
      url: 'maintenance/categories/:cat_id/subcategories',
      method: 'get',
      id: 'maintenance_subcategories',
      action: 'GET_MAINTENANCE_SUB_CATEGORIES',
      response: 'data.subcategories',
    },
    {
      url: 'maintenance/categories/:cat_id/subcategories/:category_id',
      method: 'get',
      id: 'maintenance_subcategory',
      action: 'GET_MAINTENANCE_SUBCATEGORIY',
      response: 'data.subcategory',
    },
    {
      url: 'maintenance/categories/:cat_id/subcategories/:category_id',
      method: 'put',
      id: 'maintenance_subcategory',
      action: 'UPDATE_MAINTENANCE_SUBCATEGORY',
      response: 'data.subcategory',
    },
    {
      url: 'maintenance/categories/:cat_id/subcategories',
      method: 'post',
      id: 'maintenance_subcategories',
      action: 'CREATE_MAINTENANCE_SUBCATEGORY',
      response: 'data.subcategories',
    },
    {
      url: 'maintenance/categories/:cat_id/subcategories/:category_id',
      method: 'delete',
      id: 'maintenance_subcategories',
      action: 'DELETE_MAINTENANCE_SUBCATEGORY',
      response: 'data.subcategories',
    },
    {
      url:
        'maintenance/categories/:cat_id/subcategories/:sub_id/subsubcategories',
      method: 'get',
      id: 'maintenance_subsubcategories',
      action: 'GET_MAINTENANCE_SUB_SUB_CATEGORIES',
      response: 'data.subsubcategories',
    },
    {
      url:
        'maintenance/categories/:cat_id/subcategories/:sub_id/subsubcategories/:issue_id',
      method: 'get',
      id: 'maintenance_subsubcategory',
      action: 'GET_MAINTENANCE_SUBSUBCATEGORY',
      response: 'data.subsubcategory',
    },
    {
      url:
        'maintenance/categories/:cat_id/subcategories/:sub_id/subsubcategories/:issue_id',
      method: 'put',
      id: 'maintenance_subsubcategory',
      action: 'UPDATE_MAINTENANCE_SUBSUBCATEGORY',
      response: 'data.subsubcategory',
    },
    {
      url:
        'maintenance/categories/:cat_id/subcategories/:sub_id/subsubcategories',
      method: 'post',
      id: 'maintenance_subsubcategories',
      action: 'CREATE_MAINTENANCE_SUBSUBCATEGORY',
      response: 'data.subsubcategories',
    },
    {
      url: 'maintenance/status_details',
      method: 'get',
      id: 'statuses_details',
      action: 'GET_MAINTENANCE_STATUSES_DETAILS',
      response: 'data.status_details',
    },
    {
      url: 'maintenance/status_details',
      method: 'post',
      id: 'statuses_details',
      action: 'CREATE_MAINTENANCE_STATUS_DETAILS',
      response: 'data.status_details',
    },
    {
      url: 'maintenance/status_details/:status_id',
      method: 'get',
      id: 'status_details',
      action: 'GET_MAINTENANCE_STATUS_DETAILS',
      response: 'data.status_details',
    },
    {
      url: 'maintenance/status_details/:status_id',
      method: 'put',
      id: 'status_details',
      action: 'UPDATE_MAINTENANCE_STATUS_DETAILS',
      response: 'data.status_details',
    },
    {
      url: 'unit_status_details',
      method: 'get',
      id: 'unit_statuses_details',
      action: 'GET_UNIT_STATUSES_DETAILS',
      response: 'data',
    },
    {
      url: 'unit_status_details',
      method: 'post',
      id: 'unit_status_details',
      action: 'CREATE_UNIT_STATUS_DETAILS',
      response: 'data.unit_status_detail',
    },
    {
      url: 'unit_status_details/:id',
      method: 'get',
      id: 'unit_status_details',
      action: 'GET_UNIT_STATUS_DETAILS',
      response: 'data.unit_status_detail',
    },
    {
      url: 'unit_status_details/:id',
      method: 'put',
      id: 'unit_status_details',
      action: 'UPDATE_UNIT_STATUS_DETAILS',
      response: 'data.unit_status_detail',
    },
    {
      url: 'unit_status_details/:id',
      method: 'delete',
      id: 'unit_status_details',
      action: 'DELETE_UNIT_STATUS_DETAILS',
      response: 'data.unit_status_detail',
    },
    {
      url: 'compounds',
      method: 'get',
      id: 'compounds',
      action: 'GET_COMPOUNDS',
      response: 'data.compounds',
    },
    {
      url: 'compounds/brief',
      method: 'get',
      id: 'compounds',
      action: 'GET_COMPOUNDS_BRIEF',
      response: 'data.compounds',
    },
    {
      url: 'compounds',
      method: 'post',
      id: 'compound',
      action: 'CREATE_COMPOUND',
      response: 'data.compounds',
    },
    {
      url: 'compounds/:compound_id',
      method: 'get',
      id: 'compound',
      action: 'GET_COMPOUND',
      response: 'data.compound',
    },
    {
      url: 'compounds/:compound_id',
      method: 'put',
      id: 'compounds',
      action: 'UPDATE_COMPOUND',
      response: 'data.compound',
      onSuccess: 'update',
      updateBy: 'id',
    },
    {
      url: 'compounds/:compound_id/documents',
      method: 'get',
      id: 'compound_documents',
      action: 'GET_COMPOUND_DOCUMENTS',
      response: 'data.documents',
    },
    {
      url: 'compounds/:compound_id/documents',
      method: 'post',
      id: 'compound_document',
      action: 'CREATE_COMPOUND_DOCUMENT',
      response: 'data.document',
    },
    {
      url: 'compounds/:compound_id/forms',
      method: 'get',
      id: 'compound_forms',
      action: 'GET_COMPOUND_FORMS',
      response: 'data.forms',
    },
    {
      url: 'compounds/:compound_id/forms',
      method: 'post',
      id: 'compound_forms',
      action: 'CREATE_COMPOUND_FORM',
      response: 'data.form',
    },
    {
      url: 'compounds/:compound_id/forms/:form_id',
      method: 'put',
      id: 'update_compound_forms',
      action: 'UPDATE_COMPOUND_FORM',
      response: 'data.form',
    },
    {
      url: 'compounds/:compound_id/owners',
      method: 'get',
      id: 'compound_owners',
      action: 'GET_COMPOUND_OWNERS',
      response: 'data.owners',
    },
    {
      url: 'compounds/:compound_id/owners',
      method: 'post',
      id: 'compound_owners',
      action: 'CREATE_COMPOUND_OWNER',
      response: 'data.owner',
    },
    {
      url: 'compounds/:compound_id/owners/:owner_id',
      method: 'delete',
      id: 'compound_owners',
      action: 'DELETE_COMPOUND_OWNER',
      response: 'data.owner',
    },
    {
      url: 'compounds/:compound_id/owners/:owner_id',
      method: 'put',
      id: 'compound_owners',
      action: 'UPDATE_COMPOUND_OWNER',
      response: 'data.owner',
    },
    {
      url: 'units',
      method: 'get',
      id: 'units',
      action: 'GET_UNITS',
      response: 'data.units',
    },
    {
      url: 'sellable_units',
      method: 'get',
      id: 'units',
      action: 'GET_UNITS_OWNERS_MODULE',
      response: 'data.sellable_units',
    },
    {
      url: 'sellable_units/editable_owner_units',
      method: 'get',
      id: 'units',
      action: 'GET_EDITABLE_UNITS_OWNERS_MODULE',
      response: 'data.units',
    },
    {
      url: 'sellable_units/all_units',
      method: 'get',
      id: 'units_has_no_user',
      action: 'GET_UNITS_HAS_NO_USER',
      response: 'data.units',
    },
    {
      url: 'units/brief',
      method: 'get',
      id: 'units',
      action: 'GET_UNITS_BRIEF',
      response: 'data.units',
    },
    {
      url: 'units/:unit_id',
      method: 'get',
      id: 'unit',
      action: 'GET_UNIT',
      response: 'data.unit',
    },
    {
      url: 'units',
      method: 'post',
      id: 'unit',
      action: 'CREATE_UNIT',
      response: 'data.unit',
    },

    {
      url: 'units/:unit_id',
      method: 'put',
      id: 'unit',
      action: 'UPDATE_UNIT',
      response: 'data.unit',
    },
    {
      url: 'units/:unit_id/notes',
      method: 'get',
      id: 'unit_notes',
      action: 'GET_UNIT_NOTES',
      response: 'data.notes',
    },
    {
      url: 'units/:unit_id/notes',
      method: 'post',
      id: 'unit_note',
      action: 'CREATE_UNIT_NOTE',
      response: 'data.note',
    },
    {
      url: 'units/:unit_id/history',
      method: 'get',
      id: 'unit_history',
      action: 'GET_UNIT_HISTORY',
      response: 'data.unit_history',
    },
    {
      url: 'units/:unit_id/price',
      method: 'get',
      id: 'duration_price',
      action: 'GET_UNIT_PRICES',
      response: 'data.price',
    },
    {
      url: 'tenants',
      method: 'get',
      id: 'tenants',
      action: 'GET_TENANTS',
      response: 'data.tenants',
    },
    {
      url: 'tenants/:tenant_id',
      method: 'get',
      id: 'tenant',
      action: 'GET_TENANT',
      response: 'data.tenant',
    },
    {
      url: 'leads/:lead_id',
      method: 'get',
      id: 'lead',
      action: 'GET_LEAD',
      response: 'data.lead',
    },
    {
      url: 'leads/:lead_id',
      method: 'put',
      id: 'lead',
      action: 'UPDATE_LEAD',
      response: 'data.lead',
    },
    {
      url: 'leads/:lead_id/qualify',
      method: 'put',
      id: 'lead',
      action: 'UPDATE_LEAD_QUALIFIED',
      response: 'data',
    },
    {
      url: 'maintenance/issues/:issue_id/attachments',
      method: 'get',
      id: 'issue_attachments',
      action: 'GET_ISSUE_ATTACHMENTS',
      response: 'data.attachments',
      onPending: 'reset',
    },
    {
      url: 'maintenance/issues/:issue_id/attachments',
      method: 'post',
      id: 'issue_attachments',
      action: 'ADD_ISSUE_ATTACHMENT',
      response: 'data.attachment',
      onSuccess: 'push',
    },
    {
      url: 'maintenance/issues/:issue_id/notes',
      method: 'get',
      id: 'issue_notes',
      action: 'GET_ISSUE_NOTES',
      response: 'data.notes',
    },
    {
      url: 'maintenance/issues/:issue_id/notes',
      method: 'post',
      id: 'issue_notes',
      action: 'CREATE_ISSUE_NOTE',
      response: 'data.note',
      onSuccess: 'push',
    },
    {
      url: 'maintenance/issues/:issue_id/notes/:note_id',
      method: 'delete',
      id: 'issue_notes',
      action: 'DELETE_ISSUE_NOTE',
      response: 'data.note',
      onSuccess: 'remove',
      removeBy: 'id',
    },
    {
      url: 'users',
      method: 'get',
      id: 'users',
      action: 'GET_USERS',
      response: 'data.users',
    },
    {
      url: 'users/brief',
      method: 'get',
      id: 'users',
      action: 'GET_BRIEF_USERS',
      response: 'data.users',
    },
    {
      url: 'maintenance/issues/:issue_id/feedback',
      method: 'put',
      id: 'issue_feedback',
      action: 'CREATE_ISSUE_FEEDBACK',
      response: 'data.maintenance_issue',
    },
    {
      url: 'companies/:company_slug',
      method: 'get',
      id: 'company',
      action: 'GET_COMPANY',
      response: 'data.company',
    },
    {
      url: 'companies/:company_slug',
      method: 'put',
      id: 'company',
      action: 'UPDATED_COMPANY',
      response: 'data.company',
    },
    {
      url: 'tenants/:tenant_id/payments/:payment_id/invoices',
      method: 'post',
      id: 'invoice',
      action: 'GENERATE_PAYMENT_INVOICE',
      response: 'data.invoice',
    },
    {
      url: 'tenants/:tenant_id/invoices/:invoice_id',
      method: 'get',
      id: 'invoice',
      action: 'GET_INVOICE',
      response: 'data.invoice',
    },
    {
      url: 'tenants/:tenant_id/invoices/:invoice_id/credit_notes',
      method: 'post',
      id: 'credit_note',
      action: 'ADD_CREDIT_NOTE_TO_INVOICE',
      response: 'data.credit_note',
    },
    {
      url: 'tenants/:tenant_id/invoices/:invoice_id/collections',
      method: 'post',
      id: 'collect_invoice',
      action: 'COLLECT_TENANT_INVOICE',
      response: 'data.invoice',
    },
    {
      url: 'tenants/:tenant_id/invoices/:invoice_id/allocations',
      method: 'get',
      id: 'invoice_collections',
      action: 'GET_INVOICE_COLLECTIONS',
      response: 'data.allocations',
    },
    {
      url: 'tenants/:tenant_id/credit_notes',
      method: 'post',
      id: 'credit_note',
      action: 'CREATE_CREDIT_NOTE',
      response: 'data.credit_note',
    },
    {
      url: 'tenants/:tenant_id/credit_notes',
      method: 'get',
      id: 'credit_notes',
      action: 'GET_CREDIT_NOTES',
      response: 'data',
    },
    {
      url: 'tenants/:tenant_id/credit_notes/:credit_note_id',
      method: 'get',
      id: 'credit_note',
      action: 'GET_CREDIT_NOTE',
      response: 'data.credit_note',
    },
    {
      url: 'tenants/:tenant_id/credit_notes/:credit_note_id',
      method: 'put',
      id: 'credit_note',
      action: 'UPDATE_CREDIT_NOTE',
      response: 'data.credit_note',
    },
    {
      url: 'tenants/:tenant_id/invoices',
      method: 'get',
      id: 'tenant_invoices',
      action: 'GET_TENANT_INVOICES',
      response: 'data',
    },
    {
      url: 'integration/sadad/exclude_from_sadad/:invoice_id',
      method: 'post',
      id: 'exclude_sadad',
      action: 'EXCLUDE_FROM_SADAD',
      response: 'data',
    },
    {
      url: 'tenants/:tenant_id/invoices/unposted',
      method: 'get',
      id: 'tenant_unposted_invoices',
      action: 'GET_TENANT_UNPOSTED_INVOICES',
      response: 'data',
    },
    {
      url: 'tenants/:tenant_id/invoices',
      method: 'get',
      id: 'tenant_due_amount_invoices',
      action: 'GET_TENANT_DUE_AMOUNT_INVOICES',
      response: 'data.invoices',
    },
    {
      url: 'tenants/:tenant_id/invoices',
      method: 'get',
      id: 'due_invoices',
      action: 'GET_DUE_INVOICES',
      response: 'data',
    },
    {
      url: 'tenants/:tenant_id/invoices/:invoice_id',
      method: 'get',
      id: 'tenant_invoice',
      action: 'GET_TENANT_INVOICE',
      response: 'data.invoice',
    },
    {
      url: 'tenants/:tenant_id/credit_notes/:credit_note_id/allocate',
      method: 'post',
      id: 'allocate_credit_note',
      action: 'ALLOCATE_CREDIT_NOTE',
      response: 'data',
    },
    {
      url: 'tenants/brief',
      method: 'get',
      id: 'tenantsbrief',
      action: 'GET_TENANT_BRIEFLY',
      response: 'data.tenants',
    },
    {
      url: 'accounts',
      method: 'get',
      id: 'accounts',
      action: 'GET_ACCOUNTS',
      response: 'data.accounts',
    },
    {
      url: 'sub_accounts',
      method: 'get',
      id: 'sub_accounts',
      action: 'GET_SUB_ACCOUNTS',
      response: 'data.accounts',
    },
    {
      url: 'tenants/:tenant_id/credit_notes/:credit_note_id/cash_refunds',
      method: 'post',
      id: 'cache_refund',
      action: 'CACHE_REFUND',
      response: 'data',
    },
    {
      url: 'tenants/:tenant_id/multiple_invoices_collections',
      method: 'post',
      id: 'collect_invoices',
      action: 'COLLECT_MULTIPLE_INVOICES',
      response: 'data',
    },
    {
      url: 'tenants/:customer_id/quotations/:quotation_id',
      method: 'get',
      id: 'quotation',
      action: 'GET_TENANT_QUOTATION',
      response: 'data.quotation',
    },
    {
      url: 'tenants/:customer_id/quotations/:quotation_id',
      method: 'put',
      id: 'quotation',
      action: 'UPDATE_TENANT_QUOTATION',
      response: 'data.quotation',
    },
    {
      url: 'tenants/:tenant_id/quotations/:quotation_id',
      method: 'delete',
      id: 'quotation',
      action: 'DELETE_TENANT_QUOTATION',
      response: 'data.quotation',
    },
    {
      url: 'tenants/:tenant_id/quotations/:quotation_id/units',
      method: 'post',
      id: 'quotation_unit',
      action: 'CREATE_TENANT_QUOTATION_UNIT',
      response: 'data.quotation',
    },
    {
      url: 'tenants/:tenant_id/quotations/:quotation_id/status',
      method: 'put',
      id: 'quotation_status',
      action: 'UPDATE_TENANT_QUOTATION_STATUS',
      response: 'data.quotation',
    },
    {
      url: 'tenants/:tenant_id/quotations/:quotation_id/finalize',
      method: 'put',
      id: 'quotation_finalize',
      action: 'FINALIZE_TENANT_QUOTATION',
      response: 'data.quotation',
    },
    {
      url: 'tenants/:tenant_id/quotations/:quotation_id/renewals',
      method: 'post',
      id: 'quotation_renewals',
      action: 'RENEWALS_TENANT_QUOTATION',
      response: 'data.quotation',
    },
    {
      url: 'tenants/:tenant_id/quotations/:quotation_id/down_payment_invoice',
      method: 'post',
      id: 'generate_tenant_downpayment',
      action: 'GENERATE_TENANT_DOWN_PAYMENT',
      response: 'data',
    },
    {
      url: 'quotations_force_delete',
      method: 'delete',
      id: 'quotation_force_delete',
      action: 'QUOTATION_FORCE_DELETE',
      response: 'data.quotation_force_delete',
    },
    {
      url: 'tenants/:tenant_id/quotations/:quotation_id/units/:unit_id',
      method: 'put',
      id: 'quotation_unit',
      action: 'UPDATE_TENANT_QUOTATION_UNIT',
      response: 'data.quotation',
    },
    {
      url: 'tenants/:tenant_id/quotations/:quotation_id/units/:unit_id',
      method: 'delete',
      id: 'quotation_unit',
      action: 'DELETE_TENANT_QUOTATION_UNIT',
      response: 'data.quotation',
    },
    {
      url: 'leads/:customer_id/quotations/:quotation_id',
      method: 'get',
      id: 'quotation',
      action: 'GET_LEAD_QUOTATION',
      response: 'data.quotation',
    },
    {
      url: 'leads/:customer_id/quotations/:quotation_id/quotation_templates',
      method: 'get',
      id: 'quotation_options',
      action: 'GET_LEAD_QUOTATION_TEMPLATES_OPTIONS',
      response: 'data',
    },
    {
      url: 'leads/:customer_id/quotations/:quotation_id',
      method: 'put',
      id: 'quotation',
      action: 'UPDATE_LEAD_QUOTATION',
      response: 'data.quotation',
    },
    {
      url: 'leads/:lead_id/quotations/:quotation_id/down_payment_invoice',
      method: 'post',
      id: 'generate_lead_downpayment',
      action: 'GENERATE_LEAD_DOWNPAYMENT',
      response: 'data',
    },
    {
      url: 'tenants/:tenant_id/cash_receipts',
      method: 'get',
      id: 'cash_receipts',
      action: 'GET_CASH_RECEIPTS',
      response: 'data',
    },
    {
      url: 'tenants/:tenant_id/leases/:lease_id/payments',
      method: 'get',
      id: 'lease_payments',
      action: 'GET_TENANT_LEASE_PAYMENTS',
      response: 'data.payments',
    },
    {
      url: 'operations/leasing_clearances/:operation_id/credit_notes',
      method: 'get',
      id: 'lease_credit_notes',
      action: 'GET_LEASE_CREDIT_NOTES',
      response: 'data.credit_notes',
    },
    {
      url: 'operations/leasing_clearances',
      method: 'get',
      id: 'leasing_clearances',
      action: 'GET_CLEARANCE_ORDERS',
      response: 'data',
    },
    {
      url: 'operations/leasing_clearances/:operation_id',
      method: 'get',
      id: 'operation',
      action: 'GET_CLEARANCE_ORDER',
      response: 'data.operation',
    },
    {
      url: 'operations/leasing_clearances/:operation_id',
      method: 'put',
      id: 'operation',
      action: 'UPDATE_CLEARANCE_ORDER',
      response: 'data.operation',
    },
    {
      url: 'operations/leasing_clearances/:operation_id/invoices',
      method: 'post',
      id: 'clearance_invoice',
      action: 'GENERATE_CLEARANCE_INVOICE',
      response: 'data.operation',
    },
    {
      url: 'operations/leasing_clearances/:operation_id/cancel_leases',
      method: 'post',
      id: 'cancel_leases',
      action: 'CANCEL_OPERATION_LEASES',
      response: 'data',
    },
    {
      url: 'operations/leasing_orders',
      method: 'get',
      id: 'leasing_orders',
      action: 'GET_LEASING_ORDERS',
      response: 'data',
    },
    {
      url: 'operations/leasing_orders/:operation_id',
      method: 'get',
      id: 'operation',
      action: 'GET_LEASING_ORDER',
      response: 'data.operation',
    },
    {
      url: 'operations/leasing_orders/:operation_id',
      method: 'put',
      id: 'operation',
      action: 'UPDATE_LEASING_ORDER',
      response: 'data.operation',
    },
    {
      url: 'compounds/:compound_id/blocks',
      method: 'get',
      id: 'compound_blocks',
      action: 'GET_COMPOUND_BLOCKS',
      response: 'data.blocks',
    },
    {
      url: 'buildings',
      method: 'get',
      id: 'buildings',
      action: 'GET_BUILDINGS',
      response: 'data.buildings',
    },
    {
      url: 'compounds/:compound_id/notes',
      method: 'get',
      id: 'compound_notes',
      action: 'GET_COMPOUND_NOTES',
      response: 'data.notes',
    },
    {
      url: 'compounds/:compound_id/notes',
      method: 'post',
      id: 'compound_note',
      action: 'CREATE_COMPOUND_NOTE',
      response: 'data.note',
    },
    {
      url: 'compounds/:compound_id/history',
      method: 'get',
      id: 'compound_history',
      action: 'GET_COMPOUND_HISTORY',
      response: 'data.compound_history',
    },
    {
      url: 'tenants/:tenant_id/leases',
      method: 'get',
      id: 'tenant_leases',
      action: 'GET_TENANT_LEASES',
      response: 'data',
    },
    {
      url: 'tenants/:tenant_id/leases/:lease_id',
      method: 'put',
      id: 'tenant_lease',
      action: 'UPDATE_TENANT_LEASE',
      response: 'data.lease',
    },
    {
      url: 'tenants/:tenant_id/leases/:lease_id',
      method: 'get',
      id: 'tenant_lease',
      action: 'GET_TENANT_LEASE',
      response: 'data.lease',
    },
    {
      url: 'tenants/:tenant_id/quotations',
      method: 'get',
      id: 'tenant_quotations',
      action: 'GET_TENANT_QUOTATIONS',
      response: 'data',
    },
    {
      url: 'tenants/:tenant_id/quotations',
      method: 'get',
      id: 'quotations_dropdown',
      action: 'GET_TENANT_QUOTATIONS_DROPDOWN',
      response: 'data',
    },
    {
      url: 'tenants/:tenant_id/quotations',
      method: 'post',
      id: 'tenant_quotation',
      action: 'CREATE_TENANT_QUOTATION',
      response: 'data.quotation',
    },
    {
      url: 'cms/banks',
      method: 'get',
      id: 'banks',
      action: 'GET_BANKS',
      response: 'data.banks',
    },
    {
      url: 'compounds/:compound_id/banks',
      method: 'get',
      id: 'compound_banks',
      action: 'GET_COMPOUND_BANKS',
      response: 'data.banks',
    },
    {
      url: 'compounds/:compound_id/banks',
      method: 'post',
      id: 'compound_banks',
      action: 'ADD_COMPOUND_BANK',
      response: 'data.banks',
      onSuccess: 'push',
    },
    {
      url: 'compounds/:compound_id/banks/:bank_id',
      method: 'get',
      id: 'compound_bank',
      action: 'GET_COMPOUND_BANK',
      response: 'data.bank',
    },
    {
      url: 'compounds/:compound_id/banks/:bank_id',
      method: 'put',
      id: 'compound_bank',
      action: 'UPDATE_COMPOUND_BANK',
      response: 'data.bank',
    },
    {
      url: 'tenants/:tenant_id/credit_notes/:credit_note_id/cash_refunds',
      method: 'get',
      id: 'cash_refunds',
      action: 'GET_CASH_REFUNDS',
      response: 'data.cash_refund',
    },
    {
      url: 'tenants/:tenant_id/contracts',
      method: 'get',
      id: 'tenant_contracts',
      action: 'GET_TENANT_CONTRACTS',
      response: 'data',
    },
    {
      url: 'tenants/:tenant_id/contracts/:contract_id',
      method: 'get',
      id: 'tenant_contract',
      action: 'GET_TENANT_CONTRACT',
      response: 'data.contract',
    },
    {
      url: 'tenants/:tenant_id/contracts',
      method: 'get',
      id: 'contracts_dropdown',
      action: 'GET_CONTRACTS_DROPDOWN',
      response: 'data',
    },
    {
      url: 'contracts',
      method: 'get',
      id: 'contracts',
      action: 'GET_CONTRACTS',
      response: 'data',
    },
    {
      url: 'contracts/:contract_id/activities',
      method: 'get',
      id: 'contract_activities',
      action: 'GET_CONTRACT_ACTIVITIES',
      response: 'data',
    },
    {
      url: 'contracts/:contract_id/installments',
      method: 'get',
      id: 'contract_payments',
      action: 'GET_CONTRACT_PAYMENTS',
      response: 'data',
    },
    {
      url: 'contracts/:contract_id/post_invoice',
      method: 'post',
      id: 'post_invoice_per_contract',
      action: 'POST_INVOICE_PER_CONTRACT',
      response: 'data',
    },
    {
      url: 'contracts/:contract_id/installments/:installment_id/post_invoice',
      method: 'post',
      id: 'post_invoice_per_installment',
      action: 'POST_INVOICE_PER_INSTALLMENT',
      response: 'data',
    },
    {
      url: 'tenants/:tenant_id/contracts/:contract_id/status',
      method: 'put',
      id: 'contract',
      action: 'UPDATE_CONTRACT_STATUS',
      response: 'data.contract',
    },
    {
      url: 'tenants/:tenant_id/documents',
      method: 'get',
      id: 'tenant_documents',
      action: 'GET_TENANT_DOCUMENTS',
      response: 'data',
    },
    {
      url: 'tenants/:tenant_id/documents',
      method: 'post',
      id: 'tenant_document',
      action: 'CREATE_TENANT_DOCUMENT',
      response: 'data.document',
    },
    {
      url: 'leasing_clearances/:leasing_clearance_id/documents',
      method: 'post',
      id: 'leasing_clearance_document',
      action: 'CREATE_CLEARANCE_DOCUMENT',
      response: 'data.document',
    },
    {
      url: 'leasing_clearances/:leasing_clearance_id/documents',
      method: 'get',
      id: 'leasing_clearance_document_create',
      action: 'GET_CLEARANCE_DOCUMENTS',
      response: 'data',
    },
    {
      url: 'tenants/:tenant_id/notes',
      method: 'get',
      id: 'tenant_notes',
      action: 'GET_TENANT_NOTES',
      response: 'data.notes',
    },
    {
      url: 'tenants/:tenant_id/histories',
      method: 'get',
      id: 'tenant_history',
      action: 'GET_TENANT_HISTORY',
      response: 'data',
    },
    {
      url: 'tenants/:tenant_id/installments',
      method: 'get',
      id: 'tenant_payments',
      action: 'GET_TENANT_PAYMENTS',
      response: 'data',
    },
    {
      url: 'tenants/:tenant_id',
      method: 'put',
      id: 'tenant',
      action: 'UPDATE_TENANT',
      response: 'data.tenant',
    },
    {
      url: 'tenants/:tenant_id/leases/:lease_id/terminate',
      method: 'post',
      id: 'tenant_lease',
      action: 'TERMINATE_LEASE',
      response: 'data',
    },
    {
      url: 'tenants/:tenant_id/notes',
      method: 'post',
      id: 'tenant_note',
      action: 'CREATE_TENANT_NOTE',
      response: 'data',
    },
    {
      url: 'owners',
      method: 'get',
      id: 'owners',
      action: 'GET_OWNERS',
      response: 'data.owners',
    },
    {
      url: 'cities',
      method: 'get',
      id: 'cities',
      action: 'GET_CITIES',
      response: 'data.cities',
    },
    {
      url: 'tenants/:tenant_id/passwords/resend',
      method: 'put',
      id: 'tenant_reset',
      action: 'RESEND_TENANT_PASSWORD',
      response: 'data',
    },
    {
      url: 'unit_types',
      method: 'GET',
      id: 'unit_types',
      action: 'GET_UNIT_TYPES',
      response: 'data.unit_types',
    },
    {
      url: 'unit_types/brief',
      method: 'GET',
      id: 'unit_types',
      action: 'GET_UNIT_TYPES_BRIEF',
      response: 'data.unit_types',
    },
    {
      url: 'unit_types',
      method: 'post',
      id: 'unit_type',
      action: 'CREATE_UNIT_TYPE',
      response: 'data.unit_type',
    },
    {
      url: 'unit_types/:unit_type_id',
      method: 'put',
      id: 'unit_type',
      action: 'UPDATE_UNIT_TYPE',
      response: 'data.unit_type',
    },
    {
      url: 'interest_levels',
      method: 'GET',
      id: 'interest_levels',
      action: 'GET_INTEREST_LEVELS',
      response: 'data.interest_levels',
    },
    {
      url: 'services',
      method: 'GET',
      id: 'services',
      action: 'GET_SERVICES',
      response: 'data',
    },
    {
      url: 'tenants/:tenant_id/leases/:lease_id/services',
      method: 'POST',
      id: 'lease_services',
      action: 'ADD_LEASE_SERVICE',
      response: 'data',
    },
    {
      url: 'tenants/:tenant_id/quotations/:quotation_id/units/:unit_id/items',
      method: 'POST',
      id: 'quotation_unit_item',
      action: 'ADD_QUOTATION_UNIT_ITEM',
      response: 'data',
    },
    {
      url: 'tenants/:tenant_id/quotations/:quotation_id/history',
      method: 'get',
      id: 'quotation_history',
      action: 'GET_QUOTATION_HISTORY',
      response: 'data',
    },
    {
      url:
        'tenants/:tenant_id/quotations/:quotation_id/units/:unit_id/items/:item_id',
      method: 'DELETE',
      id: 'quotation_unit_item',
      action: 'DELETE_QUOTATION_UNIT_ITEM',
      response: 'data',
    },
    {
      url:
        'tenants/:tenant_id/quotations/:quotation_id/units/:unit_id/items/:item_id',
      method: 'PUT',
      id: 'quotation_unit_item_update_description',
      action: 'UPDATE_UNIT_ITEM_DESCRIPTION',
      response: 'data',
    },
    {
      url:
        'leads/:leads_id/quotations/:quotation_id/units/:unit_id/items/:item_id',
      method: 'PUT',
      id: 'leads_quotation_unit_item_update_description',
      action: 'UPDATE_LEADS_UNIT_ITEM_DESCRIPTION',
      response: 'data',
    },
    {
      url: 'leads/:lead_id/quotations',
      method: 'GET',
      id: 'lead_quotations',
      action: 'GET_LEAD_QUOTATIONS',
      response: 'data',
    },
    {
      url: 'leads/:lead_id/quotations/:quotation_id/status',
      method: 'put',
      id: 'quotation_status',
      action: 'UPDATE_LEAD_QUOTATION_STATUS',
      response: 'data.quotation',
    },
    {
      url: 'maintenance/exports/issues.xls',
      method: 'GET',
      id: 'exports_issues',
      action: 'EXPORT_MAINTENANCE_REQUESTS',
      response: 'data',
    },
    {
      url: 'operations/expected_arrivals',
      method: 'get',
      id: 'expected_arrivals',
      action: 'GET_EXPECTED_ARRIVALS',
      response: 'data',
    },
    {
      url: 'pre_occupancy_orders',
      method: 'get',
      id: 'preoccupancy_orders',
      action: 'GET_PREOCCUPANCY_ORDER_DETAILS',
      response: 'data',
    },
    {
      url: 'leasable_units',
      method: 'get',
      id: 'leasable_units',
      action: 'GET_LEASABLE_UNITS',
      response: 'data.units',
    },
    {
      url: 'news',
      method: 'get',
      id: 'media',
      action: 'GET_MEDIA',
      response: 'data',
    },
    {
      url: 'news',
      method: 'post',
      id: 'media_item',
      action: 'CREATE_MEDIA',
      response: 'data',
    },
    {
      url: 'news/:item_id',
      method: 'get',
      id: 'media_item',
      action: 'GET_MEDIA_ITEM',
      response: 'data.news',
    },
    {
      url: 'news/:item_id',
      method: 'put',
      id: 'media_item',
      action: 'UPDATE_MEDIA_ITEM',
      response: 'data.news',
    },

    /******Get News Posts*******/
    {
      url: 'post/posts',
      method: 'post',
      id: 'post_item',
      action: 'CREATE_POST',
      response: 'data',
    },
    {
      url: 'post/posts',
      method: 'get',
      id: 'post',
      action: 'GET_POSTS',
      response: 'data',
    },
    {
      url: 'post/categories',
      method: 'get',
      id: 'categories',
      action: 'GET_CATEGORY_LIST',
      response: 'data',
    },
    {
      url: 'post/posts/:post_id',
      method: 'get',
      id: 'post_item',
      action: 'GET_POST_ITEM',
      response: 'data',
    },
    {
      url: 'post/posts/:post_id',
      method: 'put',
      id: 'post_item',
      action: 'UPDATE_POST_ITEM',
      response: 'data',
    },
    {
      url: 'post/categories/:category_id/sub_categories',
      method: 'get',
      id: 'sub_category',
      action: 'GET_SUB_CATEGORY_LIST',
      response: 'data',
    },
    /************Get Posts End****************/

    /**************Get Contact Forms**************/
    {
      url: 'contact_us/forms',
      method: 'get',
      id: 'contact',
      action: 'GET_CONTACTS',
      response: 'data',
    },
    {
      url: 'contact_us/forms/:contact_id',
      method: 'get',
      id: 'contact_item',
      action: 'GET_CONTACT_ITEM',
      response: 'data',
    },
    {
      url: 'contact_us/form_replies',
      method: 'post',
      id: 'contact_item',
      action: 'REPLY_CONTACT_ITEM',
      response: 'data',
    },
    /**************End Get Contact Forms**************/

    {
      url: 'business_types',
      method: 'get',
      id: 'business_types',
      action: 'GET_BUSINESS_TYPES',
      response: 'data',
    },
    {
      url: 'business_types/:business_type_id',
      method: 'get',
      id: 'business_type',
      action: 'GET_BUSINESS_TYPE',
      response: 'data.business_type',
    },
    {
      url: 'business_types/:business_type_id',
      method: 'put',
      id: 'business_type',
      action: 'UPDATE_BUSINESS_TYPE',
      response: 'data.business_type',
    },
    {
      url: 'business_types',
      method: 'post',
      id: 'business_type',
      action: 'CREATE_BUSINESS_TYPES',
      response: 'data.business_type',
    },
    {
      url: 'business_type_destributions',
      method: 'get',
      id: 'business_type_destributions',
      action: 'GET_BUSINESS_TYPE_DESTRIBUTIONS',
      response: 'data',
    },
    {
      url: 'business_type_destributions/:destribution_id',
      method: 'get',
      id: 'business_type_destribution',
      action: 'GET_BUSINESS_TYPE_DESTRIBUTION',
      response: 'data.business_type_destribution',
    },
    {
      url: 'business_type_destributions/:destribution_id',
      method: 'put',
      id: 'business_type_destribution',
      action: 'UPDATE_BUSINESS_TYPE_DESTRIBUTION',
      response: 'data.business_type',
    },
    {
      url: 'business_type_destributions',
      method: 'post',
      id: 'business_type_destribution',
      action: 'CREATE_BUSINESS_TYPE_DESTRIBUTION',
      response: 'data',
    },
    {
      url: 'trade_names',
      method: 'get',
      id: 'trade_names',
      action: 'GET_TRADE_NAMES',
      response: 'data',
    },
    {
      url: 'trade_names',
      method: 'post',
      id: 'trade_name',
      action: 'CREATE_TRADE_NAME',
      response: 'data',
    },
    {
      url: 'trade_names/:trade_name_id',
      method: 'get',
      id: 'trade_name',
      action: 'GET_TRADE_NAME',
      response: 'data.trade_name',
    },
    {
      url: 'trade_names/:trade_name_id',
      method: 'put',
      id: 'trade_name',
      action: 'UPDATE_TRADE_NAME',
      response: 'data',
    },
    {
      url: 'business_types/:business_type_id/shop_types',
      method: 'get',
      id: 'shop_types',
      action: 'GET_SHOP_TYPES',
      response: 'data',
    },
    {
      url: 'business_types/:business_type_id/shop_types',
      method: 'post',
      id: 'shop_type',
      action: 'CREATE_SHOP_TYPE',
      response: 'data',
    },
    {
      url: 'business_types/:business_type_id/shop_types/:shop_type_id',
      method: 'get',
      id: 'shop_type',
      action: 'GET_SHOP_TYPE',
      response: 'data.shop_type',
    },
    {
      url: 'business_types/:business_type_id/shop_types/:shop_type_id',
      method: 'put',
      id: 'shop_type',
      action: 'UPDATE_SHOP_TYPE',
      response: 'data',
    },
    {
      url: 'reports/gross_leasable_area',
      method: 'get',
      id: 'gross_leasable_area',
      action: 'GET_GROSS_LEASABLE_AREA',
      response: 'data',
    },
    {
      url: 'payment_terms',
      method: 'get',
      id: 'payment_terms',
      action: 'GET_PAYMENT_TERMS',
      response: 'data.payment_terms',
    },
    {
      url: 'payment_terms',
      method: 'post',
      id: 'update_payment_terms',
      action: 'UPDATE_PAYMENT_TERMS',
      response: 'data',
    },
    {
      url: 'quotations/:quotation_id/installment_dates',
      method: 'get',
      id: 'installment_dates',
      action: 'GET_INSTALLMENT_DATES',
    },
    {
      url: 'templates?filter[type]=Template::Contract',
      method: 'get',
      id: 'contract_templates',
      action: 'GET_CONTRACT_TEMPLATES',
      response: 'data.templates',
    },
    {
      url: 'templates',
      method: 'post',
      id: 'contract_template',
      action: 'CREATE_CONTRACT_TEMPLATE',
      response: 'data.templates',
    },
    {
      url: 'templates',
      method: 'post',
      id: 'quotation_template',
      action: 'CREATE_QUOTATION_TEMPLATE',
      response: 'data.templates',
    },
    {
      url: 'templates?filter[type]=Template::Quotation',
      method: 'get',
      id: 'quotation_templates',
      action: 'GET_QUOTATION_TEMPLATES',
      response: 'data.templates',
    },
    {
      url: 'templates/:template_id',
      method: 'get',
      id: 'contract_template',
      action: 'GET_CONTRACT_TEMPLATE',
      response: 'data.template',
    },
    {
      url: 'templates/:template_id',
      method: 'get',
      id: 'quotation_template',
      action: 'GET_QUOTATION_TEMPLATE',
      response: 'data.template',
    },
    {
      url: 'templates/:template_id',
      method: 'put',
      id: 'contract_template',
      action: 'UPDATE_CONTRACT_TEMPLATE',
      response: 'data.template',
    },
    {
      url: 'templates/:template_id',
      method: 'put',
      id: 'quotation_template',
      action: 'UPDATE_QUOTATION_TEMPLATE',
      response: 'data.template',
    },
    {
      url: 'templates/contract_options',
      method: 'get',
      id: 'contract_options',
      action: 'GET_CONTRACT_OPTIONS',
      response: 'data.options',
    },
    {
      url: 'templates/quotation_options',
      method: 'get',
      id: 'quotation_options',
      action: 'GET_QUOTATION_OPTIONS',
      response: 'data.options',
    },
    {
      url: 'maintenance/part_categories',
      method: 'get',
      id: 'part_categories',
      action: 'GET_PART_CATEGORIES',
      response: 'data.part_categories',
    },
    {
      url: 'maintenance/part_categories',
      method: 'post',
      id: 'part_category',
      action: 'CREATE_PART_CATEGORY',
      response: 'data',
    },
    {
      url: 'maintenance/part_categories/:category_id',
      method: 'get',
      id: 'part_category',
      action: 'GET_PART_CATEGORY',
      response: 'data.part_category',
    },
    {
      url: 'maintenance/part_categories/:category_id',
      method: 'put',
      id: 'part_category',
      action: 'UPDATE_PART_CATEGORY',
      response: 'data.part_category',
    },
    {
      url: 'maintenance/parts',
      method: 'get',
      id: 'parts',
      action: 'GET_PARTS',
      response: 'data',
    },
    {
      url: 'maintenance/parts',
      method: 'post',
      id: 'part',
      action: 'CREATE_PART',
      response: 'data.part',
    },
    {
      url: 'maintenance/parts/:part_id',
      method: 'get',
      id: 'part',
      action: 'GET_PART',
      response: 'data.part',
    },
    {
      url: 'maintenance/parts/:part_id',
      method: 'put',
      id: 'part',
      action: 'UPDATE_PART',
      response: 'data.part',
    },
    {
      url: 'maintenance/parts/:part_id/restocks',
      method: 'get',
      id: 'restocks',
      action: 'GET_RESTOCKS',
      response: 'data',
    },
    {
      url: 'maintenance/parts/:part_id/restocks',
      method: 'post',
      id: 'restock',
      action: 'CREATE_RESTOCK',
      response: 'data.restock',
    },
    {
      url: 'maintenance/parts/:part_id/restocks/:item_id',
      method: 'get',
      id: 'restock',
      action: 'GET_RESTOCK',
      response: 'data.restock',
    },
    {
      url: 'maintenance/parts/:part_id/restocks/:item_id',
      method: 'put',
      id: 'restock',
      action: 'UPDATE_RESTOCK',
      response: 'data.restock',
    },
    {
      url: 'maintenance/issues/:issue_id/work_order_additional_items',
      method: 'get',
      id: 'work_order_additional_items',
      action: 'GET_WORK_ORDER_ADDITIONAL_ITEMS',
      response: 'data',
    },
    {
      url: 'maintenance/issues/:issue_id/work_order_additional_items',
      method: 'post',
      id: 'work_order_additional_item',
      action: 'CREATE_WORK_ORDER_ADDITIONAL_ITEM',
      response: 'data.work_order_additional_item',
    },
    {
      url: 'maintenance/issues/:issue_id/work_order_additional_items/:item_id',
      method: 'put',
      id: 'work_order_additional_item',
      action: 'UPDATE_WORK_ORDER_ADDITIONAL_ITEM',
      response: 'data.work_order_additional_item',
    },
    {
      url: 'maintenance/issues/:issue_id/work_order_additional_items/:item_id',
      method: 'get',
      id: 'work_order_additional_item',
      action: 'GET_WORK_ORDER_ADDITIONAL_ITEM',
      response: 'data.work_order_additional_item',
    },
    {
      url: 'maintenance/issues/:issue_id/work_order_items',
      method: 'get',
      id: 'work_order_items',
      action: 'GET_WORK_ORDER_ITEMS',
      response: 'data',
    },
    {
      url: 'maintenance/issues/:issue_id/work_order_items',
      method: 'post',
      id: 'work_order_item',
      action: 'CREATE_WORK_ORDER_ITEMS',
      response: 'data.work_order_item',
    },
    {
      url: 'maintenance/issues/:issue_id/work_order_items/:item_id',
      method: 'get',
      id: 'work_order_item',
      action: 'GET_WORK_ORDER_ITEM',
      response: 'data.work_order_item',
    },
    {
      url: 'maintenance/issues/:issue_id/work_order_items/:item_id',
      method: 'put',
      id: 'work_order_item',
      action: 'UPDATE_WORK_ORDER_ITEM',
      response: 'data.work_order_item',
    },
    {
      url: 'maintenance/issues/:issue_id/work_order_items/:item_id/approve',
      method: 'put',
      id: 'work_order_item',
      action: 'APPROVE_WORK_ORDER_ITEM',
      response: 'data.work_order_item',
    },
    {
      url: 'maintenance/issues/:issue_id/work_order_items/:item_id/reject',
      method: 'put',
      id: 'work_order_item',
      action: 'REJECT_WORK_ORDER_ITEM',
      response: 'data.work_order_item',
    },
    {
      url: 'maintenance/parts/:part_id/work_orders',
      method: 'get',
      id: 'part_work_orders',
      action: 'GET_PART_WORK_ORDERS',
      response: 'data',
    },
    {
      url: 'unit_types/:id',
      method: 'get',
      id: 'unit_type_prices',
      action: 'GET_UNIT_TYPE_PRICES',
      response: 'data',
    },
    {
      url: 'unit_types/:unit_type_id/amenities',
      method: 'get',
      id: 'unit_type_amenities',
      action: 'GET_UNIT_TYPE_AMENITIES',
      response: 'data',
    },
    {
      url: 'maintenance/work_order_checklists',
      method: 'get',
      id: 'work_order_checklists',
      action: 'GET_WORK_ORDER_CHECKLISTS',
      response: 'data',
    },
    {
      url: 'maintenance/work_order_checklists/:checklist_id',
      method: 'get',
      id: 'work_order_checklist',
      action: 'GET_WORK_ORDER_CHECKLIST',
      response: 'data.work_order_checklist',
    },
    {
      url: 'maintenance/work_order_checklists/:checklist_id',
      method: 'put',
      id: 'work_order_checklist',
      action: 'UPDATE_WORK_ORDER_CHECKLIST',
      response: 'data.work_order_checklist',
    },
    {
      url: 'maintenance/work_order_checklists',
      method: 'post',
      id: 'work_order_checklist',
      action: 'CREATE_WORK_ORDER_CHECKLIST',
      response: 'data.work_order_checklist',
    },
    {
      url: 'maintenance/work_order_checklists/:checklist_id/tasks',
      method: 'get',
      id: 'checklist_tasks',
      action: 'GET_CHECKLIST_TASKS',
      response: 'data',
    },
    {
      url: 'maintenance/work_order_checklists/:checklist_id/tasks',
      method: 'post',
      id: 'checklist_task',
      action: 'CREATE_CHECKLIST_TASK',
      response: 'data',
    },
    {
      url: 'maintenance/work_order_checklists/:checklist_id/tasks/:task_id',
      method: 'get',
      id: 'checklist_task',
      action: 'GET_CHECKLIST_TASK',
      response: 'data.task',
    },
    {
      url: 'maintenance/work_order_checklists/:checklist_id/tasks/:task_id',
      method: 'put',
      id: 'checklist_task',
      action: 'UPDATE_CHECKLIST_TASK',
      response: 'data.get',
    },
    {
      url: 'maintenance/issues/:issue_id/tasks',
      method: 'get',
      id: 'work_order_tasks',
      action: 'GET_WORK_ORDER_TASKS',
      response: 'data',
    },
    {
      url: 'maintenance/issues/:issue_id/tasks',
      method: 'post',
      id: 'work_order_task',
      action: 'CREATE_WORK_ORDER_TASK',
      response: 'data.task',
    },
    {
      url: 'maintenance/issues/:issue_id/tasks/:task_id',
      method: 'get',
      id: 'work_order_task',
      action: 'GET_WORK_ORDER_TASK',
      response: 'data.task',
    },
    {
      url: 'maintenance/issues/:issue_id/tasks/:task_id',
      method: 'put',
      id: 'work_order_task',
      action: 'UPDATE_WORK_ORDER_TASK',
      response: 'data.task',
    },
    {
      url: 'provider/issues/:issue_id/tasks',
      method: 'get',
      id: 'work_order_tasks',
      action: 'GET_PROVIDER_WORK_ORDER_TASKS',
      response: 'data',
    },
    {
      url: 'provider/issues/:issue_id/tasks',
      method: 'post',
      id: 'work_order_task',
      action: 'CREATE_PROVIDER_WORK_ORDER_TASK',
      response: 'data.task',
    },
    {
      url: 'provider/issues/:issue_id/tasks/:task_id',
      method: 'get',
      id: 'work_order_task',
      action: 'GET_PROVIDER_WORK_ORDER_TASK',
      response: 'data.task',
    },
    {
      url: 'provider/issues/:issue_id/tasks/:task_id',
      method: 'put',
      id: 'work_order_task',
      action: 'UPDATE_PROVIDER_WORK_ORDER_TASK',
      response: 'data.task',
    },
    {
      url: 'maintenance/issues/:issue_id/assign_checklist',
      method: 'post',
      id: 'assign_checklist',
      action: 'ASSIGN_CHECKLIST',
      response: 'data',
    },
    {
      url: 'maintenance/issues/:issue_id/tasks/:task_id/documents',
      method: 'get',
      id: 'task_documents',
      action: 'GET_TASK_DOCUMENTS',
      response: 'data',
    },
    {
      url: 'maintenance/issues/:issue_id/tasks/:task_id/documents',
      method: 'post',
      id: 'task_document',
      action: 'UPLOAD_TASK_DOCUMENT',
      response: 'data',
    },
    {
      url: 'provider/tasks/:task_id/documents',
      method: 'get',
      id: 'task_documents',
      action: 'GET_PROVIDER_TASK_DOCUMENTS',
      response: 'data',
    },
    {
      url: 'provider/tasks/:task_id/documents',
      method: 'post',
      id: 'task_document',
      action: 'UPLOAD_PROVIDER_TASK_DOCUMENT',
      response: 'data',
    },
    {
      url: 'maintenance/equipments',
      method: 'get',
      id: 'area_equipments',
      action: 'GET_AREA_EQUIPMENTS',
      response: 'data.equipment',
    },
    {
      url: 'maintenance/equipments',
      method: 'post',
      id: 'area_equipment',
      action: 'CREATE_AREA_EQUIPMENT',
      response: 'data',
    },
    {
      url: 'maintenance/equipments/:area_id',
      method: 'get',
      id: 'area_equipment',
      action: 'GET_AREA_EQUIPMENT',
      response: 'data.equipment',
    },
    {
      url: 'maintenance/equipments/:area_id',
      method: 'put',
      id: 'area_equipment',
      action: 'UPDATE_AREA_EQUIPMENT',
      response: 'data.equipment',
    },
    {
      url: 'maintenance/equipments',
      method: 'get',
      id: 'common_area',
      action: 'GET_COMMON_AREA',
      response: 'data.equipment',
    },
    {
      url: 'maintenance/equipments',
      method: 'get',
      id: 'equipments',
      action: 'GET_EQUIPMENTS',
      response: 'data.equipment',
    },
    {
      url: 'tenants/:tenant_id/leases/:lease_id/passwords/resend',
      method: 'put',
      id: 'lease_reset',
      action: 'RESEND_LEASE_PASSWORD',
      response: 'data',
    },
    {
      url: 'integration/netsuite/customers',
      method: 'get',
      id: 'netsuite_customers',
      action: 'GET_NETSUITE_CUSTOMERS',
      response: 'data',
    },
    {
      url: 'integration/netsuite/customers/:customer_id',
      method: 'put',
      id: 'netsuite_customer',
      action: 'SYNC_NETSUITE_CUSTOMER',
      response: 'data',
    },
    {
      url: 'integration/netsuite/customer_collections/:customer_id',
      method: 'get',
      id: 'netsuite_customer_collections',
      action: 'NETSUITE_CUSTOMER_COLLECTION',
      response: 'data.customer_collection',
    },
    {
      url: 'integration/netsuite/invoices',
      method: 'get',
      id: 'netsuite_invoices',
      action: 'GET_NETSUITE_INVOICES',
      response: 'data',
    },
    {
      url: 'integration/netsuite/invoices/:invoice_id',
      method: 'put',
      id: 'netsuite_invoice',
      action: 'SYNC_NETSUITE_INVOICE',
      response: 'data',
    },
    {
      url: 'integration/netsuite/payments',
      method: 'get',
      id: 'netsuite_payments',
      action: 'GET_NETSUITE_PAYMENTS',
      response: 'data',
    },
    {
      url: 'integration/netsuite/payments/:payment_id',
      method: 'put',
      id: 'netsuite_payment',
      action: 'SYNC_NETSUITE_PAYMENT',
      response: 'data',
    },
    {
      url: 'integration/netsuite/customer_collections&search=:search_keyword',
      method: 'get',
      id: 'newsuite_list',
      action: 'GET_NETSUITE_LIST',
      response: 'data',
    },
    {
      url:
        'integration/third_parties/third_party_integrations?third_party[name]=sadad&search=:search_keyword',
      method: 'get',
      id: 'sadad_list',
      action: 'GET_SADAD_LIST',
      response: 'data',
    },
    {
      url: 'integration/third_parties/third_party_integrations',
      method: 'post',
      id: 'create_sadad_form',
      action: 'CREATE_SADAD_FORM',
      response: 'data',
    },
    {
      url: 'integration/third_parties/third_party_integrations/:sadad_id',
      method: 'put',
      id: 'update_sadad_form',
      action: 'UPDATE_SADAD_FORM',
      response: 'data',
    },
    {
      url: 'integration/third_parties/third_party_integrations/:sadad_id',
      method: 'delete',
      id: 'delete_sadad_form',
      action: 'DELETE_SADAD_INVOICE',
      response: 'data',
    },
    {
      url:
        'integration/third_parties/third_party_integrations/:sadad_id/deactivate',
      method: 'put',
      id: 'deactivate_Sadad_Form',
      action: 'DEACTIVATE_SADAD_ITEM',
      response: 'data',
    },
    {
      url:
        'integration/third_parties/third_party_integrations/:sadad_id/activate',
      method: 'put',
      id: 'activate_Sadad_Form',
      action: 'ACTIVATE_SADAD_ITEM',
      response: 'data',
    },
    {
      url:
        'integration/third_parties/third_party_integrations/:sadad_id?third_party[name]=sadad',
      method: 'get',
      id: 'sadad_item',
      action: 'GET_SADAD_ITEM',
      response: 'data',
    },
    {
      url: 'integration/sadad/payment_invoices',
      method: 'get',
      id: 'sadad_invoices',
      action: 'GET_SADAD_INVOICES',
      response: 'data',
    },
    {
      url: 'integration/sadad/accounts',
      method: 'get',
      id: 'sadad_customers_accounts',
      action: 'GET_SADAD_CUSTOMERS_ACCOUNTS',
      response: 'data',
    },
    {
      url: 'integration/sadad/accounts/:customer_id',
      method: 'put',
      id: 'sadad_customers_accounts',
      action: 'SYNC_SADAD_CUSTOMERS_ACCOUNTS',
      response: 'data',
    },
    {
      url: 'integration/sadad/payment_invoices/:invoice_id',
      method: 'put',
      id: 'sadad_invoices',
      action: 'SYNC_SADAD_INVOICE',
      response: 'data',
    },
    {
      url: 'tenants/:tenant_id/compounds',
      method: 'get',
      id: 'tenant_compounds',
      action: 'GET_TENANT_COMPOUNDS',
      response: 'data',
    },
    {
      url: 'unit_owners/:owner_id/compounds',
      method: 'get',
      id: 'owner_compounds',
      action: 'GET_OWNER_COMPOUNDS',
      response: 'data',
    },

    {
      url: 'tenants/:tenant_id/mobile_accesses',
      method: 'get',
      id: 'mobile_accesses',
      action: 'GET_TENANT_MOBILE_ACCESSES',
      response: 'data',
    },
    {
      url: 'tenants/:tenant_id/mobile_accesses',
      method: 'get',
      id: 'mobile_accesses',
      action: 'GET_TENANT_MOBILE_ACCESSES',
      response: 'data',
    },

    {
      url: 'tenants/:tenant_id/mobile_accesses',
      method: 'post',
      id: 'mobile_access',
      action: 'CREATE_TENANT_MOBILE_ACCESS',
      response: 'data',
    },
    {
      url: 'tenants/:tenant_id/mobile_accesses/:mobile_access_id',
      method: 'get',
      id: 'mobile_access',
      action: 'GET_TENANT_MOBILE_ACCESS',
      response: 'data.mobile_access',
    },
    {
      url: 'tenants/:tenant_id/mobile_accesses/:mobile_access_id',
      method: 'put',
      id: 'mobile_access',
      action: 'UPDATE_TENANT_MOBILE_ACCESS',
      response: 'data.mobile_access',
    },
    {
      url:
        'tenants/:tenant_id/mobile_accesses/:mobile_access_id/resend_password',
      method: 'put',
      id: 'resend_mobile_access',
      action: 'RESEND_PASSWORD_TENANT_MOBILE_ACCESS',
      response: 'data',
    },
    {
      url: 'acl/roles',
      method: 'get',
      id: 'roles',
      action: 'GET_ROLES',
      response: 'data',
    },
    {
      url: 'tenants/:tenant_id/units/brief',
      method: 'get',
      id: 'tenant_units',
      action: 'GET_TENANT_UNITS',
      response: 'data',
    },
    {
      url: 'tenants/:tenant_id/contracts/:contract_id/units',
      method: 'get',
      id: 'contract_units',
      action: 'GET_TENANT_CONTRACT_UNITS',
      response: 'data',
    },
    {
      url: 'tenants/:tenant_id/collections',
      method: 'get',
      id: 'tenant_collections',
      action: 'GET_TENANT_COLLECTIONS',
      response: 'data',
    },
    {
      url: 'tenants/:tenant_id/under_collections',
      method: 'get',
      id: 'tenant_under_collections',
      action: 'GET_TENANT_UNDER_COLLECTIONS',
      response: 'data',
    },
    {
      url: 'tenants/:tenant_id/under_collections/:collectionId',
      method: 'put',
      id: 'decline_collections',
      action: 'DECLINE_TENANT_UNDER_COLLECTIONS',
      response: 'data',
    },
    {
      url: 'tenants/:tenant_id/collections/:collection_id',
      method: 'get',
      id: 'tenant_collection',
      action: 'GET_TENANT_COLLECTION',
      response: 'data.collection',
    },
    {
      url: 'tenants/:tenant_id/collections/:collection_id/allocate',
      method: 'post',
      id: 'allocate_collection',
      action: 'ALLOCATE_COLLECTION',
      response: 'data',
    },
    {
      url: 'tenants/:tenant_id/quotations/:quotation_id/income_invoices',
      method: 'post',
      id: 'income_invoice',
      action: 'CREATE_INCOME_INVOICE',
      response: 'data.income_invoice',
    },
    {
      url:
        'tenants/:tenant_id/quotations/:quotation_id/income_invoices/:invoice_id',
      method: 'get',
      id: 'income_invoice',
      action: 'GET_INCOME_INVOICE',
      response: 'data.income_invoice',
    },
    {
      url:
        'tenants/:tenant_id/quotations/:quotation_id/income_invoices/:invoice_id',
      method: 'put',
      id: 'income_invoice',
      action: 'UPDATE_INCOME_INVOICE',
      response: 'data.income_invoice',
    },
    {
      url:
        'tenants/:tenant_id/quotations/:quotation_id/income_invoices/:invoice_id/approve',
      method: 'put',
      id: 'approve_income_invoice',
      action: 'APPROVE_INCOME_INVOICE',
      response: 'data.income_invoice',
    },
    {
      url:
        'tenants/:tenant_id/quotations/:quotation_id/income_invoices/:invoice_id/void',
      method: 'put',
      id: 'void_income_invoice',
      action: 'VOID_INCOME_INVOICE',
      response: 'data.income_invoice',
    },
    {
      url:
        'tenants/:tenant_id/quotations/:quotation_id/income_invoices/:invoice_id/items',
      method: 'get',
      id: 'income_invoice_items',
      action: 'GET_INCOME_INVOICE_ITEMS',
      response: 'data',
    },
    {
      url:
        'tenants/:tenant_id/quotations/:quotation_id/income_invoices/:invoice_id/items',
      method: 'post',
      id: 'income_invoice_item',
      action: 'CREATE_INCOME_INVOICE_ITEM',
      response: 'data',
    },
    {
      url:
        'tenants/:tenant_id/quotations/:quotation_id/income_invoices/:invoice_id/items/:item_id',
      method: 'put',
      id: 'income_invoice_item',
      action: 'UPDATE_INCOME_INVOICE_ITEM',
    },
    {
      url:
        'tenants/:tenant_id/quotations/:quotation_id/income_invoices/:invoice_id/items/:item_id',
      method: 'get',
      id: 'income_invoice_item',
      action: 'GET_INCOME_INVOICE_ITEM',
      response: 'data.item',
    },
    {
      url:
        'tenants/:tenant_id/quotations/:quotation_id/units/:unit_id/incremental_items',
      method: 'get',
      id: 'incremental_items',
      action: 'GET_UNIT_INCREMENTAL_ITEMS',
      response: 'data.items',
    },
    {
      url:
        'tenants/:tenant_id/quotations/:quotation_id/units/:unit_id/incremental_items/:id',
      method: 'put',
      id: 'incremental_item',
      action: 'UPDATE_UNIT_INCREMENTAL_ITEM',
      response: 'data.item',
    },
    {
      url:
        'leads/:lead_id/quotations/:quotation_id/units/:unit_id/incremental_items',
      method: 'get',
      id: 'lead_incremental_items',
      action: 'GET_LEAD_UNIT_INCREMENTAL_ITEMS',
      response: 'data.items',
    },
    {
      url:
        'leads/:lead_id/quotations/:quotation_id/units/:unit_id/incremental_items/:id',
      method: 'put',
      id: 'lead_incremental_item',
      action: 'UPDATE_LEAD_UNIT_INCREMENTAL_ITEM',
      response: 'data.item',
    },
    {
      url: 'income_categories',
      method: 'get',
      id: 'income_categories',
      action: 'GET_INCOME_CATEGORIES',
      response: 'data',
    },
    {
      url: 'income_categories/:category_id',
      method: 'get',
      id: 'income_category',
      action: 'GET_INCOME_CATEGORY',
      response: 'data.income_category',
    },
    {
      url: 'income_categories',
      method: 'post',
      id: 'income_category',
      action: 'CREATE_INCOME_CATEGORY',
      response: 'data.income_category',
    },
    {
      url: 'income_categories/:category_id',
      method: 'put',
      id: 'income_category',
      action: 'UPDATE_INCOME_CATEGORY',
      response: 'data.income_category',
    },

    {
      url: 'income_categories/:category_id/income_sub_categories',
      method: 'get',
      id: 'income_sub_categories',
      action: 'GET_INCOME_SUB_CATEGORIES',
      response: 'data',
    },
    {
      url:
        'income_categories/:category_id/income_sub_categories/:sub_category_id',
      method: 'get',
      id: 'income_sub_category',
      action: 'GET_INCOME_SUB_CATEGORY',
      response: 'data.income_sub_category',
    },
    {
      url: 'income_categories/:category_id/income_sub_categories',
      method: 'post',
      id: 'income_sub_category',
      action: 'CREATE_INCOME_SUB_CATEGORY',
      response: 'data.income_category',
    },
    {
      url:
        'income_categories/:category_id/income_sub_categories/:sub_category_id',
      method: 'put',
      id: 'income_sub_category',
      action: 'UPDATE_INCOME_SUB_CATEGORY',
      response: 'data.income_sub_category',
    },
    {
      url: 'quotations/:quotation_id/payment_terms',
      method: 'get',
      id: 'quotations_payment_terms',
      action: 'GET_QUOTATION_PAYMENT_TERMS',
      response: 'data.payment_terms',
    },
    {
      url: 'tenants/:tenant_id/contact_people',
      method: 'get',
      id: 'tenant_contact_peoples',
      action: 'GET_TENANT_CONTACT_PEOPLES',
      response: 'data',
    },
    {
      url: 'tenants/:tenant_id/contact_people/:contact_id',
      method: 'get',
      id: 'tenant_contact_people',
      action: 'GET_TENANT_CONTACT_PEOPLE',
      response: 'data.contact_person',
    },
    {
      url: 'tenants/:tenant_id/contact_people',
      method: 'post',
      id: 'tenant_contact_people',
      action: 'CREATE_TENANT_CONTACT_PEOPLES',
      response: 'data',
    },
    {
      url: 'tenants/:tenant_id/contact_people/:contact_id',
      method: 'put',
      id: 'tenant_contact_people',
      action: 'UPDATE_TENANT_CONTACT_PEOPLE',
      response: 'data',
    },
    {
      url: 'leads/:lead_id/contact_people',
      method: 'get',
      id: 'lead_contact_peoples',
      action: 'GET_LEAD_CONTACT_PEOPLES',
      response: 'data',
    },
    {
      url: 'leads/:lead_id/contact_people/:contact_id',
      method: 'get',
      id: 'lead_contact_people',
      action: 'GET_LEAD_CONTACT_PEOPLE',
      response: 'data.contact_person',
    },
    {
      url: 'leads/:lead_id/contact_people',
      method: 'post',
      id: 'lead_contact_people',
      action: 'CREATE_LEAD_CONTACT_PEOPLES',
      response: 'data.contact_person',
    },
    {
      url: 'leads/:lead_id/contact_people/:contact_id',
      method: 'put',
      id: 'lead_contact_people',
      action: 'UPDATE_LEAD_CONTACT_PEOPLE',
      response: 'data.contact_person',
    },
    {
      url: 'compounds/:compound_id/contracting_details',
      method: 'get',
      id: 'contracting_details',
      action: 'GET_CONTRACTING_DETAILS',
      response: 'data.contracting_detail',
    },
    {
      url: 'compounds/:compound_id/contracting_details',
      method: 'post',
      id: 'contracting_details',
      action: 'CREATE_CONTRACTING_DETAILS',
      response: 'data.contracting_detail',
    },
    {
      url: 'compounds/:compound_id/contracting_details',
      method: 'put',
      id: 'contracting_details',
      action: 'UPDATE_CONTRACTING_DETAILS',
      response: 'data.contracting_detail',
    },
    {
      url: 'compounds/:compound_id/payment_terms',
      method: 'get',
      id: 'compound_payment_terms',
      action: 'GET_COMPOUND_PAYMENT_TERMS',
      response: 'data.compound',
    },
    {
      url: 'compounds/:compound_id/payment_terms',
      method: 'put',
      id: 'update_compound_payment_terms',
      action: 'PUT_COMPOUND_PAYMENT_TERMS',
      response: 'data',
    },
    {
      url: 'tenants/:tenant_id/quotations/:quotation_id/assignable_templates',
      method: 'get',
      id: 'assignable_templates',
      action: 'GET_ASSIGNABLE_TEMPLATES',
      response: 'data',
    },
    {
      url: 'leads/:lead_id/quotations/:quotation_id/assignable_templates',
      method: 'get',
      id: 'assignable_templates',
      action: 'GET_LEAD_ASSIGNABLE_TEMPLATES',
      response: 'data',
    },
    {
      url: 'templates/:template_id/duplicate',
      method: 'post',
      id: 'duplicate_template',
      action: 'DUPLICATE_TEMPLATE',
      response: 'data',
    },
    {
      url: 'leads/:lead_id/finalize',
      method: 'put',
      id: 'lead_quotation_finalize',
      action: 'FINALIZE_LEAD_QUOTATION',
      response: 'data',
    },
    {
      url: 'leads/:lead_id/business_types',
      method: 'get',
      id: 'lead_business_types',
      action: 'GET_LEAD_BUSINESS_TYPES',
      response: 'data',
    },
    {
      url: 'leads/:lead_id/business_types',
      method: 'post',
      id: 'lead_business_type',
      action: 'CREATE_LEAD_BUSINESS_TYPE',
      response: 'data',
    },
    {
      url: 'leads/:lead_id/trade_names',
      method: 'get',
      id: 'lead_trade_names',
      action: 'GET_LEAD_TRADE_NAMES',
      response: 'data',
    },
    {
      url: 'leads/:lead_id/trade_names/:id',
      method: 'get',
      id: 'lead_trade_name',
      action: 'GET_LEAD_TRADE_NAME',
      response: 'data.trade_name',
    },
    {
      url: 'leads/:lead_id/trade_names',
      method: 'post',
      id: 'lead_trade_name',
      action: 'CREATE_LEAD_TRADE_NAME',
      response: 'data.trade_name',
    },
    {
      url: 'leads/:lead_id/quotations/trade_names',
      method: 'post',
      id: 'lead_quotations_trade_name',
      action: 'CREATE_LEAD_QUOTATIONS_TRADE_NAME',
      response: 'data.trade_name',
    },
    {
      url: 'leads/:lead_id/trade_names/:id',
      method: 'put',
      id: 'lead_trade_name',
      action: 'UPDATE_LEAD_TRADE_NAME',
      response: 'data.trade_name',
    },
    {
      url: 'tenants/:tenant_id/business_types',
      method: 'get',
      id: 'tenant_business_types',
      action: 'GET_TENANT_BUSINESS_TYPES',
      response: 'data',
    },
    {
      url: 'tenants/:tenant_id/business_types',
      method: 'post',
      id: 'tenant_business_type',
      action: 'CREATE_TENANT_BUSINESS_TYPE',
      response: 'data',
    },
    {
      url: 'tenants/:tenant_id/trade_names',
      method: 'get',
      id: 'tenant_trade_names',
      action: 'GET_TENANT_TRADE_NAMES',
      response: 'data',
    },
    {
      url: 'tenants/:tenant_id/trade_names/:id',
      method: 'get',
      id: 'tenant_trade_name',
      action: 'GET_TENANT_TRADE_NAME',
      response: 'data.trade_name',
    },
    {
      url: 'tenants/:tenant_id/trade_names',
      method: 'post',
      id: 'tenant_trade_name',
      action: 'CREATE_TENANT_TRADE_NAME',
      response: 'data.trade_name',
    },
    {
      url: 'tenants/:tenant_id/quotations/trade_names',
      method: 'post',
      id: 'tenant_quotations_trade_name',
      action: 'CREATE_TENANT_QUOTATIONS_TRADE_NAME',
      response: 'data.trade_name',
    },
    {
      url: 'tenants/:tenant_id/trade_names/:id',
      method: 'put',
      id: 'tenant_trade_name',
      action: 'UPDATE_TENANT_TRADE_NAME',
      response: 'data.trade_name',
    },
    {
      url: 'contracts/:contract_id/attachments',
      method: 'get',
      id: 'contract_attachments',
      action: 'GET_CONTRACT_ATTACHMENTS',
      response: 'data.attachments',
    },
    {
      url: 'contracts/:contract_id/attachments',
      method: 'post',
      id: 'contract_attachment',
      action: 'UPLOAD_CONTRACT_ATTACHMENT',
      response: 'data.attachment',
    },
    {
      url: 'contracts/:contract_id/notes',
      method: 'get',
      id: 'contract_notes',
      action: 'GET_CONTRACT_NOTES',
      response: 'data.notes',
    },
    {
      url: 'contracts/:contract_id/notes',
      method: 'post',
      id: 'contract_note',
      action: 'CREATE_CONTRACT_NOTE',
      response: 'data.note',
    },
    {
      url: 'contracts/:contract_id/units',
      method: 'get',
      id: 'contract_units',
      action: 'GET_CONTRACT_UNITS',
      response: 'data',
    },
    {
      url: 'contracts/:contract_id/invoices',
      method: 'get',
      id: 'contract_invoices',
      action: 'GET_CONTRACT_INVOICES',
      response: 'data',
    },
    {
      url: 'contracts/:contract_id/leases/finance',
      method: 'get',
      id: 'contract_lease_units',
      action: 'GET_CONTRACT_LEASE_UNITS',
      response: 'data',
    },
    {
      url: 'contracts/:contract_id/leases',
      method: 'get',
      id: 'contract_lease',
      action: 'GET_CONTRACT_LEASES',
      response: 'data',
    },
    {
      url: 'tenants/:tenant_id/contracts/:contract_id/signature_status',
      method: 'put',
      id: 'contract_signature_status',
      action: 'UPDATE_CONTRACT_SIGNATURE_STATUS',
      response: 'data',
    },
    {
      url: 'tenants/:tenant_id/contracts/:contract_id/security_deposit',
      method: 'put',
      id: 'security_deposit_update',
      action: 'UPDATE_SECURITY_DEPOSIT',
      response: 'data',
    },
    {
      url: 'leasing/statistics',
      method: 'get',
      id: 'leasing_statistics',
      action: 'GET_LEASING_STATISTICS',
      response: 'data.statistics',
    },
    {
      url: 'contracts/:contract_id',
      method: 'get',
      id: 'contract',
      action: 'GET_CONTRACT',
      response: 'data.contract',
    },
    {
      url: 'workflow/quotation/configs',
      method: 'get',
      id: 'quotation_workflow_configs',
      action: 'GET_QUOTATION_WORKFLOW_CONFIGS',
      response: 'data',
    },
    {
      url: 'workflow/quotation/configs/:id',
      method: 'get',
      id: 'quotation_workflow_config',
      action: 'GET_QUOTATION_WORKFLOW_CONFIG',
      response: 'data.quotation_workflow_config',
    },
    {
      url: 'workflow/quotation/configs',
      method: 'post',
      id: 'quotation_workflow_config',
      action: 'CREATE_QUOTATION_WORKFLOW_CONFIG',
      response: 'data',
    },
    {
      url: 'workflow/quotation/configs/:id',
      method: 'put',
      id: 'quotation_workflow_config',
      action: 'UPDATE_QUOTATION_WORKFLOW_CONFIG',
      response: 'data',
    },
    {
      url: 'workflow/contract_discount/configs',
      method: 'get',
      id: 'contract_discount_workflow_configs',
      action: 'GET_CONTRACT_DISCOUNT_WORKFLOW_CONFIGS',
      response: 'data',
    },
    {
      url: 'workflow/contract_discount/configs/:id',
      method: 'get',
      id: 'contract_discount_workflow_config',
      action: 'GET_CONTRACT_DISCOUNT_WORKFLOW_CONFIG',
      response: 'data.contract_discount_workflow_config',
    },
    {
      url: 'workflow/contract_discount/configs/:id',
      method: 'put',
      id: 'contract_discount_workflow_config',
      action: 'UPDATE_CONTRACT_DISCOUNT_WORKFLOW_CONFIG',
      response: 'data',
    },
    {
      url: 'workflow/contract_discount/configs',
      method: 'post',
      id: 'contract_discount_workflow_config',
      action: 'CREATE_CONTRACT_DISCOUNT_WORKFLOW_CONFIG',
      response: 'data',
    },
    {
      url: 'workflow/quotation_discount/configs',
      method: 'get',
      id: 'quotation_discount_workflow_configs',
      action: 'GET_QUOTATION_DISCOUNT_WORKFLOW_CONFIGS',
      response: 'data',
    },
    {
      url: 'workflow/quotation_discount/configs/:id',
      method: 'get',
      id: 'quotation_discount_workflow_config',
      action: 'GET_QUOTATION_DISCOUNT_WORKFLOW_CONFIG',
      response: 'data.quotation_discount_workflow_config',
    },
    {
      url: 'workflow/quotation_discount/configs/:id',
      method: 'put',
      id: 'quotation_discount_workflow_config',
      action: 'UPDATE_QUOTATION_DISCOUNT_WORKFLOW_CONFIG',
      response: 'data',
    },
    {
      url: 'workflow/quotation_discount/configs',
      method: 'post',
      id: 'quotation_discount_workflow_config',
      action: 'CREATE_QUOTATION_DISCOUNT_WORKFLOW_CONFIG',
      response: 'data',
    },
    {
      url: 'workflow/deal/configs',
      method: 'get',
      id: 'deal_workflow_configs',
      action: 'GET_DEAL_WORKFLOW_CONFIGS',
      response: 'data',
    },
    {
      url: 'workflow/deal/configs/:id',
      method: 'get',
      id: 'deal_workflow_config',
      action: 'GET_DEAL_WORKFLOW_CONFIG',
      response: 'data.deal_workflow_config',
    },
    {
      url: 'workflow/deal/configs/:id',
      method: 'put',
      id: 'deal_workflow_config',
      action: 'UPDATE_DEAL_WORKFLOW_CONFIG',
      response: 'data',
    },
    {
      url: 'workflow/deal/configs',
      method: 'post',
      id: 'deal_workflow_config',
      action: 'CREATE_DEAL_WORKFLOW_CONFIG',
      response: 'data',
    },
    {
      url: 'workflow/deal/rejection_reasons',
      method: 'get',
      id: 'deal_rejection_reasons',
      action: 'GET_DEAL_REJECTION_REASONS',
      response: 'data',
    },
    {
      url: 'workflow/deal/rejection_reasons/:id',
      method: 'put',
      id: 'deal_rejection_reason',
      action: 'UPDATE_DEAL_REJECTION_REASON',
      response: 'data',
    },
    {
      url: 'workflow/deal/rejection_reasons',
      method: 'post',
      id: 'deal_rejection_reason',
      action: 'CREATE_DEAL_REJECTION_REASON',
      response: 'data',
    },
    {
      url: 'workflow/quotation_discount/rejection_reasons',
      method: 'get',
      id: 'quotation_discount_rejection_reasons',
      action: 'GET_QUOTATION_DISCOUNT_REJECTION_REASONS',
      response: 'data',
    },
    {
      url: 'workflow/quotation_discount/rejection_reasons/:id',
      method: 'put',
      id: 'quotation_discount_rejection_reason',
      action: 'UPDATE_QUOTATION_DISCOUNT_REJECTION_REASON',
      response: 'data',
    },
    {
      url: 'workflow/quotation_discount/rejection_reasons',
      method: 'post',
      id: 'quotation_discount_rejection_reason',
      action: 'CREATE_QUOTATION_DISCOUNT_REJECTION_REASON',
      response: 'data',
    },
    {
      url: 'workflow/contract_discount/rejection_reasons',
      method: 'get',
      id: 'contract_discount_rejection_reasons',
      action: 'GET_CONTRACT_DISCOUNT_REJECTION_REASONS',
      response: 'data',
    },
    {
      url: 'workflow/contract_discount/rejection_reasons/:id',
      method: 'put',
      id: 'contract_discount_rejection_reason',
      action: 'UPDATE_CONTRACT_DISCOUNT_REJECTION_REASON',
      response: 'data',
    },
    {
      url: 'workflow/contract_discount/rejection_reasons',
      method: 'post',
      id: 'contract_discount_rejection_reason',
      action: 'CREATE_CONTRACT_DISCOUNT_REJECTION_REASON',
      response: 'data',
    },
    {
      url: 'contract_discount_reasons',
      method: 'get',
      id: 'contract_discount_request_reasons',
      action: 'GET_CONTRACT_DISCOUNT_REQUEST_REASONS',
      response: 'data',
    },
    {
      url: 'contract_discount_reasons/:id',
      method: 'put',
      id: 'contract_discount_request_reason',
      action: 'UPDATE_CONTRACT_DISCOUNT_REQUEST_REASON',
      response: 'data',
    },
    {
      url: 'contract_discount_reasons',
      method: 'post',
      id: 'contract_discount_request_reason',
      action: 'CREATE_CONTRACT_DISCOUNT_REQUEST_REASON',
      response: 'data',
    },
    {
      url: 'workflow/quotation/rejection_reasons',
      method: 'get',
      id: 'quotation_rejection_reasons',
      action: 'GET_QUOTATION_REJECTION_REASONS',
      response: 'data',
    },
    {
      url: 'workflow/quotation/rejection_reasons/:id',
      method: 'put',
      id: 'quotation_rejection_reason',
      action: 'UPDATE_QUOTATION_REJECTION_REASON',
      response: 'data',
    },
    {
      url: 'workflow/quotation/rejection_reasons',
      method: 'post',
      id: 'quotation_rejection_reason',
      action: 'CREATE_QUOTATION_REJECTION_REASON',
      response: 'data',
    },
    {
      url: 'workflow/assignees/config_assignees',
      method: 'get',
      id: 'config_assignees',
      action: 'GET_CONFIG_ASSIGNEES',
      response: 'data.assignees',
    },
    {
      url: 'workflow/assignees/step_assignees',
      method: 'get',
      id: 'step_assignees',
      action: 'GET_STEP_ASSIGNEES',
      response: 'data.assignees',
    },
    {
      url: 'quotations/:quotation_id/request_for_approval',
      method: 'put',
      id: 'approve_quotation',
      action: 'APPROVE_TENANT_QUOTATION',
      response: 'data',
    },
    {
      url: 'workflow/executions',
      method: 'get',
      id: 'workflow_requests',
      action: 'GET_WORKFLOW_REQUESTS',
      response: 'data',
    },
    {
      url: 'workflow/executions/:request_id',
      method: 'get',
      id: 'workflow_request',
      action: 'GET_WORKFLOW_REQUEST',
      response: 'data.execution',
    },
    {
      url: 'workflow/decisions/:decision_id/approve',
      method: 'put',
      id: 'approve_workflow_request',
      action: 'APPROVE_WORKFLOW_REQUEST',
      response: 'data',
    },
    {
      url: 'workflow/decisions/:decision_id/reject',
      method: 'put',
      id: 'reject_workflow_request',
      action: 'REJECT_WORKFLOW_REQUEST',
      response: 'data',
    },
    {
      url: 'contracts/:contract_id/contract_discounts',
      method: 'post',
      id: 'create_contract_discounts',
      action: 'CREATE_CONTRACT_DISCOUNT_REQUEST',
      response: 'data',
    },
    {
      url: 'contracts/:contract_id/contract_discounts/:contract_discount_id',
      method: 'get',
      id: 'contract_discount',
      action: 'GET_CONTRACT_DISCOUNT_REQUEST',
      response: 'data.contract_discount',
    },
    {
      url: 'workflow/executions/:request_id/comments',
      method: 'post',
      id: 'add_workflow_request_comment',
      action: 'ADD_WORKFLOW_REQUEST_COMMENT',
      response: 'data',
    },
    {
      url: 'workflow/pending_executions',
      method: 'get',
      id: 'workflow_requests',
      action: 'GET_WORKFLOW_PENDING_EXECUTIONS',
      response: 'data',
    },
    {
      url: 'workflow/pending_executions/:execution_id',
      method: 'get',
      id: 'workflow_request',
      action: 'GET_WORKFLOW_PENDING_EXECUTION',
      response: 'data.pending_execution',
    },
    {
      url: 'workflow/associated_executions',
      method: 'get',
      id: 'workflow_requests',
      action: 'GET_WORKFLOW_ASSOCIATED_EXECUTIONS',
      response: 'data',
    },
    {
      url: 'workflow/associated_executions/:execution_id',
      method: 'get',
      id: 'workflow_request',
      action: 'GET_WORKFLOW_ASSOCIATED_EXECUTION',
      response: 'data.associated_execution',
    },
    {
      url:
        'contracts/:contract_id/contract_discounts/:contract_discount_id/cancel',
      method: 'delete',
      id: 'cancel_contract_discount',
      action: 'CANCEL_CONTRACT_DISCOUNT',
      response: 'data',
    },
    {
      url: 'contracts/brief',
      method: 'get',
      id: 'dropdown_contracts',
      action: 'GET_DROPDOWN_CONTRACTS',
      response: 'data',
    },
    {
      url: 'tenants/:tenant_id/contracts/brief',
      method: 'get',
      id: 'dropdown_contracts',
      action: 'GET_DROPDOWN_TENANTS_CONTRACTS',
      response: 'data',
    },
    {
      url: 'contract_discount/contracts/:contract_id',
      method: 'get',
      id: 'contract',
      action: 'GET_WORKFLOW_CONTRACT',
      response: 'data.contract',
    },
    {
      url: 'contracts/:contract_id/year_rent_amount',
      method: 'get',
      id: 'year_rent_amount',
      action: 'GET_WORKFLOW_RENT_AMOUNT',
      response: 'data.year_rent_amount',
      onPending: 'reset',
    },
    {
      url: 'workflow/configs/applicable_configs',
      method: 'get',
      id: 'workflow_applicable_config',
      action: 'GET_WORKFLOW_APPLICABLE_CONFIG',
      response: 'data',
    },
    /**********GET Visits List Start***********/
    {
      url: 'visit/visits',
      method: 'get',
      id: 'get_visits',
      action: 'GET_VISITS',
      response: 'data',
    },
    {
      url: 'visit/visits',
      method: 'post',
      id: 'visit_item',
      action: 'CREATE_VISIT',
      response: 'data',
    },
    {
      url: 'visit/visits/:id',
      method: 'get',
      id: 'visit_item',
      action: 'GET_VISIT_ITEM',
      response: 'data.visit',
    },
    {
      url: 'visit/visits/:id',
      method: 'put',
      id: 'visit_item',
      action: 'UPDATE_VISIT_ITEM',
      response: 'data',
    },
    {
      url: 'visit/visits/:item_id/comments',
      method: 'post',
      id: 'visit_item',
      action: 'ADD_COMMENT',
      response: 'data',
    },
    {
      url: 'visit/visitors/:item_id/ban_visitor',
      method: 'put',
      id: 'ban_item',
      action: 'ADD_BAN',
      response: 'data',
    },
    {
      url: 'visit/visitors/:item_id/unban_visitor',
      method: 'put',
      id: 'unban_item',
      action: 'UN_BAN',
      response: 'data',
    },
    {
      url: 'visit/visitors',
      method: 'get',
      id: 'get_visitors',
      action: 'GET_VISITORS',
      response: 'data',
    },

    /**********GET Visits List End***********/
    /*****Security***/
    {
      url: 'security_providers',
      method: 'post',
      id: 'security_item',
      action: 'CREATE_SECURITY',
      response: 'data',
    },
    {
      url: 'security_providers',
      method: 'get',
      id: 'security_item',
      action: 'GET_SECURITY_LIST',
      response: 'data',
    },
    {
      url: 'security_providers/:item_id',
      method: 'get',
      id: 'security_item',
      action: 'GET_SECURITY_ITEM',
      response: 'data',
    },
    {
      url: 'security_providers/:item_id',
      method: 'put',
      id: 'security_item',
      action: 'UPDATE_SECURITY_ITEM',
      response: 'data',
    },
    {
      url: 'visit/visitors/:id',
      method: 'get',
      id: 'visitor_item',
      action: 'GET_VISITOR_ITEM',
      response: 'data.visitor',
    },
    /*****Post Categories***/
    {
      url: 'post/categories',
      method: 'post',
      id: 'post_category_item',
      action: 'CREATE_POST_CATEGORY',
      response: 'data',
    },
    {
      url: 'post/categories/:item_id',
      method: 'get',
      id: 'category_item',
      action: 'GET_CATEGORY_ITEM',
      response: 'data',
    },

    {
      url: 'post/categories/:item_id',
      method: 'put',
      id: 'category_item',
      action: 'UPDATE_CATEGORY_ITEM',
      response: 'data',
    },

    /*****LEASING VISIT***/
    {
      url: 'leasing_users/',
      method: 'get',
      id: 'leasing_list_users',
      action: 'GET_LEASING_USERS',
      response: 'data.leasing_user',
    },

    {
      url: 'leasing_users/:user_id',
      method: 'get',
      id: 'leasing_list_user',
      action: 'GET_LEASING_USER',
      response: 'data.leasing_user',
    },

    {
      url: 'leasing_users/',
      method: 'post',
      id: 'leasing_list_users',
      action: 'CREATE_LEASING_USER',
      response: 'data',
    },
    {
      url: 'leasing_users/:user_id',
      method: 'put',
      id: 'leasing_list_users',
      action: 'UPDATE_LEASING_USER',
      response: 'data.leasing_user',
    },

    /*****SERVICE VISIT***/
    {
      url: 'visit/categories',
      method: 'post',
      id: 'service_visit_item',
      action: 'CREATE_SERVICE_VISIT',
      response: 'data',
    },
    {
      url: 'visit/categories',
      method: 'get',
      id: 'service_visit_item',
      action: 'GET_SERVICE_LIST',
      response: 'data',
    },
    {
      url: 'visit/categories/:item_id',
      method: 'put',
      id: 'service_visit_item',
      action: 'UPDATE_SERVICE_ITEM',
      response: 'data',
    },
    {
      url: 'visit/categories/:item_id',
      method: 'get',
      id: 'service_visit_item',
      action: 'GET_SERVICE_ITEM',
      response: 'data',
    },
    /**********SERVICE PROVIDER*******************/
    {
      url: 'visit/recurring_visits',
      method: 'post',
      id: 'service_provider_visit_item',
      action: 'CREATE_SERVICE_PROVIDER_VISIT',
      response: 'data',
    },
    {
      url: 'visit/recurring_visits',
      method: 'get',
      id: 'service_provider_visit_item',
      action: 'GET_VISIT_SERVICE_PROVIDER_LIST',
      response: 'data',
    },

    {
      url: 'visit/recurring_visits/:item_id',
      method: 'get',
      id: 'service_provider_visit_item',
      action: 'GET_VISIT_SERVICE_PROVIDER_ITEM',
      response: 'data',
    },
    /*******amenities tab******** */
    {
      url: 'amenities',
      method: 'post',
      id: 'amenities_tab',
      action: 'CREATE_Amenities',
      response: 'data',
    },
    {
      url: 'amenities',
      method: 'get',
      id: 'amenities_tab',
      action: 'GET_AMENITIES_LIST',
      response: 'data',
    },
    {
      url: 'amenities/:item_id',
      method: 'put',
      id: 'amenities_tab_item',
      action: 'UPDATE_AMENITY_ITEM',
      response: 'data',
    },
    {
      url: 'amenities/:item_id',
      method: 'get',
      id: 'amenities_tab_item',
      action: 'GET_AMENITY_ITEM',
      response: 'data',
    },
    /*******Operations tab******** */
    {
      url: 'operation_members',
      method: 'post',
      id: 'operation_tab',
      action: 'CREATE_OPERATIONS_MEMBERS',
      response: 'data',
    },
    {
      url: 'operation_members',
      method: 'get',
      id: 'operation_tab',
      action: 'GET_OPERATIONS_MEMBERS_LIST',
      response: 'data',
    },
    {
      url: 'operation_members/:item_id',
      method: 'get',
      id: 'operation_tab_item',
      action: 'GET_OPERATIONS_MEMBERS_ITEM',
      response: 'data',
    },
    {
      url: 'operation_members/:item_id',
      method: 'put',
      id: 'operation_tab_item',
      action: 'UPDATE_OPERATIONS_MEMBERS_ITEM',
      response: 'data',
    },
    {
      url: 'pre_occupancy_orders/:item_id',
      method: 'get',
      id: 'pre_occupancy_item',
      action: 'GET_PRE_OCCUPANCY_ORDER_DETAILS',
      response: 'data',
    },
    {
      url: 'pre_occupancy_orders/:item_id',
      method: 'put',
      id: 'pre_occupancy_unit_readness_date',
      action: 'SET_UNIT_READNESS_DATE',
      response: 'data.pre_occupancy_order',
    },
    {
      url: 'pre_occupancy_orders/get_orders_numbers',
      method: 'get',
      id: 'get_orders_numbers',
      action: 'GET_PREOCCUPANCY_ORDER_NUMBERS',
      response: 'data',
    },
    {
      url: 'operations/leasing_orders/:item_id/amenities',
      method: 'get',
      id: 'leasing_amenities_list',
      action: 'GET_AMENITIES_LEASING_LIST',
      response: 'data',
    },
    {
      url: 'operations/leasing_orders/:item_id/amenities/:amenity_id',
      method: 'get',
      id: 'leasing_amenities_list_details',
      action: 'GET_AMENITIES_LEASING_ITEM_DETAILS',
      response: 'data',
    },
    /******moving out */
    {
      url: 'operations/leasing_clearances/:item_id/amenities/moving_out_list',
      method: 'get',
      id: 'leasing_amenities_list_moving_out',
      action: 'GET_AMENITIES_LIST_MOVING_OUT',
      response: 'data',
    },
    {
      url:
        'operations/leasing_clearances/:item_id/amenities/:amenity_id/moving_out_show',
      method: 'get',
      id: 'leasing_amenities_list_details_moving_out',
      action: 'GET_AMENITIES_LEASING_ITEM_DETAILS_MOVING_OUT',
      response: 'data',
    },
    {
      url: 'leasing_clearances/:item_id/notes',
      method: 'post',
      id: 'moving_out_notes',
      action: 'CREATE_NOTES_MOVING_OUT',
      response: 'data',
    },
    {
      url: 'operations/leasing_clearances/:item_id/damaged_items/:damaged_id',
      method: 'get',
      id: 'damaged_items',
      action: 'GET_DAMAGED_ITEM',
      response: 'data.damaged_item',
    },
    {
      url: 'leasing_clearances/:item_id/notes',
      method: 'get',
      id: 'moving_out_notes_list',
      action: 'GET_NOTES_MOVING_OUT',
      response: 'data',
    },
    {
      url: 'operations/leasing_clearances/:item_id/damaged_items',
      method: 'get',
      id: 'damaged_list',
      action: 'GET_DAMAGED_LIST',
      response: 'data',
    },
    // {
    //   url: 'operations/leasing_clearances/:item_id/damaged_items/:damaged_id',
    //   method: 'get',
    //   id: 'damaged_item',
    //   action: 'GET_DAMAGED_ITEM',
    //   response: 'data',
    // },

    {
      url: 'operations/leasing_orders/:item_id/inventory_list',
      method: 'get',
      id: 'inventory_list',
      action: 'GET_INVENTORY_LIST',
      response: 'data',
    },
    {
      url: 'operations/leasing_orders/:item_id/inventory_details/:inventory_id',
      method: 'get',
      id: 'inventory_item',
      action: 'GET_INVENTORY_ITEM_DETAILS',
      response: 'data',
    },
    /***Unit Owners****/
    {
      url: 'unit_owners',
      method: 'post',
      id: 'create_owner',
      action: 'CREATE_UNIT_OWNER',
      response: 'data',
    },
    {
      url: 'unit_owners',
      method: 'get',
      id: 'get_owner',
      action: 'GET_UNIT_OWNER',
      response: 'data',
    },
    {
      url: 'unit_owners/:item_id',
      method: 'get',
      id: 'get_owner_item',
      action: 'GET_UNIT_OWNER_ITEM',
      response: 'data',
    },
    {
      url: 'unit_owners/:item_id',
      method: 'put',
      id: 'update_owner_item',
      action: 'UPDATE_UNIT_OWNER_ITEM',
      response: 'data',
    },
    {
      url: 'unit_owners/:owner_id/sellable_units',
      method: 'get',
      id: 'owner_units',
      action: 'GET_OWNERS_UNITS',
      response: 'data',
    },
    /*****salable units */
    {
      url: 'sellable_units',
      method: 'post',
      id: 'salable_unit',
      action: 'CREATE_SALABLE_UNIT',
      response: 'data.unit',
    },
    {
      url: 'sellable_units',
      method: 'get',
      id: 'salable_unit_list',
      action: 'GET_SALABLE_UNITS',
      response: 'data.unit',
    },

    {
      url: 'sellable_units/:salable_id',
      method: 'get',
      id: 'get_salable_item',
      action: 'GET_SALABLE_UNIT_ITEM',
      response: 'data',
    },
    {
      url: 'sellable_units/:salable_id',
      method: 'put',
      id: 'update_salable_item',
      action: 'UPDATE_SALABLE_UNIT',
      response: 'data',
    },
    /****Owner Mobile Access***/

    {
      url: 'tenants/:owner_id/mobile_accesses',
      method: 'get',
      id: 'owner_mobile_accesses',
      action: 'GET_OWNER_MOBILE_ACCESS_LIST',
      response: 'data',
    },
    {
      url: 'tenants/:owner_id/mobile_accesses',
      method: 'post',
      id: 'owner_mobile_access',
      action: 'CREATE_OWNER_MOBILE_ACCESS',
      response: 'data',
    },

    {
      url: 'tenants/:owner_id/mobile_accesses/:mobile_access_id',
      method: 'get',
      id: 'owner_mobile_accesses_item',
      action: 'GET_OWNER_MOBILE_ACCESSES',
      response: 'data',
    },
    {
      url: 'tenants/:owner_id/mobile_accesses/:mobile_access_id',
      method: 'put',
      id: 'edit_owner_mobile_access',
      action: 'UPDATE_OWNER_MOBILE_ACCESS',
      response: 'data',
    },
    {
      url: 'contact_us/forms/:item_id/close_ticket',
      method: 'put',
      id: 'close_ticket',
      action: 'CLOSE_TICKET',
      response: 'data',
    },
    {
      url: 'acl/roles/sales_roles',
      method: 'get',
      id: 'sales_roles',
      action: 'GET_SALES_ROLES',
      response: 'data',
    },
    {
      url: 'compounds/has_no_manager',
      method: 'get',
      id: 'compounds_has_no_manager',
      action: 'GET_COMPOUNDS_HAS_NO_MANAGERS',
      response: 'data',
    },
    {
      url: 'sales',
      method: 'post',
      id: 'create_sales_member',
      action: 'CREATE_SALES_MEMBER',
      response: 'data',
    },
    {
      url: 'sales',
      method: 'get',
      id: 'get_sales',
      action: 'GET_SALES',
      response: 'data',
    },
    {
      url: 'sales',
      method: 'get',
      id: 'get_assigned_sales',
      action: 'GET_ASSIGNED_SALES',
      response: 'data',
    },
    {
      url: 'sales/:sales_user_id',
      method: 'get',
      id: 'sales_item',
      action: 'GET_SALES_ITEM',
      response: 'data',
    },
    {
      url: 'sales/:sales_member_id/get_sales_members',
      method: 'get',
      id: 'sales_member_item',
      action: 'GET_SALES_MEMBER_FOR_SUPERVISOR',
      response: 'data',
    },

    {
      url: 'sales/:item_id',
      method: 'put',
      id: 'update_sales_member',
      action: 'UPDATE_SALE_MEMBER',
      response: 'data',
    },
  ],
}

export default config
