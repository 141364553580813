import { callApi } from '../utils/apiUtils'

export const COMPOUNDS_LOAD_REQUEST = 'COMPOUNDS_LOAD_REQUEST'
export const COMPOUNDS_LOAD_SUCCESS = 'COMPOUNDS_LOAD_SUCCESS'
export const COMPOUNDS_LOAD_FAILURE = 'COMPOUNDS_LOAD_FAILURE'
export const COMPOUND_LOAD_REQUEST = 'COMPOUND_LOAD_REQUEST'
export const COMPOUND_LOAD_SUCCESS = 'COMPOUND_LOAD_SUCCESS'
export const COMPOUND_LOAD_FAILURE = 'COMPOUND_LOAD_FAILURE'

export const COMPOUND_EDIT_START = 'COMPOUND_EDIT_START'
export const COMPOUND_EDIT_CANCEL = 'COMPOUND_EDIT_CANCEL'
export const COMPOUND_SAVE_REQUEST = 'COMPOUND_SAVE_REQUEST'
export const COMPOUND_SAVE_SUCCESS = 'COMPOUND_SAVE_SUCCESS'
export const COMPOUND_SAVE_FAILURE = 'COMPOUND_SAVE_FAILURE'
export const COMPOUND_CREATE_SUCCESS = 'COMPOUND_CREATE_SUCCESS'
export const COMPOUND_DEL_REQUEST = 'COMPOUND_DEL_REQUEST'
export const COMPOUND_DEL_SUCCESS = 'COMPOUND_DEL_SUCCESS'
export const COMPOUND_DEL_FAILURE = 'COMPOUND_DEL_FAILURE'

export const COMPOUND_HISTORY_LOAD_REQUEST = 'COMPOUND_HISTORY_LOAD_REQUEST'
export const COMPOUND_HISTORY_LOAD_SUCCESS = 'COMPOUND_HISTORY_LOAD_SUCCESS'
export const COMPOUND_HISTORY_LOAD_FAILURE = 'COMPOUND_HISTORY_LOAD_FAILURE'

function compoundsLoadRequest() {
  return {
    type: COMPOUNDS_LOAD_REQUEST,
  }
}

function compoundsLoadSuccess(response) {
  const compounds = response.data.compounds
  return {
    type: COMPOUNDS_LOAD_SUCCESS,
    compounds: compounds,
  }
}

function compoundsLoadFailure(error) {
  return {
    type: COMPOUNDS_LOAD_FAILURE,
    error: error,
  }
}

function compoundLoadRequest() {
  return {
    type: COMPOUND_LOAD_REQUEST,
  }
}

function compoundLoadSuccess(response) {
  const compound = response.data.compound
  return {
    type: COMPOUND_LOAD_SUCCESS,
    compound: compound,
  }
}

function compoundLoadFailure(error) {
  return {
    type: COMPOUND_LOAD_FAILURE,
    error: error,
  }
}

function compoundSaveRequest() {
  return {
    type: COMPOUND_SAVE_REQUEST,
  }
}

function compoundSaveSuccess(response) {
  const compound = response.data.compound
  return {
    type: COMPOUND_SAVE_SUCCESS,
    compound: compound,
    id: compound.id,
  }
}

function compoundCreateSuccess(response) {
  const compound = response.data.compound
  return {
    type: COMPOUND_CREATE_SUCCESS,
    compound: compound,
    id: 'New',
  }
}

function compoundSaveFailure(error) {
  return {
    type: COMPOUND_SAVE_FAILURE,
    error: error.error,
  }
}

function compoundDelRequest() {
  return {
    type: COMPOUND_DEL_REQUEST,
  }
}

function compoundDelSuccess(response) {
  const compound = response.data.compound
  return {
    type: COMPOUND_DEL_SUCCESS,
    compound: compound,
  }
}

function compoundDelFailure(error) {
  return {
    type: COMPOUND_DEL_FAILURE,
    error: error,
  }
}

function compoundHistoryLoadRequest() {
  return {
    type: COMPOUND_HISTORY_LOAD_REQUEST,
  }
}

function compoundHistoryLoadSuccess(response) {
  const compound_history = response.data.compound_history
  return {
    type: COMPOUND_HISTORY_LOAD_SUCCESS,
    history: compound_history,
  }
}

function compoundHistoryLoadFailure(error) {
  return {
    type: COMPOUND_HISTORY_LOAD_FAILURE,
    error: error,
  }
}

export function compoundEdit(values) {
  return {
    type: COMPOUND_EDIT_START,
    id: values.id,
    action_type: values.action_type,
  }
}

export function compoundEditCancel() {
  return {
    type: COMPOUND_EDIT_CANCEL,
  }
}

export function compoundSave(data) {
  const id = data.id || ''
  if (id === '') {
    const config = {
      method: 'post',
      body: JSON.stringify({ compound: data }),
    }
    return callApi(
      '/compounds/',
      {},
      config,
      compoundSaveRequest,
      compoundCreateSuccess,
      compoundSaveFailure,
    )
  } else {
    const config = {
      method: 'put',
      body: JSON.stringify({ compound: data }),
    }
    return callApi(
      '/compounds/' + id,
      {},
      config,
      compoundSaveRequest,
      compoundSaveSuccess,
      compoundSaveFailure,
    )
  }
}

export function compoundDel(id) {
  const config = {
    method: 'delete',
  }
  return callApi(
    '/compounds/' + id,
    {},
    config,
    compoundDelRequest,
    compoundDelSuccess,
    compoundDelFailure,
  )
}

export function compoundsLoad(params) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/compounds',
    params,
    config,
    compoundsLoadRequest,
    compoundsLoadSuccess,
    compoundsLoadFailure,
  )
}

export function compoundLoad(id) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/compounds/' + id,
    {},
    config,
    compoundLoadRequest,
    compoundLoadSuccess,
    compoundLoadFailure,
  )
}

export function compoundHistoryLoad(compoundId) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/compounds/' + compoundId + '/history',
    {},
    config,
    compoundHistoryLoadRequest,
    compoundHistoryLoadSuccess,
    compoundHistoryLoadFailure,
  )
}
