import {
  LEASE_VEHICLES_LOAD_REQUEST,
  LEASE_VEHICLES_LOAD_SUCCESS,
  LEASE_VEHICLES_LOAD_FAILURE,
  LEASE_VEHICLE_EDIT_START,
  LEASE_VEHICLE_EDIT_CANCEL,
  LEASE_VEHICLE_SAVE_SUCCESS,
  LEASE_VEHICLE_CREATE_SUCCESS,
  LEASE_VEHICLE_DEL_REQUEST,
  LEASE_VEHICLE_DEL_SUCCESS,
  LEASE_VEHICLE_DEL_FAILURE,
} from '../actions/leaseVehicle';

const initialState = {
  leaseVehicles: [],
  loaded: false,
  editing: false,
};

function initializeState(){
  return Object.assign({}, initialState);
}

export default function leaseVehicle(state = initializeState(), action = {}) {
  switch (action.type) {
    
    case LEASE_VEHICLES_LOAD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case LEASE_VEHICLES_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        leaseVehicles: action.leaseVehicles,
        error: null
      };
    case LEASE_VEHICLES_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        leaseVehicles: [],
        error: action.error
      };
    case LEASE_VEHICLE_EDIT_START:
      return {
        ...state,
        editing: {
          ...state.editing,
          [action.id]: true
        }
      };
    case LEASE_VEHICLE_EDIT_CANCEL:
      return {
        ...state,
        editing: {
          ...state.editing,
          [action.id]: false
        }
      };
    case LEASE_VEHICLE_SAVE_SUCCESS:
      let leaseVehicles = [...state.leaseVehicles];
      let leaseVehicleData = [];
      for (const [index, leaseVehicle] of leaseVehicles.entries()) {
        if (leaseVehicle.id === action.id) {
          leaseVehicleData = [
            ...leaseVehicles.slice(0, index),
            action.leaseVehicle,
            ...leaseVehicles.slice(index + 1)
          ];
        }
      }
      return {
        ...state,
        leaseVehicles: leaseVehicleData,
        editing: {
          ...state.editing,
          [action.id]: false
        },
      };
    case LEASE_VEHICLE_CREATE_SUCCESS:
      leaseVehicles = [...state.leaseVehicles];
      return {
        ...state,
        leaseVehicles: [...leaseVehicles, action.leaseVehicle],
        editing: {
          ...state.editing,
          [action.id]: false
        },
      };
    case LEASE_VEHICLE_DEL_REQUEST:
      return {
        ...state,
        deleting: true
      };
    case LEASE_VEHICLE_DEL_SUCCESS:
      leaseVehicles = [...state.leaseVehicles];
      for (const [index, leaseVehicle] of leaseVehicles.entries()) {
        if (leaseVehicle.id === action.leaseVehicle.id) {
          leaseVehicles.splice( index, 1 );
        }
      }
      return {
        ...state,
        deleting: false,
        deleted: true,
        leaseVehicles: leaseVehicles,
        error: null
      };
    case LEASE_VEHICLE_DEL_FAILURE:
      return {
        ...state,
        deleting: false,
        deleted: false,
        error: action.error
      };

    default:
      return state;
  }
}
