import {
  UNIT_TYPES_LOAD_REQUEST,
  UNIT_TYPES_LOAD_SUCCESS,
  UNIT_TYPES_LOAD_FAILURE,
  UNIT_TYPE_EDIT_START,
  UNIT_TYPE_EDIT_CANCEL,
  UNIT_TYPE_SAVE_SUCCESS,
  UNIT_TYPE_CREATE_SUCCESS,
  UNIT_TYPE_DEL_REQUEST,
  UNIT_TYPE_DEL_SUCCESS,
  UNIT_TYPE_DEL_FAILURE,
} from '../actions/unitType';

const initialState = {
  unitTypes: [],
  loaded: false,
  editing: {},
  saveError: {},
  loading_unitTypes: false,
};

function initializeState(){
  return Object.assign({}, initialState);
}

export default function unitType(state = initializeState(), action = {}) {
  switch (action.type) {
    case 'RESET_UNIT_TYPES':
      return {
        ...state,
        unitTypes: []
      }

    case UNIT_TYPES_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        loading_unitTypes: true,
      };
    case UNIT_TYPES_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        unitTypes: action.unitTypes,
        error: null,
        loading_unitTypes: false,
      };
    case UNIT_TYPES_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        unitTypes: null,
        error: action.error,
        loading_unitTypes: false,
      };
    case UNIT_TYPE_EDIT_START:
      return {
        ...state,
        editing: {
          ...state.editing,
          [action.id]: true
        }
      };
    case UNIT_TYPE_EDIT_CANCEL:
      return {
        ...state,
        editing: {
          ...state.editing,
          [action.id]: false
        }
      };

    case UNIT_TYPE_SAVE_SUCCESS:
      let unitTypes = [...state.unitTypes];
      let unitTypeData = [];
      for (const [index, unitType] of unitTypes.entries()) {
        if (unitType.id === action.id) {
          unitTypeData = [
            ...unitTypes.slice(0, index),
            action.unitType,
            ...unitTypes.slice(index + 1)
          ];
        }
      }
      return {
        ...state,
        unitTypes: unitTypeData,
        loading_unitTypes: false,
        editing: {
          ...state.editing,
          [action.id]: false
        },
      };
    case UNIT_TYPE_CREATE_SUCCESS:
      unitTypes = [...state.unitTypes];
      return {
        ...state,
        unitTypes: [...unitTypes, action.unitType],
        editing: {
          ...state.editing,
          [action.id]: false
        },
      };

    case UNIT_TYPE_DEL_REQUEST:
      return {
        ...state,
        deleting: true,
        loading_unitTypes: true,
      };
    case UNIT_TYPE_DEL_SUCCESS:
      unitTypes = [...state.unitTypes];
      for (const [index, unitType] of unitTypes.entries()) {
        if (unitType.id === action.unitType.id) {
          unitTypes.splice( index, 1 );
        }
      }
      return {
        ...state,
        deleting: false,
        deleted: true,
        unitTypes: unitTypes,
        error: null,
        loading_unitTypes: false,
      };
    case UNIT_TYPE_DEL_FAILURE:
      return {
        ...state,
        deleting: false,
        deleted: false,
        error: action.error,
        loading_unitTypes: false,
      };

    case 'UNIT_TYPE_SAVE_REQUEST':
      return {
        ...state,
        loading_unitTypes: true,
      }

    case 'UNIT_TYPE_CREATE_SUCCESS':
      return {
        ...state,
        loading_unitTypes: false,
      }
    default:
      return state;
  }
}
