import { leader } from './dashboard/leads'
import { revenue } from './dashboard/revenue'
import { contractMovement } from './dashboard/contractsMovement'
import { vacancyByUnitType } from './dashboard/vacancyByUnitType'
import { occupancyRate } from './dashboard/occupancyRate'
import { tenants } from './dashboard/tenants'
import { aging } from './dashboard/aging'
import { signedContracts } from './dashboard/signedContracts'
import { maintenance } from './dashboard/maintenance'

const actionBase = 'DASHBOARD'

const DEFAULT_STATE = {
  leads: {},
  revenue: {},
  contract_movement: {},
  vacancy_by_unit_type: {},
  occupancy_rate: {},
  tenants: {},
  aging: {},
  signed_contracts: {},
  maintenance: {},
}

export default function dashboard(state = DEFAULT_STATE, action) {
  const actionType = action.type.split('_LOAD')[0]
  switch (actionType) {
    // Leads reducers
    case `${actionBase}_LEADS`:
      return { ...state, leads: leader(action) }

    // revenue reducers
    case `${actionBase}_REVENUE`:
      return { ...state, revenue: revenue(action) }

    // contract movement reducers
    case `${actionBase}_CONTRACT_MOVEMENT`:
      return { ...state, contract_movement: contractMovement(action) }

    // vacancy by unit type reducers
    case `${actionBase}_VACANCY_BY_UNIT_TYPE`:
      return { ...state, vacancy_by_unit_type: vacancyByUnitType(action) }

    // occupancy rate reducers
    case `${actionBase}_OCCUPANCY_RATE`:
      return { ...state, occupancy_rate: occupancyRate(action) }

    // tenants reducers
    case `${actionBase}_TENANTS`:
      return { ...state, tenants: tenants(action) }

    // tenants reducers
    case `${actionBase}_AGING`:
      return { ...state, aging: aging(action) }

    // signed contracts reducers
    case `${actionBase}_SIGNED_CONTRACTS`:
      return { ...state, signed_contracts: signedContracts(action) }

    // signed contracts reducers
    case `${actionBase}_MAINTENANCE`:
      return { ...state, maintenance: maintenance(action) }

    default:
      return state
  }
}
