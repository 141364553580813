import { callApi } from '../utils/apiUtils';

export const UNIT_NOTES_LOAD_REQUEST = 'UNIT_NOTES_LOAD_REQUEST';
export const UNIT_NOTES_LOAD_SUCCESS = 'UNIT_NOTES_LOAD_SUCCESS';
export const UNIT_NOTES_LOAD_FAILURE  = 'UNIT_NOTES_LOAD_FAILURE';

export const UNIT_NOTE_EDIT_START = 'UNIT_NOTE_EDIT_START';
export const UNIT_NOTE_EDIT_CANCEL = 'UNIT_NOTE_EDIT_CANCEL';
export const UNIT_NOTE_SAVE_REQUEST = 'UNIT_NOTE_SAVE_REQUEST';
export const UNIT_NOTE_SAVE_FAILURE = 'UNIT_NOTE_SAVE_FAILURE';
export const UNIT_NOTE_CREATE_SUCCESS = 'UNIT_NOTE_CREATE_SUCCESS';


function unitNotesLoadRequest() {
  return {
    type: UNIT_NOTES_LOAD_REQUEST,
  };
}

function unitNotesLoadSuccess(response) {
  const unitNotes = response.data.notes;
  return {
    type: UNIT_NOTES_LOAD_SUCCESS,
    notes: unitNotes,
  };
}

function unitNotesLoadFailure(error) {
  return {
    type: UNIT_NOTES_LOAD_FAILURE,
    error: error,
  };
}

function unitNoteSaveRequest() {
  return {
    type: UNIT_NOTE_SAVE_REQUEST,
  };
}

function unitNoteCreateSuccess(response) {
  const unitNote = response.data.note;
  return {
    type: UNIT_NOTE_CREATE_SUCCESS,
    note: unitNote,
    id: 'New'
  };
} 

function unitNoteSaveFailure(error) {
  return {
    type: UNIT_NOTE_SAVE_FAILURE,
    error: error,
  };
}

export function unitNoteEdit(values) {
  return {
    type: UNIT_NOTE_EDIT_START,
  };
}

export function unitNoteEditCancel() {
  return {
    type: UNIT_NOTE_EDIT_CANCEL,
  };
}

export function unitNoteSave(unitId, data) {
  const config = {
    method: 'post', 
    body: JSON.stringify({note: data}),
  };
  return callApi('/units/'+unitId+'/notes/', {}, config, unitNoteSaveRequest, unitNoteCreateSuccess, unitNoteSaveFailure);
}

export function unitNotesLoad(unitId) {
  const config = {
    method: 'get',    
  };
  return callApi('/units/'+unitId+'/notes/', {}, config, unitNotesLoadRequest, unitNotesLoadSuccess, unitNotesLoadFailure);
}