import {
  CONTRACTS_LOAD_REQUEST,
  CONTRACTS_LOAD_SUCCESS,
  CONTRACTS_LOAD_FAILURE,
} from '../actions/contract';

const initialState = {
  contracts: [],
};

function initializeState(){
  return Object.assign({}, initialState);
}

export default function contract(state = initializeState(), action = {}) {
  switch (action.type) {
    case CONTRACTS_LOAD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case CONTRACTS_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        contracts: action.contracts,
        error: null
      };
    case CONTRACTS_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        contracts: null,
        error: action.error
      };
    default:
      return state;
  }
}
