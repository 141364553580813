import React, { Component } from 'react'
import Loading from './Loading'
import './loading.css'

export default function asyncComponent(importComponent, loadingType) {
  class AsyncComponent extends Component {
    state = {
      component: null,
    }

    async componentDidMount() {
      const { default: component } = await importComponent()

      this.setState({ component: component })
    }

    render() {
      const C = this.state.component

      return C ? <C {...this.props} /> : <Loading type={loadingType} />
    }
  }

  return AsyncComponent
}
