import {
  SERVICES_LOAD_REQUEST,
  SERVICES_LOAD_SUCCESS,
  SERVICES_LOAD_FAILURE,
  SERVICE_EDIT_START,
  SERVICE_EDIT_CANCEL,
  SERVICE_SAVE_SUCCESS,
  SERVICE_CREATE_SUCCESS,
  SERVICE_DEL_REQUEST,
  SERVICE_DEL_SUCCESS,
  SERVICE_DEL_FAILURE,
} from '../actions/service';

const initialState = {
  services: [],
  loaded: false,
  editing: {},
  saveError: {},
  loading_services: false,
};

function initializeState(){
  return Object.assign({}, initialState);
}

export default function service(state = initializeState(), action = {}) {
  switch (action.type) {
    case SERVICES_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        loading_services: true,
      };
    case SERVICES_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        services: action.services,
        error: null,
        loading_services: false,
      };
    case SERVICES_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        services: null,
        error: action.error,
        loading_services: false,
      };
    case SERVICE_EDIT_START:
      return {
        ...state,
        editing: {
          ...state.editing,
          [action.id]: true
        }
      };
    case SERVICE_EDIT_CANCEL:
      return {
        ...state,
        editing: {
          ...state.editing,
          [action.id]: false
        }
      };

    case SERVICE_SAVE_SUCCESS:
      let services = [...state.services];
      let serviceData = [];
      for (const [index, service] of services.entries()) {
        if (service.id === action.id) {
          serviceData = [
            ...services.slice(0, index),
            action.service,
            ...services.slice(index + 1)
          ];
        }
      }
      return {
        ...state,
        services: serviceData,
        loading_services: false,
        editing: {
          ...state.editing,
          [action.id]: false
        },
      };
    case SERVICE_CREATE_SUCCESS:
      services = [...state.services];
      return {
        ...state,
        services: [...services, action.service],
        loading_services: false,
        editing: {
          ...state.editing,
          [action.id]: false
        },
      };

    case SERVICE_DEL_REQUEST:
      return {
        ...state,
        deleting: true,
        loading_services: true,
      };
    case SERVICE_DEL_SUCCESS:
      services = [...state.services];
      for (const [index, service] of services.entries()) {
        if (service.id === action.service.id) {
          services.splice( index, 1 );
        }
      }
      return {
        ...state,
        deleting: false,
        deleted: true,
        services: services,
        error: null,
        loading_services: false,
      };
    case SERVICE_DEL_FAILURE:
      return {
        ...state,
        deleting: false,
        deleted: false,
        error: action.error,
        loading_services: false,
      };

    case 'SERVICE_SAVE_REQUEST':
      return {
        ...state,
        loading_services: true,
      }

    case 'SERVICE_SAVE_SUCCESS':
      return {
        ...state,
        loading_services: false,
      }
    default:
      return state;
  }
}
