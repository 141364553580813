import {
  LEAD_ACTIVITIES_LOAD_REQUEST,
  LEAD_ACTIVITIES_LOAD_SUCCESS,
  LEAD_ACTIVITIES_LOAD_FAILURE,
  LEAD_ACTIVITY_EDIT_START,
  LEAD_ACTIVITY_EDIT_CANCEL,
  LEAD_ACTIVITY_CREATE_SUCCESS,
} from '../actions/leadActivity';

const initialState = {
  activities: [],
  loaded: false,
  editing: false,
};

function initializeState(){
  return Object.assign({}, initialState);
}

export default function unitNotes(state = initializeState(), action = {}) {
  switch (action.type) {
    case LEAD_ACTIVITIES_LOAD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case LEAD_ACTIVITIES_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        activities: action.activities,
        error: null
      };
    case LEAD_ACTIVITIES_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        activities: null,
        error: action.error
      };

    case LEAD_ACTIVITY_EDIT_START:
      return {
        ...state,
        editing: true
      };
    case LEAD_ACTIVITY_EDIT_CANCEL:
      return {
        ...state,
        editing: false
      };

    case LEAD_ACTIVITY_CREATE_SUCCESS:
      let activities = [...state.activities];
      return {
        ...state,
        activities: [action.activity, ...activities],
        editing: false,
      };

    default:
      return state;
  }
}
