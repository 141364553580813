const actionBase = 'DASHBOARD_REVENUE'
const STATE = {
  revenue: null,
  errMsg: null,
  sucMsg: null,
  loading: false,
}

export function revenue(action){

  switch (action.type) {

    // On Pending
    case `${actionBase}_LOAD_REQUEST`:
      return {...STATE, loading: true}

    // On success request
    case `${actionBase}_LOAD_SUCCESS`:

      return {
        ...STATE,
        revenue: action.payload.revenue,
        loading: false
      }

    // On failed request
    case `${actionBase}_LOAD_FAILURE`:
      return {
        ...STATE,
        errMsg: action.payload,
        loading: false
      }

    default:
      return STATE

  }

}
