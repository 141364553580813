import { callApi } from '../utils/apiUtils';

export const COMPOUND_NOTES_LOAD_REQUEST = 'COMPOUND_NOTES_LOAD_REQUEST';
export const COMPOUND_NOTES_LOAD_SUCCESS = 'COMPOUND_NOTES_LOAD_SUCCESS';
export const COMPOUND_NOTES_LOAD_FAILURE  = 'COMPOUND_NOTES_LOAD_FAILURE';

export const COMPOUND_NOTE_EDIT_START = 'COMPOUND_NOTE_EDIT_START';
export const COMPOUND_NOTE_EDIT_CANCEL = 'COMPOUND_NOTE_EDIT_CANCEL';
export const COMPOUND_NOTE_SAVE_REQUEST = 'COMPOUND_NOTE_SAVE_REQUEST';
export const COMPOUND_NOTE_SAVE_FAILURE = 'COMPOUND_NOTE_SAVE_FAILURE';
export const COMPOUND_NOTE_CREATE_SUCCESS = 'COMPOUND_NOTE_CREATE_SUCCESS';


function compoundNotesLoadRequest() {
  return {
    type: COMPOUND_NOTES_LOAD_REQUEST,
  };
}

function compoundNotesLoadSuccess(response) {
  const compoundNotes = response.data.notes;
  return {
    type: COMPOUND_NOTES_LOAD_SUCCESS,
    notes: compoundNotes,
  };
}

function compoundNotesLoadFailure(error) {
  return {
    type: COMPOUND_NOTES_LOAD_FAILURE,
    error: error,
  };
}

function compoundNoteSaveRequest() {
  return {
    type: COMPOUND_NOTE_SAVE_REQUEST,
  };
}

function compoundNoteCreateSuccess(response) {
  const compoundNote = response.data.note;
  return {
    type: COMPOUND_NOTE_CREATE_SUCCESS,
    note: compoundNote,
    id: 'New'
  };
} 

function compoundNoteSaveFailure(error) {
  return {
    type: COMPOUND_NOTE_SAVE_FAILURE,
    error: error,
  };
}

export function compoundNoteEdit(values) {
  return {
    type: COMPOUND_NOTE_EDIT_START,
  };
}

export function compoundNoteEditCancel() {
  return {
    type: COMPOUND_NOTE_EDIT_CANCEL,
  };
}

export function compoundNoteSave(compoundId, data) {
  const config = {
    method: 'post', 
    body: JSON.stringify({note: data}),
  };
  return callApi('/compounds/'+compoundId+'/notes/', {}, config, compoundNoteSaveRequest, compoundNoteCreateSuccess, compoundNoteSaveFailure);
}

export function compoundNotesLoad(compoundId) {
  const config = {
    method: 'get',    
  };
  return callApi('/compounds/'+compoundId+'/notes/', {}, config, compoundNotesLoadRequest, compoundNotesLoadSuccess, compoundNotesLoadFailure);
}