import { callApi } from '../utils/apiUtils';

export const BLOCKS_LOAD_REQUEST = 'BLOCKS_LOAD_REQUEST';
export const BLOCKS_LOAD_SUCCESS = 'BLOCKS_LOAD_SUCCESS';
export const BLOCKS_LOAD_FAILURE  = 'BLOCKS_LOAD_FAILURE';
export const BLOCK_EDIT_START = 'BLOCK_EDIT_START';
export const BLOCK_EDIT_CANCEL = 'BLOCK_EDIT_CANCEL';
export const BLOCK_SAVE_REQUEST = 'BLOCK_SAVE_REQUEST';
export const BLOCK_SAVE_SUCCESS = 'BLOCK_SAVE_SUCCESS';
export const BLOCK_SAVE_FAILURE = 'BLOCK_SAVE_FAILURE';
export const BLOCK_CREATE_SUCCESS = 'BLOCK_CREATE_SUCCESS';
export const BLOCK_DEL_REQUEST = 'BLOCK_DEL_REQUEST';
export const BLOCK_DEL_SUCCESS = 'BLOCK_DEL_SUCCESS';
export const BLOCK_DEL_FAILURE = 'BLOCK_DEL_FAILURE';

function blocksLoadRequest() {
  return {
    type: BLOCKS_LOAD_REQUEST,
  };
}

function blocksLoadSuccess(response) {
  const blocks = response.data.blocks;
  return {
    type: BLOCKS_LOAD_SUCCESS,
    blocks: blocks,
  };
}

function blocksLoadFailure(error) {
  return {
    type: BLOCKS_LOAD_FAILURE,
    error: error,
  };
} 

function blockSaveRequest() {
  return {
    type: BLOCK_SAVE_REQUEST,
  };
}

function blockSaveSuccess(response) {
  const block = response.data.block;
  return {
    type: BLOCK_SAVE_SUCCESS,
    block: block,
    id: block.id,
  };
}

function blockCreateSuccess(response) {
  const block = response.data.block;
  return {
    type: BLOCK_CREATE_SUCCESS,
    block: block,
    id: 'New'
  };
} 

function blockSaveFailure(error) {
  return {
    type: BLOCK_SAVE_FAILURE,
    error: error,
  };
}

function blockDelRequest() {
  return {
    type: BLOCK_DEL_REQUEST,
  };
}

function blockDelSuccess(response) {
  const block = response.data.block;
  return {
    type: BLOCK_DEL_SUCCESS,
    block: block,
  };
}

function blockDelFailure(error) {
  return {
    type: BLOCK_DEL_FAILURE,
    error: error,
  };
}


export function blocksLoad(compoundId) {
  const config = {
    method: 'get',    
  };
  return callApi('/compounds/'+compoundId+'/blocks', {}, config, blocksLoadRequest, blocksLoadSuccess, blocksLoadFailure);
}

export function blockEdit(id) {
  return {
    type: BLOCK_EDIT_START,
    id: id,
  };
}

export function blockEditCancel(id) {
  return {
    type: BLOCK_EDIT_CANCEL,
    id: id,
  };
}

export function blockSave(compoundId, data) {
  const id = data.id || '';
  if(id===''){
    const config = {
      method: 'post', 
      body: JSON.stringify({block: data}),
    };
    return callApi('/compounds/'+compoundId+'/blocks', {}, config, blockSaveRequest, blockCreateSuccess, blockSaveFailure);
  } else {
    const config = {
      method: 'put', 
      body: JSON.stringify({block: data}),
    };
    return callApi('/compounds/'+compoundId+'/blocks/'+id, {}, config, blockSaveRequest, blockSaveSuccess, blockSaveFailure);
  }
}

export function blockDel(compoundId, id) {
  const config = {
    method: 'delete',
  };
  return callApi('/compounds/'+compoundId+'/blocks/'+id, {}, config, blockDelRequest, blockDelSuccess, blockDelFailure);
}
