import { callApi } from '../utils/apiUtils';

export const OVERSTAY_CHARGES_LOAD_REQUEST = 'OVERSTAY_CHARGES_LOAD_REQUEST';
export const OVERSTAY_CHARGES_LOAD_SUCCESS = 'OVERSTAY_CHARGES_LOAD_SUCCESS';
export const OVERSTAY_CHARGES_LOAD_FAILURE  = 'OVERSTAY_CHARGES_LOAD_FAILURE';
export const OVERSTAY_CHARGE_EDIT_START = 'OVERSTAY_CHARGE_EDIT_START';
export const OVERSTAY_CHARGE_EDIT_CANCEL = 'OVERSTAY_CHARGE_EDIT_CANCEL';
export const OVERSTAY_CHARGE_SAVE_REQUEST = 'OVERSTAY_CHARGE_SAVE_REQUEST';
export const OVERSTAY_CHARGE_SAVE_SUCCESS = 'OVERSTAY_CHARGE_SAVE_SUCCESS';
export const OVERSTAY_CHARGE_SAVE_FAILURE = 'OVERSTAY_CHARGE_SAVE_FAILURE';


function overstayChargesLoadRequest() {
  return {
    type: OVERSTAY_CHARGES_LOAD_REQUEST,
  };
}

function overstayChargesLoadSuccess(response) {
  const overstayCharges = response.data.overstay_charges;
  return {
    type: OVERSTAY_CHARGES_LOAD_SUCCESS,
    overstayCharges: overstayCharges,
  };
}

function overstayChargesLoadFailure(error) {
  return {
    type: OVERSTAY_CHARGES_LOAD_FAILURE,
    error: error,
  };
}

function overstayChargeSaveRequest() {
  return {
    type: OVERSTAY_CHARGE_SAVE_REQUEST,
  };
}

function overstayChargeSaveSuccess(response) {
  const overstayCharge = response.data.overstay_charge;
  return {
    type: OVERSTAY_CHARGE_SAVE_SUCCESS,
    overstayCharge: overstayCharge,
    id: overstayCharge.id,
  };
}

function overstayChargeSaveFailure(error) {
  return {
    type: OVERSTAY_CHARGE_SAVE_FAILURE,
    error: error,
  };
}

export function overstayChargeEdit(id) {
  return {
    type: OVERSTAY_CHARGE_EDIT_START,
    id: id,
  };
}

export function overstayChargeEditCancel(id) {
  return {
    type: OVERSTAY_CHARGE_EDIT_CANCEL,
    id: id,
  };
}

export function overstayChargeSave(data) {
  const id = data.id || '';
  const config = {
    method: 'put', 
    body: JSON.stringify({overstay_charge: data}),
  };
  return callApi('/overstay_charges/'+id, {}, config, overstayChargeSaveRequest, overstayChargeSaveSuccess, overstayChargeSaveFailure);
}


export function overstayChargesLoad() {
  const config = {
    method: 'get',    
  };
  return callApi('/overstay_charges', {}, config, overstayChargesLoadRequest, overstayChargesLoadSuccess, overstayChargesLoadFailure);
}

