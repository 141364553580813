import { callApi } from '../utils/apiUtils';

export const LEASE_VEHICLES_LOAD_REQUEST = 'LEASE_VEHICLES_LOAD_REQUEST';
export const LEASE_VEHICLES_LOAD_SUCCESS = 'LEASE_VEHICLES_LOAD_SUCCESS';
export const LEASE_VEHICLES_LOAD_FAILURE  = 'LEASE_VEHICLES_LOAD_FAILURE';
export const LEASE_VEHICLE_EDIT_START = 'LEASE_VEHICLE_EDIT_START';
export const LEASE_VEHICLE_EDIT_CANCEL = 'LEASE_VEHICLE_EDIT_CANCEL';
export const LEASE_VEHICLE_SAVE_REQUEST = 'LEASE_VEHICLE_SAVE_REQUEST';
export const LEASE_VEHICLE_SAVE_SUCCESS = 'LEASE_VEHICLE_SAVE_SUCCESS';
export const LEASE_VEHICLE_SAVE_FAILURE = 'LEASE_VEHICLE_SAVE_FAILURE';
export const LEASE_VEHICLE_CREATE_SUCCESS = 'LEASE_VEHICLE_CREATE_SUCCESS';
export const LEASE_VEHICLE_DEL_REQUEST = 'LEASE_VEHICLE_DEL_REQUEST';
export const LEASE_VEHICLE_DEL_SUCCESS = 'LEASE_VEHICLE_DEL_SUCCESS';
export const LEASE_VEHICLE_DEL_FAILURE = 'LEASE_VEHICLE_DEL_FAILURE';

function leaseVehiclesLoadRequest() {
  return {
    type: LEASE_VEHICLES_LOAD_REQUEST,
  };
}

function leaseVehiclesLoadSuccess(response) {
  const leaseVehicles = response.data.vehicles;
  return {
    type: LEASE_VEHICLES_LOAD_SUCCESS,
    leaseVehicles: leaseVehicles,
  };
}

function leaseVehiclesLoadFailure(error) {
  return {
    type: LEASE_VEHICLES_LOAD_FAILURE,
    error: error,
  };
} 

function leaseVehicleSaveRequest() {
  return {
    type: LEASE_VEHICLE_SAVE_REQUEST,
  };
}

function leaseVehicleSaveSuccess(response) {
  const leaseVehicle = response.data.vehicle;
  return {
    type: LEASE_VEHICLE_SAVE_SUCCESS,
    leaseVehicle: leaseVehicle,
    id: leaseVehicle.id,
  };
}

function leaseVehicleCreateSuccess(response) {
  const leaseVehicle = response.data.vehicle;
  return {
    type: LEASE_VEHICLE_CREATE_SUCCESS,
    leaseVehicle: leaseVehicle,
    id: 'New'
  };
} 

function leaseVehicleSaveFailure(error) {
  return {
    type: LEASE_VEHICLE_SAVE_FAILURE,
    error: error,
  };
}

function leaseVehicleDelRequest() {
  return {
    type: LEASE_VEHICLE_DEL_REQUEST,
  };
}

function leaseVehicleDelSuccess(response) {
  const leaseVehicle = response.data.vehicle;
  return {
    type: LEASE_VEHICLE_DEL_SUCCESS,
    leaseVehicle: leaseVehicle,
  };
}

function leaseVehicleDelFailure(error) {
  return {
    type: LEASE_VEHICLE_DEL_FAILURE,
    error: error,
  };
}

export function leaseVehicleEdit(id) {
  return {
    type: LEASE_VEHICLE_EDIT_START,
    id: id,
  };
}

export function leaseVehicleEditCancel(id) {
  return {
    type: LEASE_VEHICLE_EDIT_CANCEL,
    id: id,
  };
}

export function leaseVehiclesLoad(leaseId) {
  const config = {
    method: 'get',    
  };
  return callApi('/leases/'+leaseId+'/vehicles', {}, config, leaseVehiclesLoadRequest, leaseVehiclesLoadSuccess, leaseVehiclesLoadFailure);
}

export function leaseVehicleSave(leaseId, data) {
  const id = data.id || '';
  if(id===''){
    const config = {
      method: 'post', 
      body: JSON.stringify({vehicle: data}),
    };
    return callApi('/leases/'+leaseId+'/vehicles', {}, config, leaseVehicleSaveRequest, leaseVehicleCreateSuccess, leaseVehicleSaveFailure);
  } else {
    const config = {
      method: 'put', 
      body: JSON.stringify({vehicle: data}),
    };
    return callApi('/leases/'+leaseId+'/vehicles/'+id, {}, config, leaseVehicleSaveRequest, leaseVehicleSaveSuccess, leaseVehicleSaveFailure);
  }
}

export function leaseVehicleDel(leaseId, id) {
  const config = {
    method: 'delete',
  };
  return callApi('/leases/'+leaseId+'/vehicles/'+id, {}, config, leaseVehicleDelRequest, leaseVehicleDelSuccess, leaseVehicleDelFailure);
}
