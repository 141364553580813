import { callApi } from '../utils/apiUtils';

export const CITIES_LOAD_REQUEST = 'CITIES_LOAD_REQUEST';
export const CITIES_LOAD_SUCCESS = 'CITIES_LOAD_SUCCESS';
export const CITIES_LOAD_FAILURE  = 'CITIES_LOAD_FAILURE';

function citiesLoadRequest() {
  return {
    type: CITIES_LOAD_REQUEST,
  };
}

function citiesLoadSuccess(response) {
  const cities = response.data.cities;
  return {
    type: CITIES_LOAD_SUCCESS,
    cities: cities,
  };
}

function citiesLoadFailure(error) {
  return {
    type: CITIES_LOAD_FAILURE,
    error: error,
  };
}

export function citiesLoad() {
  const config = {
    method: 'get',    
  };
  return callApi('/cities', {}, config, citiesLoadRequest, citiesLoadSuccess, citiesLoadFailure);
}

