import {
  LEADS_LOAD_REQUEST,
  LEADS_LOAD_SUCCESS,
  LEADS_LOAD_FAILURE,
  LEAD_LOAD_REQUEST,
  LEAD_LOAD_SUCCESS,
  LEAD_LOAD_FAILURE,
  //LEAD_EDIT_START,
  LEAD_EDIT_CANCEL,
  LEAD_SAVE_REQUEST,
  LEAD_SAVE_SUCCESS,
  LEAD_SAVE_FAILURE,
  LEAD_CREATE_SUCCESS,
  LEAD_DEL_REQUEST,
  LEAD_DEL_SUCCESS,
  LEAD_DEL_FAILURE,
  LEAD_LOGS_LOAD_REQUEST,
  LEAD_LOGS_LOAD_SUCCESS,
  LEAD_LOGS_LOAD_FAILURE,
  LEAD_LOST_SUCCESS,
} from '../actions/lead';

const initialState = {
  leads: [],
  lead: {},
  logs: [],
  loaded: false,
  editing: false,
  update_lead: false,
  loadMore: false,
  loading_leads: false,
  loading_lead: false,
  generate_leasing_status:{
    loading: false,
  }
};

function initializeState(){
  return Object.assign({}, initialState);
}

export default function lead(state = initializeState(), action = {}) {
  switch (action.type) {
    case LEADS_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        loading_leads: true,
      };
    case LEADS_LOAD_SUCCESS:
      return {
        ...state,
        ...action.data,
        loading: false,
        loaded: true,
        error: null,
        loading_leads: false,
      };
    case LEADS_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        leads: null,
        error: action.error,
        loading_leads: false,
      };
    case LEAD_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        update_lead: false,
        loading_lead: true,
      };

    case LEAD_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        lead: action.lead,
        error: null,
        loading_lead: false,
      };
    case LEAD_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        lead: {owner:{}},
        error: action.error,
        loading_lead: false,
      };
    case 'LEAD_EDIT_START':
      return {
        ...state,
        editing:  {
          id: action.id,
          action_type: action.action_type,
        }
      };
    case LEAD_EDIT_CANCEL:
      return {
        ...state,
        lead: {owner:{}},
        editing: false
      };

    case LEAD_SAVE_REQUEST:
      return {
        ...state,
        saving: true,
      };

    case 'LEAD_FINALIZE_PENDING':
      return {
        ...state,
        saving: true,
        generate_leasing_status: {loading: true}
      };

    case LEAD_SAVE_SUCCESS:
      let leads = [...state.leads];
      let leadData = [];
      for (const [index, lead] of leads.entries()) {
        if (lead.id === action.id) {
          leadData = [
            ...leads.slice(0, index),
            action.lead,
            ...leads.slice(index + 1)
          ];
        }
      }
      return {
        ...state,
        leads: leadData,
        lead: {owner:{}},
        editing: false,
        saving: false,
      };
    case LEAD_CREATE_SUCCESS:
      leads = [...state.leads];
      return {
        ...state,
        leads: [action.lead, ...leads],
        lead: {owner:{}},
        editing: false,
        saving: false,
      };

    case LEAD_SAVE_FAILURE:
      return {
        ...state,
        saving: false,
        error: action.error,
        generate_leasing_status: false,
      };

    case LEAD_DEL_REQUEST:
      return {
        ...state,
        deleting: true
      };
    case LEAD_DEL_SUCCESS:
      leads = [...state.leads];
      for (const [index, lead] of leads.entries()) {
        if (lead.id === action.lead.id) {
          leads.splice( index, 1 );
        }
      }
      return {
        ...state,
        leads: leads,
        deleting: false,
        deleted: true,
        editing: false,
        error: null
      };

    case LEAD_DEL_FAILURE:
      return {
        ...state,
        deleting: false,
        deleted: false,
        error: action.error
      };

    case LEAD_LOGS_LOAD_REQUEST:
      return {
        ...state,
        log_loading: true
      };
    case LEAD_LOGS_LOAD_SUCCESS:
      return {
        ...state,
        logs: action.logs,
        log_loading: false,
        log_loaded: true,
        log_error: null
      };
    case LEAD_LOGS_LOAD_FAILURE:
      return {
        ...state,
        logs: [],
        log_loading: false,
        log_loaded: false,
        log_error: action.error
      };
    case LEAD_LOST_SUCCESS:
      return {
        ...state,
        lead: action.lead,
        generate_leasing_status:{loading: false}
      };

    /* Load More Leads */
    case 'LEADS_LOAD_MORE_PENDING':
      return {
        ...state,
        loadMore: true,
      }
    case 'LEADS_LOAD_MORE_SUCCESS':
      return {
        ...state,
        loadMore: false,
        leads: [
          ...state.leads,
          ...action.payload.leads
        ]
      }

    case 'LEAD_ACTIVITY_SAVE_REQUEST':
    case 'LEAD_QUOTATION_SAVE_REQUEST':
    case 'LEAD_QUOTATION_DEL_REQUEST':
    case 'LEAD_FORCE_DELETE_LOAD_REQUEST':
      return {
        ...state,
        loading_lead: true,
      }
    case 'LEAD_ACTIVITY_CREATE_SUCCESS':
    case 'LEAD_QUOTATION_SAVE_SUCCESS':
    case 'LEAD_QUOTATION_SAVE_FAILURE':
    case 'LEAD_QUOTATION_DEL_SUCCESS':
    case 'LEAD_QUOTATION_DEL_FAILURE':
    case 'LEAD_FORCE_DELETE_SUCCESS':
    case 'LEAD_QUOTATION_UNIT_ACTION_SUCCESS':
      return {
        ...state,
        update_lead: true,
        loading_lead: false,
      }

    default:
      return state;
  }
}
