export const COMPOUND_ID = 'COMPOUND_ID';
export const BUILDING_ID = 'BUILDING_ID';
export const BLOCK_ID = 'BLOCK_ID';
export const UNIT_TYPE_ID = 'UNIT_TYPE_ID';
export const LEAD_STATUS = "LEAD_STATUS"
export const STATUS = "STATUS"
export const RESET = 'RESET';

export function setCompoundId(id) {
  return {
    type: COMPOUND_ID,
    id,
  };
}
export function setBlockId(id) {
  return {
    type: BLOCK_ID,
    id,
  };
}
export function setBuildingId(id) {
  return {
    type: BUILDING_ID,
    id,
  };
}
export function setUnitTypeId(id) {
  return {
    type: UNIT_TYPE_ID,
    id,
  };
}

export function setLeadStatus(value) {
  return {
    type: LEAD_STATUS,
    value,
  };
}

export function setStatus(value) {
  return {
    type: STATUS,
    value,
  };
}

export function reset(id) {
  return {
    type: RESET,
  };
}