import Toastr from 'toastr'

import {
  TENANTS_LOAD_REQUEST,
  TENANTS_LOAD_SUCCESS,
  TENANTS_LOAD_FAILURE,
  TENANT_LOAD_REQUEST,
  TENANT_LOAD_SUCCESS,
  TENANT_LOAD_FAILURE,
  TENANT_EDIT_START,
  TENANT_EDIT_CANCEL,
  TENANT_SAVE_REQUEST,
  TENANT_SAVE_SUCCESS,
  TENANT_SAVE_FAILURE,
  TENANT_CREATE_SUCCESS,
  TENANT_DEL_REQUEST,
  TENANT_DEL_SUCCESS,
  TENANT_DEL_FAILURE,
  TENANT_HISTORY_LOAD_REQUEST,
  TENANT_HISTORY_LOAD_SUCCESS,
  TENANT_HISTORY_LOAD_FAILURE,
} from '../actions/tenant';

const initialState = {
  tenants: [],
  tenant: {},
  loaded: false,
  editing: false,
  loading: false,
  loading_tenants: false,
  loading_tenant: false,
};

function initializeState(){
  return Object.assign({}, initialState);
}

export default function tenant(state = initializeState(), action = {}) {
  switch (action.type) {
    case TENANTS_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        loading_tenants: true,
      };
    case TENANTS_LOAD_SUCCESS:
      return {
        ...state,
        ...action.data,
        loading: false,
        loaded: true,
        error: null,
        loading_tenants: false,
      };
    case TENANTS_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        tenants: null,
        error: action.error,
        loading_tenants: false,
      };
    case TENANT_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        loading_tenant: true,
      };

    case TENANT_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        tenant: action.tenant,
        error: null,
        loading_tenant: false,
      };
    case TENANT_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        tenant: {owner:{}},
        error: action.error,
        loading_tenant: false,
      };
    case TENANT_EDIT_START:
      return {
        ...state,
        editing:  {
          id: action.id,
          action_type: action.action_type,
        }
      };
    case TENANT_EDIT_CANCEL:
      return {
        ...state,
        tenant: {owner:{}},
        editing: false
      };

    case TENANT_SAVE_REQUEST:
      return {
        ...state,
        saving: true,
        loading_tenant: true,
      };

    case TENANT_SAVE_SUCCESS:
      let tenants = [...state.tenants];
      let tenantData = [];
      for (const [index, tenant] of tenants.entries()) {
        if (tenant.id === action.id) {
          tenantData = [
            ...tenants.slice(0, index),
            action.tenant,
            ...tenants.slice(index + 1)
          ];
        }
      }
      return {
        ...state,
        tenants: tenantData,
        tenant: {owner:{}},
        editing: false,
        saving: false,
        loading_tenant: false,
      };
    case TENANT_CREATE_SUCCESS:
      tenants = [...state.tenants];
      return {
        ...state,
        tenants: [action.tenant, ...tenants],
        tenant: {owner:{}},
        editing: false,
        saving: false,
      };

    case TENANT_SAVE_FAILURE:
      return {
        ...state,
        saving: false,
        error: action.error,
        loading_tenant: false,
      };

    case TENANT_DEL_REQUEST:
      return {
        ...state,
        deleting: true
      };
    case TENANT_DEL_SUCCESS:
      tenants = [...state.tenants];
      for (const [index, tenant] of tenants.entries()) {
        if (tenant.id === action.tenant.id) {
          tenants.splice( index, 1 );
        }
      }
      return {
        ...state,
        tenants: tenants,
        deleting: false,
        deleted: true,
        editing: false,
        error: null
      };
    case TENANT_DEL_FAILURE:
      return {
        ...state,
        deleting: false,
        deleted: false,
        error: action.error
      };
    case TENANT_HISTORY_LOAD_REQUEST:
      return {
        ...state,
        history_loading: true
      };
    case TENANT_HISTORY_LOAD_SUCCESS:
      return {
        ...state,
        history_loading: false,
        history_loaded: true,
        history: action.history,
        history_error: null
      };
    case TENANT_HISTORY_LOAD_FAILURE:
      return {
        ...state,
        history_loading: false,
        history_loaded: false,
        history: [],
        history_error: action.error
      };

    case 'LEASE_SAVE_REQUEST':
    case 'TENANT_PAYMENTS_LOAD_REQUEST':
    case 'TENANT_QUOTATION_SAVE_REQUEST':
    case 'TENANT_DOCUMENT_SAVE_REQUEST':
    case 'TENANT_DOCUMENT_DEL_REQUEST':
    case 'TENANT_CONTRACT_SAVE_REQUEST':
    case 'RESEND_TENANT_PASSWORD_LOAD_REQUEST':
      return {
        ...state,
        loading_tenant: true,
      }

    case 'LEASE_SAVE_SUCCESS':
    case 'LEASE_SAVE_FAILURE':
    case 'TENANT_PAYMENTS_LOAD_SUCCESS':
    case 'TENANT_QUOTATION_UNIT_ACTION_SUCCESS':
    case 'TENANT_QUOTATION_SAVE_FAILURE':
    case 'TENANT_QUOTATION_SAVE_SUCCESS':
    case 'TENANT_DOCUMENT_CREATE_SUCCESS':
    case 'TENANT_DOCUMENT_DEL_SUCCESS':
    case 'TENANT_CONTRACT_SAVE_SUCCESS':
    case 'TENANT_CONTRACT_SAVE_FAILURE':
    case 'RESEND_TENANT_PASSWORD_LOAD_FAILURE':
      return {
        ...state,
        loading_tenant: false,
      }

    case 'RESEND_TENANT_PASSWORD_LOAD_SUCCESS':
      Toastr.success("Password resend successfuly");
      return {
        ...state,
        loading_tenant: false,
      }

    default:
      return state;
  }
}
