import {
  BUILDINGS_LOAD_REQUEST,
  BUILDINGS_LOAD_SUCCESS,
  BUILDINGS_LOAD_FAILURE,
  BUILDING_EDIT_START,
  BUILDING_EDIT_CANCEL,
  BUILDING_SAVE_SUCCESS,
  BUILDING_CREATE_SUCCESS,
  BUILDING_DEL_REQUEST,
  BUILDING_DEL_SUCCESS,
  BUILDING_DEL_FAILURE,
} from '../actions/building';

const initialState = {
  buildings: [],
  loaded: false,
  editing: false,
};

function initializeState(){
  return Object.assign({}, initialState);
}

export default function building(state = initializeState(), action = {}) {
  switch (action.type) {
    
    case BUILDINGS_LOAD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case BUILDINGS_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        buildings: action.buildings,
        error: null
      };
    case BUILDINGS_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        buildings: [],
        error: action.error
      };
    case BUILDING_EDIT_START:
      return {
        ...state,
        editing: {
          ...state.editing,
          [action.id]: true
        }
      };
    case BUILDING_EDIT_CANCEL:
      return {
        ...state,
        editing: {
          ...state.editing,
          [action.id]: false
        }
      };
    case BUILDING_SAVE_SUCCESS:
      let buildings = [...state.buildings];
      let buildingData = [];
      for (const [index, building] of buildings.entries()) {
        if (building.id === action.id) {
          buildingData = [
            ...buildings.slice(0, index),
            action.building,
            ...buildings.slice(index + 1)
          ];
        }
      }
      return {
        ...state,
        buildings: buildingData,
        editing: {
          ...state.editing,
          [action.id]: false
        },
      };
    case BUILDING_CREATE_SUCCESS:
      buildings = [...state.buildings];
      return {
        ...state,
        buildings: [...buildings, action.building],
        editing: {
          ...state.editing,
          [action.id]: false
        },
      };
    case BUILDING_DEL_REQUEST:
      return {
        ...state,
        deleting: true
      };
    case BUILDING_DEL_SUCCESS:
      buildings = [...state.buildings];
      for (const [index, building] of buildings.entries()) {
        if (building.id === action.building.id) {
          buildings.splice( index, 1 );
        }
      }
      return {
        ...state,
        deleting: false,
        deleted: true,
        buildings: buildings,
        error: null
      };
    case BUILDING_DEL_FAILURE:
      return {
        ...state,
        deleting: false,
        deleted: false,
        error: action.error
      };

    default:
      return state;
  }
}
