import _ from 'lodash'
import { permissions } from '../components/const'
import { logout } from 'actions/auth'
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  LOGOUT,
} from '../actions/auth'

const initialState = {
  loggingIn: false,
  loggingOut: false,
  loginError: null,
  privileges: null,
  loading: false,
  currency: null,
}

function initializeState() {
  return Object.assign({}, initialState)
}
let privileges = null

export default function auth(state = initializeState(), action = {}) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return Object.assign({}, state, { loggingIn: true, loading: true })
    case LOGIN_SUCCESS:
      //var roleArrays = action.user.roles.map((role)=> permissions[role] )
      var nonAuthorisedModules = []
      //window.nonAuthorisedModules=nonAuthorisedModules;
      privileges = action.user.role.privileges
      //getItem
      if (!localStorage.getItem('currency')) {
        localStorage.setItem('currency', JSON.stringify(action.currency))
      }

      privileges = {
        ...privileges,
        is_admin: action.user.role.is_admin,
        is_provider: action.user.role.is_provider,
        provider_only:
          action.user.role.is_provider &&
          !action.user.role.is_admin &&
          !action.user.role.privileges.maintenance.view,
      }

      window.permissions = privileges

      return Object.assign({}, state, {
        loggingIn: false,
        user: action.user,
        non_authorised_module: nonAuthorisedModules,
        loading: false,
        currency: action.currency,
        privileges,
      })

    case LOGIN_FAILURE:
      return {
        ...state,
        loggingIn: false,
        user: null,
        loginError: action.error,
        loading: false,
        currency: action.currency,
      }

    case LOGOUT:
      return {
        ...state,
        loggingOut: false,
        user: null,
        loginError: null,
      }
    case FORGOT_PASSWORD_REQUEST:
      return Object.assign({}, state, {
        forgotPasswordConfirmationSent: false,
      })
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPasswordConfirmationSent: true,
        forgotPasswordEmail: null,
      }

    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        loginError: action.error,
      }
    case RESET_PASSWORD_REQUEST:
      return Object.assign({}, state, {
        resetPasswordSuccess: false,
      })
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordSuccess: true,
      }

    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        resetPasswordSuccess: false,
      }

    case 'USER_PRIVILEGES_LOAD_FAILURE':
      logout()
      window.location.reload()
      return state

    case 'CLEAR_PRIVILEGES':
      return {
        ...state,
        privileges: null,
      }

    default:
      return state
  }
}
