import { callApi } from '../utils/apiUtils';

export const NOTIFICATION_SETTINGS_LOAD_REQUEST = 'NOTIFICATION_SETTINGS_LOAD_REQUEST';
export const NOTIFICATION_SETTINGS_LOAD_SUCCESS = 'NOTIFICATION_SETTINGS_LOAD_SUCCESS';
export const NOTIFICATION_SETTINGS_LOAD_FAILURE  = 'NOTIFICATION_SETTINGS_LOAD_FAILURE';
export const NOTIFICATION_SETTING_EDIT_START = 'NOTIFICATION_SETTING_EDIT_START';
export const NOTIFICATION_SETTING_EDIT_CANCEL = 'NOTIFICATION_SETTING_EDIT_CANCEL';
export const NOTIFICATION_SETTING_SAVE_REQUEST = 'NOTIFICATION_SETTING_SAVE_REQUEST';
export const NOTIFICATION_SETTING_SAVE_SUCCESS = 'NOTIFICATION_SETTING_SAVE_SUCCESS';
export const NOTIFICATION_SETTING_SAVE_FAILURE = 'NOTIFICATION_SETTING_SAVE_FAILURE';


function notificationSettingsLoadRequest() {
  return {
    type: NOTIFICATION_SETTINGS_LOAD_REQUEST,
  };
}

function notificationSettingsLoadSuccess(response) {
  const notificationSettings = response.data.notification_settings;
  return {
    type: NOTIFICATION_SETTINGS_LOAD_SUCCESS,
    notificationSettings: notificationSettings,
  };
}

function notificationSettingsLoadFailure(error) {
  return {
    type: NOTIFICATION_SETTINGS_LOAD_FAILURE,
    error: error,
  };
}

function notificationSettingSaveRequest() {
  return {
    type: NOTIFICATION_SETTING_SAVE_REQUEST,
  };
}

function notificationSettingSaveSuccess(response) {
  const notificationSetting = response.data.notification_setting;
  return {
    type: NOTIFICATION_SETTING_SAVE_SUCCESS,
    notificationSetting: notificationSetting,
    id: notificationSetting.id,
  };
}

function notificationSettingSaveFailure(error) {
  return {
    type: NOTIFICATION_SETTING_SAVE_FAILURE,
    error: error,
  };
}

export function notificationSettingEdit(id) {
  return {
    type: NOTIFICATION_SETTING_EDIT_START,
    id: id,
  };
}

export function notificationSettingEditCancel(id) {
  return {
    type: NOTIFICATION_SETTING_EDIT_CANCEL,
    id: id,
  };
}

export function notificationSettingSave(data) {
  const id = data.id || '';
  const config = {
    method: 'put', 
    body: JSON.stringify({notification_setting: data}),
  };
  return callApi('/notification_settings/'+id, {}, config, notificationSettingSaveRequest, notificationSettingSaveSuccess, notificationSettingSaveFailure);
}


export function notificationSettingsLoad() {
  const config = {
    method: 'get',    
  };
  return callApi('/notification_settings', {}, config, notificationSettingsLoadRequest, notificationSettingsLoadSuccess, notificationSettingsLoadFailure);
}

