import {
  OPERATIONS_LOAD_REQUEST,
  OPERATIONS_LOAD_SUCCESS,
  OPERATIONS_LOAD_FAILURE,
  OPERATION_LOAD_REQUEST,
  OPERATION_LOAD_SUCCESS,
  OPERATION_LOAD_FAILURE,
  OPERATION_EDIT_START,
  OPERATION_EDIT_CANCEL,
  OPERATION_SAVE_REQUEST,
  OPERATION_SAVE_SUCCESS,
  OPERATION_SAVE_FAILURE,
} from '../actions/operation'

const initialState = {
  operations: [],
  operation: {},
  loaded: false,
  editing: false,
  loading: false,
  generate_invoice: {
    success: null,
    failed: null,
  },
  loading_operations: false,
  loading_operation: false,
}

function initializeState() {
  return Object.assign({}, initialState)
}

export default function operation(state = initializeState(), action = {}) {
  switch (action.type) {
    case OPERATIONS_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        loading_operations: true,
      }
    case OPERATIONS_LOAD_SUCCESS:
      return {
        ...state,
        operations: action.data.operations,
        count: action.data.count,
        loading: false,
        loaded: true,
        error: null,
        loading_operations: false,
      }
    case OPERATIONS_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        operations: null,
        error: action.error,
        loading_operations: false,
      }
    case OPERATION_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        loading_operation: true,
      }

    case OPERATION_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        operation: action.operation,
        error: null,
        loading_operation: false,
      }
    case OPERATION_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        operation: {},
        error: action.error,
        loading_operation: false,
      }
    case OPERATION_EDIT_START:
      return {
        ...state,
        editing: {
          id: action.id,
          action_type: action.action_type,
        },
      }
    case OPERATION_EDIT_CANCEL:
      return {
        ...state,
        operation: {},
        editing: false,
      }

    case OPERATION_SAVE_REQUEST:
      return {
        ...state,
        saving: true,
        loading_operation: true,
      }

    case OPERATION_SAVE_SUCCESS:
      let operations = [...state.operations]
      let operationData = []
      for (const [index, operation] of operations.entries()) {
        if (operation.id === action.id) {
          operationData = [
            ...operations.slice(0, index),
            action.operation,
            ...operations.slice(index + 1),
          ]
        }
      }
      return {
        ...state,
        operations: operationData,
        operation: {},
        editing: false,
        saving: false,
        loading_operation: false,
      }

    case OPERATION_SAVE_FAILURE:
      return {
        ...state,
        saving: false,
        error: action.error,
        loading_operation: false,
      }

    case 'INVOICE_GENERATE_PENDING':
      return {
        ...state,
        loading_operation: true,
      }

    case 'INVOICE_GENERATED_SUCCESS':
      return {
        ...state,
        generate_invoice: {
          ...initialState.generate_invoice,
          success: true,
        },
        loading_operation: false,
      }

    case 'INVOICE_GENERATED_FAILED':
      return {
        ...state,
        loading_operation: false,
      }

    default:
      return state
  }
}
