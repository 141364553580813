import { callApi } from '../utils/apiUtils';

export const SUB_ACCOUNTS_LOAD_REQUEST = 'SUB_ACCOUNTS_LOAD_REQUEST';
export const SUB_ACCOUNTS_LOAD_SUCCESS = 'SUB_ACCOUNTS_LOAD_SUCCESS';
export const SUB_ACCOUNTS_LOAD_FAILURE  = 'SUB_ACCOUNTS_LOAD_FAILURE';
export const SUB_ACCOUNT_EDIT_START = 'SUB_ACCOUNT_EDIT_START';
export const SUB_ACCOUNT_EDIT_CANCEL = 'SUB_ACCOUNT_EDIT_CANCEL';
export const SUB_ACCOUNT_SAVE_REQUEST = 'SUB_ACCOUNT_SAVE_REQUEST';
export const SUB_ACCOUNT_SAVE_SUCCESS = 'SUB_ACCOUNT_SAVE_SUCCESS';
export const SUB_ACCOUNT_SAVE_FAILURE = 'SUB_ACCOUNT_SAVE_FAILURE';
export const SUB_ACCOUNT_CREATE_SUCCESS = 'SUB_ACCOUNT_CREATE_SUCCESS';
export const SUB_ACCOUNT_DEL_REQUEST = 'SUB_ACCOUNT_DEL_REQUEST';
export const SUB_ACCOUNT_DEL_SUCCESS = 'SUB_ACCOUNT_DEL_SUCCESS';
export const SUB_ACCOUNT_DEL_FAILURE = 'SUB_ACCOUNT_DEL_FAILURE';

function subAccountsLoadRequest() {
  return {
    type: SUB_ACCOUNTS_LOAD_REQUEST,
  };
}

function subAccountsLoadSuccess(response) {
  const subAccounts = response.data.accounts;
  return {
    type: SUB_ACCOUNTS_LOAD_SUCCESS,
    subAccounts: subAccounts,
  };
}

function subAccountsLoadFailure(error) {
  return {
    type: SUB_ACCOUNTS_LOAD_FAILURE,
    error: error,
  };
}

function subAccountSaveRequest() {
  return {
    type: SUB_ACCOUNT_SAVE_REQUEST,
  };
}

function subAccountSaveSuccess(response) {
  const subAccount = response.data.account;
  return {
    type: SUB_ACCOUNT_SAVE_SUCCESS,
    subAccount: subAccount,
    id: subAccount.id,
  };
}

function subAccountCreateSuccess(response) {
  const subAccount = response.data.account;
  return {
    type: SUB_ACCOUNT_CREATE_SUCCESS,
    subAccount: subAccount,
    id: 'New'
  };
} 

function subAccountSaveFailure(error) {
  return {
    type: SUB_ACCOUNT_SAVE_FAILURE,
    error: error,
  };
}

function subAccountDelRequest() {
  return {
    type: SUB_ACCOUNT_DEL_REQUEST,
  };
}

function subAccountDelSuccess(response) {
  const subAccount = response.data.account;
  return {
    type: SUB_ACCOUNT_DEL_SUCCESS,
    subAccount: subAccount,
  };
}

function subAccountDelFailure(error) {
  return {
    type: SUB_ACCOUNT_DEL_FAILURE,
    error: error,
  };
}

export function subAccountEdit(id) {
  return {
    type: SUB_ACCOUNT_EDIT_START,
    id: id,
  };
}

export function subAccountEditCancel(id) {
  return {
    type: SUB_ACCOUNT_EDIT_CANCEL,
    id: id,
  };
}

export function subAccountSave(data) {
  const id = data.id || '';
  if(id===''){
    const config = {
      method: 'post', 
      body: JSON.stringify({account: data}),
    };
    return callApi('/sub_accounts/', {}, config, subAccountSaveRequest, subAccountCreateSuccess, subAccountSaveFailure);
  } else {
    const config = {
      method: 'put', 
      body: JSON.stringify({account: data}),
    };
    return callApi('/sub_accounts/'+id, {}, config, subAccountSaveRequest, subAccountSaveSuccess, subAccountSaveFailure);
  }
}

export function subAccountDel(id) {
  const config = {
    method: 'delete',
  };
  return callApi('/sub_accounts/'+id, {}, config, subAccountDelRequest, subAccountDelSuccess, subAccountDelFailure);
}

export function subAccountsLoad() {
  const config = {
    method: 'get',    
  };
  return callApi('/sub_accounts', {}, config, subAccountsLoadRequest, subAccountsLoadSuccess, subAccountsLoadFailure);
}