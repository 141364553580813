import {
  DISCOUNTS_LOAD_REQUEST,
  DISCOUNTS_LOAD_SUCCESS,
  DISCOUNTS_LOAD_FAILURE,
  DISCOUNT_EDIT_START,
  DISCOUNT_EDIT_CANCEL,
  DISCOUNT_SAVE_SUCCESS,
  DISCOUNT_CREATE_SUCCESS,
  DISCOUNT_DEL_REQUEST,
  DISCOUNT_DEL_SUCCESS,
  DISCOUNT_DEL_FAILURE,
} from '../actions/discount';

const initialState = {
  discounts: [],
  loaded: false,
  editing: {},
};

function initializeState(){
  return Object.assign({}, initialState);
}

export default function discount(state = initializeState(), action = {}) {
  switch (action.type) {
    case DISCOUNTS_LOAD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case DISCOUNTS_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        discounts: action.discounts,
        error: null
      };
    case DISCOUNTS_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        discounts: null,
        error: action.error
      };
    case DISCOUNT_EDIT_START:
      return {
        ...state,
        editing: {
          ...state.editing,
          [action.id]: true
        }
      };
    case DISCOUNT_EDIT_CANCEL:
      return {
        ...state,
        editing: {
          ...state.editing,
          [action.id]: false
        }
      };

    case DISCOUNT_SAVE_SUCCESS:
      let discounts = [...state.discounts];
      let discountData = [];
      for (const [index, discount] of discounts.entries()) {
        if (discount.id === action.id) {
          discountData = [
            ...discounts.slice(0, index),
            action.discount,
            ...discounts.slice(index + 1)
          ];
        }
      }
      return {
        ...state,
        discounts: discountData,
        editing: {
          ...state.editing,
          [action.id]: false
        },
      };
    case DISCOUNT_CREATE_SUCCESS:
      discounts = [...state.discounts];
      return {
        ...state,
        discounts: [...discounts, action.discount],
        editing: {
          ...state.editing,
          [action.id]: false
        },
      };

    case DISCOUNT_DEL_REQUEST:
      return {
        ...state,
        deleting: true
      };
    case DISCOUNT_DEL_SUCCESS:
      discounts = [...state.discounts];
      for (const [index, discount] of discounts.entries()) {
        if (discount.id === action.discount.id) {
          discounts.splice( index, 1 );
        }
      }
      return {
        ...state,
        deleting: false,
        deleted: true,
        discounts: discounts,
        error: null
      };
    case DISCOUNT_DEL_FAILURE:
      return {
        ...state,
        deleting: false,
        deleted: false,
        error: action.error
      };
    default:
      return state;
  }
}
