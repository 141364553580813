import { callApi } from '../utils/apiUtils'

export const OPERATIONS_LOAD_REQUEST = 'OPERATIONS_LOAD_REQUEST'
export const OPERATIONS_LOAD_SUCCESS = 'OPERATIONS_LOAD_SUCCESS'
export const OPERATIONS_LOAD_FAILURE = 'OPERATIONS_LOAD_FAILURE'

export const OPERATION_LOAD_REQUEST = 'OPERATION_LOAD_REQUEST'
export const OPERATION_LOAD_SUCCESS = 'OPERATION_LOAD_SUCCESS'
export const OPERATION_LOAD_FAILURE = 'OPERATION_LOAD_FAILURE'

export const OPERATION_EDIT_START = 'OPERATION_EDIT_START'
export const OPERATION_EDIT_CANCEL = 'OPERATION_EDIT_CANCEL'
export const OPERATION_SAVE_REQUEST = 'OPERATION_SAVE_REQUEST'
export const OPERATION_SAVE_SUCCESS = 'OPERATION_SAVE_SUCCESS'
export const OPERATION_SAVE_FAILURE = 'OPERATION_SAVE_FAILURE'

function operationsLoadRequest() {
  return {
    type: OPERATIONS_LOAD_REQUEST,
  }
}

function operationsLoadSuccess(response) {
  const data = response.data
  return {
    type: OPERATIONS_LOAD_SUCCESS,
    data,
  }
}

function operationsLoadFailure(error) {
  return {
    type: OPERATIONS_LOAD_FAILURE,
    error: error,
  }
}

function operationLoadRequest() {
  return {
    type: OPERATION_LOAD_REQUEST,
  }
}

function operationLoadSuccess(response) {
  const operation = response.data.operation
  return {
    type: OPERATION_LOAD_SUCCESS,
    operation,
  }
}

function operationLoadFailure(error) {
  return {
    type: OPERATION_LOAD_FAILURE,
    error: error,
  }
}

function operationSaveRequest() {
  return {
    type: OPERATION_SAVE_REQUEST,
  }
}

function operationSaveSuccess(response) {
  const operation = response.data.operation
  window.jQuery('#earlyClearanceModal').modal('hide')
  return {
    type: OPERATION_SAVE_SUCCESS,
    id: operation.id,
    operation,
  }
}

function operationSaveFailure(error) {
  return {
    type: OPERATION_SAVE_FAILURE,
    error: error.error,
  }
}

export function operationEdit(values) {
  return {
    type: OPERATION_EDIT_START,
    id: values.id,
    action_type: values.action_type,
  }
}

export function operationEditCancel() {
  return {
    type: OPERATION_EDIT_CANCEL,
  }
}

export function unitPreparationOrderSave(data) {
  const id = data.id
  const config = {
    method: 'put',
    body: JSON.stringify({ unit_preparation: data }),
  }
  return callApi(
    '/operations/unit_preparations/' + id,
    {},
    config,
    operationSaveRequest,
    operationSaveSuccess,
    operationSaveFailure,
  )
}

export function unitPreparationOrdersLoad(params) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/operations/unit_preparations',
    params,
    config,
    operationsLoadRequest,
    operationsLoadSuccess,
    operationsLoadFailure,
  )
}

export function unitPreparationOrderLoad(id) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/operations/unit_preparations/' + id,
    {},
    config,
    operationLoadRequest,
    operationLoadSuccess,
    operationLoadFailure,
  )
}

export function leasingOrderSave(data) {
  const id = data.id
  const config = {
    method: 'put',
    body: JSON.stringify({ leasing_order: data }),
  }
  return callApi(
    '/operations/leasing_orders/' + id,
    {},
    config,
    operationSaveRequest,
    operationSaveSuccess,
    operationSaveFailure,
  )
}

export function leasingOrdersLoad(params) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/operations/leasing_orders',
    params,
    config,
    operationsLoadRequest,
    operationsLoadSuccess,
    operationsLoadFailure,
  )
}

export function leasingOrderLoad(id) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/operations/leasing_orders/' + id,
    {},
    config,
    operationLoadRequest,
    operationLoadSuccess,
    operationLoadFailure,
  )
}

export function clearanceOrderSave(data) {
  const id = data.id
  const config = {
    method: 'put',
    body: JSON.stringify({ leasing_clearance: data }),
  }
  return callApi(
    '/operations/leasing_clearances/' + id,
    {},
    config,
    operationSaveRequest,
    operationSaveSuccess,
    operationSaveFailure,
  )
}

export function clearanceOrdersLoad(params) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/operations/leasing_clearances',
    params,
    config,
    operationsLoadRequest,
    operationsLoadSuccess,
    operationsLoadFailure,
  )
}

export function clearanceOrderLoad(id) {
  const config = {
    method: 'get',
  }
  return callApi(
    '/operations/leasing_clearances/' + id,
    {},
    config,
    operationLoadRequest,
    operationLoadSuccess,
    operationLoadFailure,
  )
}

function invoiceGeneratePending() {
  return {
    type: 'INVOICE_GENERATE_PENDING',
  }
}
function invoiceGeneratedSuccess(values) {
  return {
    type: 'INVOICE_GENERATED_SUCCESS',
    payload: values,
  }
}
function faildToGenerateInvoice(error) {
  return {
    type: 'INVOICE_GENERATED_FAILED',
    payload: error,
  }
}
export function loadGenerateClearanceInvoice(id) {
  const config = {
    method: 'POST',
  }
  const onSuccess = () => {
    window.location.reload()
  }
  return callApi(
    `/operations/leasing_clearances/${id}/invoices`,
    {},
    config,
    invoiceGeneratePending,
    onSuccess,
    faildToGenerateInvoice,
  )
}
